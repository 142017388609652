"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyIdeateMessage = exports.IdeateMessage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var message_card_1 = require("../code/message.card");
var misc_1 = require("../misc");
require("../vscode.dark+.css");
var IdeateMessage = function (props) {
    var message = props.message, corners = props.corners;
    var steps = message.steps;
    if (steps) {
        return (0, jsx_runtime_1.jsx)(SteppedIdeateMessage, { message: steps, corners: corners });
    }
    return (0, jsx_runtime_1.jsx)(BlockIdeateMessage, { message: message.data, corners: corners });
};
exports.IdeateMessage = IdeateMessage;
var SteppedIdeateMessage = function (props) {
    var message = props.message, corners = props.corners;
    if (message.length === 0) {
        return (0, jsx_runtime_1.jsx)(exports.EmptyIdeateMessage, __assign({}, props));
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex flex-col space-y-2", children: message.map(function (step, i) {
            var action = "";
            switch (step.action) {
                case "create_file":
                    action = "Create";
                    break;
                case "delete_file":
                    action = "Delete";
                    break;
                case "update_file":
                    action = "Update";
                    break;
            }
            // Don't display an empty box
            if (!step.title) {
                return null;
            }
            return ((0, jsx_runtime_1.jsxs)(message_card_1.MessageCard, { corners: corners, className: "bg-surface", children: [(0, jsx_runtime_1.jsxs)("div", { className: "bg-surface-container px-2.5 py-1.5 md:px-3 md:py-2", children: [(0, jsx_runtime_1.jsx)("p", { className: "pb-1 text-sm font-semibold text-on-surface", children: step.title }), (0, jsx_runtime_1.jsxs)("p", { className: "text-xs text-on-surface-variant", children: [action, " ", step.path] })] }), step.description && ((0, jsx_runtime_1.jsx)("div", { className: "px-2.5 py-1.5 md:px-3 md:py-2", children: (0, jsx_runtime_1.jsx)(misc_1.Markdown, { content: step.description }) }))] }, i));
        }) }));
};
var EmptyIdeateMessage = function (props) {
    var corners = props.corners;
    return ((0, jsx_runtime_1.jsx)(message_card_1.MessageCard, { corners: corners, className: "bg-surface", children: (0, jsx_runtime_1.jsx)("p", { className: "px-2.5 py-1.5 text-sm italic text-gray-200 md:px-3 md:py-2", children: "Unable to generate solution" }) }));
};
exports.EmptyIdeateMessage = EmptyIdeateMessage;
var BlockIdeateMessage = function (props) {
    var message = props.message, corners = props.corners;
    return ((0, jsx_runtime_1.jsxs)(message_card_1.MessageCard, { corners: corners, className: "bg-surface", children: [(0, jsx_runtime_1.jsx)("div", { className: "bg-surface-container px-2.5 py-1.5 md:px-3 md:py-2", children: (0, jsx_runtime_1.jsx)("p", { className: "text-sm italic text-on-surface", children: "Unable to generate step-by-step solution" }) }), (0, jsx_runtime_1.jsx)("div", { className: "px-2.5 py-1.5 md:px-3 md:py-2", children: (0, jsx_runtime_1.jsx)(misc_1.Markdown, { content: message }) })] }));
};
