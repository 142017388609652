"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AIProvider = void 0;
var ai_provider_id_1 = require("./ai.provider.id");
exports.AIProvider = (_a = {},
    _a[ai_provider_id_1.AIProviderId.OpenAI] = {
        id: ai_provider_id_1.AIProviderId.OpenAI,
        name: "OpenAI",
        description: "OpenAI is an AI research and deployment company.",
        icon: "https://firebasestorage.googleapis.com/v0/b/buildt-internal.appspot.com/o/providers%2Fopenai.svg?alt=media&token=8ea3fb8f-25e0-49b3-aaae-7ff63e2cbd21",
    },
    _a);
