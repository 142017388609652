"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@headlessui/react");
var react_2 = require("react");
function Modal(props) {
    var show = props.show, title = props.title, children = props.children;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(react_1.Transition, { appear: true, show: show, as: react_2.Fragment, children: (0, jsx_runtime_1.jsxs)(react_1.Dialog, { as: "div", className: "relative z-10", onClose: function () { }, children: [(0, jsx_runtime_1.jsx)(react_1.Transition.Child, { as: react_2.Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0", children: (0, jsx_runtime_1.jsx)("div", { className: "fixed inset-0 bg-black bg-opacity-30" }) }), (0, jsx_runtime_1.jsx)("div", { className: "fixed inset-0 overflow-y-auto", children: (0, jsx_runtime_1.jsx)("div", { className: "flex min-h-full items-center justify-center p-4 text-center md:p-6", children: (0, jsx_runtime_1.jsx)(react_1.Transition.Child, { as: react_2.Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 scale-95", enterTo: "opacity-100 scale-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 scale-100", leaveTo: "opacity-0 scale-95", children: (0, jsx_runtime_1.jsxs)(react_1.Dialog.Panel, { className: "w-full max-w-md overflow-hidden rounded-xl bg-surface p-6 text-left align-middle shadow-xl transition-all", children: [(0, jsx_runtime_1.jsx)(react_1.Dialog.Title, { as: "h3", className: "mb-4 text-lg font-medium leading-6 text-white", children: title }), children] }) }) }) })] }) }) }));
}
exports.Modal = Modal;
