"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagProgressType = void 0;
var TagProgressType;
(function (TagProgressType) {
    TagProgressType["FindingImportantFiles"] = "FindingImportantFiles";
    TagProgressType["BalancingFileRepresentation"] = "BalancingFileRepresentation";
    TagProgressType["FindingThemes"] = "FindingThemes";
    TagProgressType["Tagging"] = "Tagging";
})(TagProgressType || (exports.TagProgressType = TagProgressType = {}));
