"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCornerStyle = void 0;
var getCornerStyle = function (corners) {
    if (!corners) {
        return [];
    }
    var tr = corners.tr, tl = corners.tl, br = corners.br, bl = corners.bl;
    var trs = tr ? "rounded-tr-lg md:rounded-tr-xl" : "rounded-tr-sm md:rounded-tr";
    var tls = tl ? "rounded-tl-lg md:rounded-tl-xl" : "rounded-tl-sm md:rounded-tl";
    var brs = br ? "rounded-br-lg md:rounded-br-xl" : "rounded-br-sm md:rounded-br";
    var bls = bl ? "rounded-bl-lg md:rounded-bl-xl" : "rounded-bl-sm md:rounded-bl";
    return [trs, tls, brs, bls];
};
exports.getCornerStyle = getCornerStyle;
