import { Lineage } from "@cosine/common"
import { DataHandler } from "./data.handler"

export class Data {
  protected static handlers: DataHandler[]
  protected static enabled: boolean

  public static init = (handlers: DataHandler[], enabled = true) => {
    this.handlers = handlers
    this.enabled = enabled
  }

  public static getHandlers() {
    return this.handlers
  }

  public static collect = async (lineage: Lineage, data: any, timestamp = Date.now()) => {
    if (!this.enabled) {
      return
    }
    if (!this.handlers) {
      return
    }
    await Promise.all(this.activeHandlers.map((handler) => handler.collect(lineage, data, timestamp)))
  }

  private static get activeHandlers(): DataHandler[] {
    return this.handlers.filter((handler) => handler.enabled)
  }

  public static enable = () => (this.enabled = true)

  public static disable = () => (this.enabled = false)
}
