import { Telemetry } from "@cosine/telemetry"
import { MockTelemetryHandler } from "@cosine/telemetry"
import { Network as BaseNetwork } from "../index"
const agent = "mock_agent"
const version = "0.0.0"

export class MockNetwork extends BaseNetwork {
  constructor(url: string) {
    const handler = new MockTelemetryHandler()
    Telemetry.init([handler])
    super({ url, agent, version })
  }

  protected async getToken(): Promise<string | undefined> {
    return "mock_token"
  }

  protected async reauthenticate(): Promise<void> {}
}
