import * as crypto from "crypto"
import * as fs from "fs"

export async function getFileHash(filePath: string, algorithm = "sha1", encoding: "hex" | "binary" = "hex"): Promise<string> {
  return new Promise((resolve, reject) => {
    const hash = crypto.createHash(algorithm)
    hash.setEncoding(encoding)

    const fileStream = fs.createReadStream(filePath)
    fileStream.on("error", (error) => reject(error))
    fileStream.on("end", () => {
      hash.end()
      resolve(hash.read().toString())
    })

    fileStream.pipe(hash)
  })
}
