import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "./custom.chat.message"
import { IdeateStep } from ".."
import { parseIdeationYaml } from "../../utils/ideate.parse.utils"

export class IdeateChatMessage extends CustomChatMessage<string> {
  public override readonly id: string
  public override readonly type = CustomChatMessageType.Ideate
  public override readonly role: ChatRole = ChatRole.Assistant
  public override readonly data: string
  public steps: IdeateStep[] | undefined

  constructor(id: string, data: string, initialParse = false) {
    super()
    this.id = id
    this.data = data
    if (initialParse) {
      this.steps = parseIdeationYaml(data + "\n")
    }
  }

  public override get content(): string {
    const steps = this.steps ?? parseIdeationYaml(this.data)
    if (steps) {
      return steps
        .map(
          (step) => `[Step ${step.index}]
  
Action: ${step.action}
File: ${step.path}
Title: ${step.title}
Description: ${step.description}`,
        )
        .join("\n\n")
    }

    return this.data
  }
}
