/* eslint-disable no-console */
import React, { useState } from "react"
import { cloneDeep } from "lodash"

//
// To whomever looks at this next
// Good luck
//
// ugh oh :(
//

const StreamedDataComponent: React.FC = () => {
  const [result, setResult] = useState<string>("")

  //
  // Should be very reminiscent of `home.controller.ts`
  //
  const ask = async () => {
    const smoothOperator = new SmoothOperator()
    smoothOperator.onSmoothMessage(msgs => {
      const msg = msgs.messages[msgs.messages.length - 1].content
      setResult(msg ?? "null")
    })

    const stream = (partialMessage: PartialChatResult[]) => {
      // Don't show empty messages because they are boring
      if (!partialMessage.length) {
        console.log("[empty]")
        return
      }

      // If the message has a type, then just throw the content at the UI
      // this is the case for "thinking"/understand/process kinda messages
      if ("type" in partialMessage[partialMessage.length - 1] || "type" in partialMessage[partialMessage.length - 1].message) {
        let msg = partialMessage[0].message?.content
        if (!msg || msg.length === 0) msg = JSON.stringify(partialMessage[0].message?.data)
        if (!msg) msg = JSON.stringify((partialMessage[0] as any)?.data)
        setResult(msg ?? "null")
        return
      }

      // Otherwise we are probably dealing with a message that is being streamed in
      // So pipe it through the smooth operator
      const theOthers = partialMessage.map(pm => pm.message)
      const tmp = { id: "1", prompt: "test", messages: theOthers }
      smoothOperator.newMessage(tmp)
    }

    await askQuestion("What does this project do?", stream)
    await smoothOperator.complete()
  }

  const fetchProjects = async () => {
    const response = await fetch("http://localhost:8080/demo/projects").catch(err => err)
    setResult(JSON.stringify(await response.json()))
  }

  return (
    <div>
      <button onClick={fetchProjects}>Get Projects</button>
      <button onClick={ask}>Ask a hardcoded question</button>
      <div>{result}</div>
    </div>
  )
}

export default StreamedDataComponent

//
// =====================================
// =====================================
// Here there be monsters... even more so than above
// =====================================
// =====================================
//
// Code below is MOSTLY ripped from other packages, and is defintely duplicated
// Will need to be cleaned up a lot
//
// The `askQuestion` function is original (and actually use API) but...
//   - heavily inspired by `handleStream` in remote.ai.ts
//   - kinda also inspired by the stream handler in `home.controller.ts/ask`
//   - and needs smoother.operator.ts somewhere... -> might be the place to stitch together streamed messages?
//   - needs a refactor to be nice.
// The `getProjects` acutally uses API, very simple functionality but will also need a refactor
//

async function askQuestion(question: string, stream: (partialMessage: PartialChatResult[]) => void) {
  const requestBody = JSON.stringify({
    prompt: question,
    history: [],
    project: "1",
  })

  //
  // Gonna want to be using our network module right here
  //
  try {
    const response = await fetch("http://localhost:8080/demo/ask", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: requestBody,
    }).catch(err => err)

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    //
    // This is a knockoff version of `handleStream` but it pretty much works
    //
    const reader = response.body!.getReader()
    let result: PartialChatResult | undefined = undefined
    let buffer = ""
    while (true) {
      const { done, value } = await reader.read()
      if (done) break

      const text = new TextDecoder("utf-8").decode(value)
      const lines = text.split("\n").filter(m => m.length > 0)
      for (const line of lines) {
        buffer += line
        buffer = buffer.replace(/^data: /, "")
        let json = null
        try {
          json = parseJSON(buffer) as PartialChatResult[]
        } catch (errpr) {
          console.log("failed: ", buffer)
        }
        if (!json) continue
        buffer = ""

        // If the message has a type then we can return as is
        // We can tell that its not a "streamed" message that needs stitching together
        if ("type" in json[json.length - 1] || "type" in json[json.length - 1].message) {
          stream(json)
          continue
        }

        // Some hacky combinatorics to stitch together streamed messages
        result = combineChatResult(result, json[json.length - 1])
        json[json.length - 1] = result
        stream(json)
      }
    }
  } catch (e) {
    console.log(e)
  }
}

function combineChatResult(current: PartialChatResult | undefined, partial: PartialChatResult): PartialChatResult {
  if (!current && partial) return partial
  return {
    ...partial,
    message: {
      ...partial.message,
      content: (current?.message?.content ?? "") + (partial.message?.content ?? ""),
    },
  }
}

const parseJSON = (text: string) => {
  try {
    return JSON.parse(text)
  } catch (error) {
    return null
  }
}

export interface ChatResult {
  id: string
  model: string
  message: ChatMessage
  logprobs?: LogProbs
  finish_reason?: string
  usage?: Usage
  function_call?: ChatFunctionCall
}
export interface Usage {
  prompt_tokens: number
  completion_tokens: number
  total_tokens: number
}
export interface ChatMessage<T = any> {
  role: ChatRole
  data?: T
  name?: string
  content?: string
}
export interface LogProbs {
  tokens?: Array<string>
  token_logprobs?: Array<number>
  top_logprobs?: Array<object>
  text_offset?: Array<number>
}
export type ChatFunctionCall<T = Record<string, any>> = {
  name: string
  arguments: T
}
export enum ChatRole {
  System = "system",
  User = "user",
  Assistant = "assistant",
}
export type PartialChatResult = Omit<ChatResult, "function_call"> & {
  function_call?: {
    name?: string
    arguments?: string
  }
}
//
// Straight up copy of `smooth-operator` from vscode app
//
export class SmoothOperator {
  private processingPromise: Promise<void> | null = null
  private callback: ((answer: any) => void) | null = null
  private terminated = false

  private answerMessage: any | null = null
  private content = ""

  private async processMessage(): Promise<void> {
    return new Promise<void>(async resolve => {
      let idx = 0

      while (!this.terminated || idx < this.content.length) {
        if (idx < this.content.length) {
          this.composeAnswerMessage(this.content.slice(0, idx))
          idx += 1
        }

        // Vary the timeout until next character based on how close we are to the end
        // Not worth varying when class is terminated, because there will be no new content
        // > 28 characters away: 2ms
        // < 28 characters away: 2ms -> 30ms as characters get fewer
        let timeout = 2
        if (!this.terminated) {
          const charDiff = this.content.length - idx
          const delay = Math.max(28 - charDiff, 0)
          timeout += delay
        }

        await new Promise(r => setTimeout(r, timeout))
      }

      this.composeAnswerMessage(this.content)
      resolve()
    })
  }

  private composeAnswerMessage(smoothContent: string) {
    const answer = this.answerMessage
    if (!answer) return

    const streamedMessage = answer.messages[answer.messages.length - 1]
    if (!streamedMessage) return

    streamedMessage.content = smoothContent
    this.callback?.(answer)
  }

  public newMessage(message: any) {
    // We need to clone the message here otherwise we will be modifying the original
    this.answerMessage = cloneDeep(message)

    // Make the assumption that the final message in the list is what is being streamed in
    this.content = message.messages[message.messages.length - 1]?.content ?? ""
    if (this.content.length > 0 && this.processingPromise === null) {
      this.processingPromise = this.processMessage()
    }
  }

  public onSmoothMessage(callback: (answer: any) => void) {
    this.callback = callback
  }

  public async complete() {
    this.terminated = true
    await this.processingPromise
    this.callback = null
  }
}
