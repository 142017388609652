"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTask = void 0;
var react_1 = require("react");
var base_task_1 = require("./base.task");
var status_1 = require("./status");
var common_1 = require("../../../../../common");
var messages_1 = require("../../../messages");
var types_1 = require("../../../types");
// Should use useReducer, not useState
var useTask = function (props) {
    var understand = props.understand;
    var semantic = understand.semantic, graph = understand.graph, overview = understand.overview, tagging = understand.tagging;
    var _a = (0, react_1.useState)((0, base_task_1.getBaseTasks)(props)), tasks = _a[0], setTasks = _a[1];
    (0, common_1.useMessage)(messages_1.IndexMessage.Progress, function (message) {
        if (semantic && message.type === messages_1.ProgressMessageType.Semantic) {
            setTasks(function (current) { return applySemantic(message.data, current); });
        }
        if (graph && message.type === messages_1.ProgressMessageType.Graph) {
            setTasks(function (current) { return applyGraph(message.data, current); });
        }
        if (overview && message.type === messages_1.ProgressMessageType.Overview) {
            setTasks(function (current) { return applyOverview(message.data, current); });
        }
        if (tagging && message.type === messages_1.ProgressMessageType.Tag) {
            setTasks(function (current) { return applyTagging(message.data, current); });
        }
    });
    (0, common_1.useMessage)(messages_1.IndexError.InitializeFailed, function () { return setTasks(setAllStatus(tasks, status_1.Status.Failed)); });
    (0, common_1.useMessage)(messages_1.IndexMessage.Cancelled, function () { return setTasks(setAllStatus(tasks, status_1.Status.Cancelled)); });
    (0, common_1.useMessage)(messages_1.IndexMessage.RetryInitialize, function () { return setTasks(resetAll(tasks)); });
    return [tasks, setTasks];
};
exports.useTask = useTask;
var applySemantic = function (data, tasks) {
    var tasksCopy = JSON.parse(JSON.stringify(tasks));
    var taskIndex = 0;
    var task = tasksCopy[taskIndex];
    var subtaskIndex = task.subtasks.findIndex(function (subtask) { return subtask.id === "semantic"; });
    var progressIndex = Object.values(types_1.SemanticProgressType).indexOf(data.type);
    var label = data.label ? data.label : undefined;
    var taskCopy = apply(task, subtaskIndex, progressIndex, data.percent, label);
    tasksCopy[taskIndex] = taskCopy;
    return tasksCopy;
};
var applyGraph = function (data, tasks) {
    var tasksCopy = JSON.parse(JSON.stringify(tasks));
    var taskIndex = 0;
    var task = tasksCopy[taskIndex];
    var subtaskIndex = task.subtasks.findIndex(function (subtask) { return subtask.id === "graph"; });
    var progressIndex = Object.values(types_1.GraphProgressType).indexOf(data.type);
    var taskCopy = apply(task, subtaskIndex, progressIndex, data.percent, data.label);
    tasksCopy[taskIndex] = taskCopy;
    return tasksCopy;
};
var applyOverview = function (data, tasks) {
    var tasksCopy = JSON.parse(JSON.stringify(tasks));
    var taskIndex = 0;
    var task = tasksCopy[taskIndex];
    var subtaskIndex = task.subtasks.findIndex(function (subtask) { return subtask.id === "overview"; });
    var progressIndex = 0;
    var taskCopy = apply(task, subtaskIndex, progressIndex, data.percent, data.label);
    tasksCopy[taskIndex] = taskCopy;
    return tasksCopy;
};
var applyTagging = function (data, tasks) {
    var tasksCopy = JSON.parse(JSON.stringify(tasks));
    var taskIndex = 0;
    var task = tasksCopy[taskIndex];
    var subtaskIndex = task.subtasks.findIndex(function (subtask) { return subtask.id === "tagging"; });
    var progressIndex = 0;
    var taskCopy = apply(task, subtaskIndex, progressIndex, data.percent, data.label);
    tasksCopy[taskIndex] = taskCopy;
    return tasksCopy;
};
var apply = function (task, subtaskIndex, progressIndex, percent, subtitle) {
    var subtask = task.subtasks[subtaskIndex];
    var progress = subtask.progress[progressIndex];
    progress.percent = percent;
    progress.subtitle = subtitle !== null && subtitle !== void 0 ? subtitle : progress.subtitle;
    progress.status = mapStatus(percent);
    subtask.status = combineStatuses(subtask.progress.map(function (p) { return p.status; }));
    task.status = combineStatuses(task.subtasks.map(function (s) { return s.status; }));
    return task;
};
var mapStatus = function (percent) {
    if (percent === 100) {
        return status_1.Status.Done;
    }
    if (percent === 0) {
        return status_1.Status.Waiting;
    }
    return status_1.Status.Loading;
};
var combineStatuses = function (statuses) {
    if (statuses.every(function (status) { return status === status_1.Status.Done; })) {
        return status_1.Status.Done;
    }
    if (statuses.some(function (status) { return status === status_1.Status.Failed; })) {
        return status_1.Status.Failed;
    }
    if (statuses.some(function (status) { return status === status_1.Status.Cancelled; })) {
        return status_1.Status.Cancelled;
    }
    if (statuses.some(function (status) { return status === status_1.Status.Loading; })) {
        return status_1.Status.Loading;
    }
    return status_1.Status.Waiting;
};
var setAllStatus = function (tasks, status) {
    return __spreadArray([], tasks.map(function (task) { return (__assign({}, task)); }), true).map(function (task) {
        return (__assign(__assign({}, task), { status: status, subtasks: task.subtasks.map(function (subtask) {
                return (__assign(__assign({}, subtask), { status: status, progress: subtask.progress.map(function (progress) {
                        return (__assign(__assign({}, progress), { status: status, percent: 0 }));
                    }) }));
            }) }));
    });
};
var resetAll = function (tasks) {
    return __spreadArray([], tasks.map(function (task) { return (__assign({}, task)); }), true).map(function (task) {
        return (__assign(__assign({}, task), { status: status_1.Status.Loading, subtasks: task.subtasks.map(function (subtask) {
                return (__assign(__assign({}, subtask), { status: status_1.Status.Loading, progress: subtask.progress.map(function (progress) {
                        return (__assign(__assign({}, progress), { status: status_1.Status.Loading, percent: 0 }));
                    }) }));
            }) }));
    });
};
