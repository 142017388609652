"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radio = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@headlessui/react");
function Radio(props) {
    var options = props.options, selected = props.selected, setSelected = props.setSelected;
    return ((0, jsx_runtime_1.jsx)("div", { className: "w-full", children: (0, jsx_runtime_1.jsx)("div", { className: "w-full", children: (0, jsx_runtime_1.jsx)(react_1.RadioGroup, { value: selected, onChange: function (value) { return setSelected(value); }, children: (0, jsx_runtime_1.jsx)("div", { className: "space-y-2", children: options.map(function (option) { return ((0, jsx_runtime_1.jsx)(react_1.RadioGroup.Option, { value: option.value, className: function (_a) {
                            var checked = _a.checked;
                            return "".concat(checked ? "bg-surface-container-lowest shadow-md" : "bg-surface-container", " flex cursor-pointer rounded-lg px-2 py-1.5 sm:px-3 sm:py-2 focus:outline-none text-on-surface");
                        }, children: function (_a) {
                            var checked = _a.checked;
                            return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "flex w-full items-center", children: [(0, jsx_runtime_1.jsx)("div", { className: "flex flex-1 items-center", children: (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_1.RadioGroup.Label, { as: "p", className: "font-medium text-on-surface", children: option.title }), option.description && ((0, jsx_runtime_1.jsx)(react_1.RadioGroup.Description, { as: "span", className: "inline text-xs text-on-surface-variant", children: (0, jsx_runtime_1.jsx)("span", { children: option.description }) }))] }) }), (0, jsx_runtime_1.jsx)("div", { className: "m-1 h-6 w-6 flex-none", children: checked && ((0, jsx_runtime_1.jsx)("div", { className: "text-on-surface", children: (0, jsx_runtime_1.jsx)(CheckIcon, {}) })) })] }) }));
                        } }, option.value)); }) }) }) }) }));
}
exports.Radio = Radio;
function CheckIcon(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ viewBox: "0 0 24 24", fill: "none" }, props, { children: [(0, jsx_runtime_1.jsx)("circle", { cx: 12, cy: 12, r: 12, fill: "#fff", opacity: "0.2" }), (0, jsx_runtime_1.jsx)("path", { d: "M7 13l3 3 7-7", stroke: "#fff", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
