import dedent from "dedent"
import { ChatMessage } from "../types"
import { SystemPromptMessage } from "../types/messages/system.prompt.message"

export function summarizePrompt(): ChatMessage {
  const text = `Task: Summarise the following chat transcript into a simpler transcript with fewer messages, but keeping the same core message and content.

    You must summarise multiple messages into single messages conveying the same message. 
    Fundamentally I should get the same sense from reading the summary as I do from reading the full transcript but in far fewer messages.
    
    Look at these examples:
    
    Before summarization:
    """
    User: Hey there what are you doing today?
    Assistant: I'm not sure, I may go to a bar this evening but I'm not set on the idea.
    User: Oh nice, I'm probably going to the beach as it's such a nice day!
    Assistant: Oh I may join you in which case, we could go to the bar after the beach?
    User: Great idea, what time should we meet and where?
    Assistant: Let's do 11am at the Pier.
    User: Brilliant, see you then!
    """
    
    After summarization:
    """
    User: What are you doing today, I'm going to the beach.
    Assistant: I was going to a bar, but I'll come to the beach with you, then the bar after.
    User: Ok, time and place?
    Assistant: Let's do 11am at the Pier.
    """
    
    Notice how effectively the same information is encoded but in half as much text.
    
    Return your answer in YAML in the following manner:
    
    - SummaryTranscript:
      - User: <partial summary>
      - Assistant: <partial summary>
      - User: <partial summary>
      - Assistant: <partial summary>
      ...etc until complete`
  return new SystemPromptMessage(dedent(text))
}
