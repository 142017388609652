"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plans = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var plan_cards_1 = require("../../payments/plan.cards");
var common_1 = require("../../../common");
var messages_1 = require("../messages");
var Plans = function (props) {
    var userPlan = props.userPlan;
    var sendMessage = (0, react_1.useContext)(common_1.MessageContext).sendMessage;
    var _a = (0, react_1.useState)([]), plans = _a[0], setPlans = _a[1];
    (0, react_1.useEffect)(function () { return sendMessage(messages_1.PlanMessage.AvailablePlans, true); }, []);
    (0, common_1.useMessage)(messages_1.PlanMessage.AvailablePlans, function (plans) { return setPlans(plans); });
    var onManagePlan = function () { var _a; return sendMessage(messages_1.PlanMessage.Manage, (_a = userPlan === null || userPlan === void 0 ? void 0 : userPlan.plan) === null || _a === void 0 ? void 0 : _a.id); };
    var onSubscribePlan = function (planId) { return sendMessage(messages_1.PlanMessage.Subscribe, planId); };
    return (0, jsx_runtime_1.jsx)(plan_cards_1.PlanCards, { plans: plans, userPlan: userPlan, onManagePlan: onManagePlan, onSubscribePlan: onSubscribePlan });
};
exports.Plans = Plans;
