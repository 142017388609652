import { AIModel } from "../../models/ai.model"

export const OpenAICompletionModel = {
  TEXT_DAVINCI_003: AIModel.create("text-davinci-003", 4096),
  TEXT_DAVINCI_002: AIModel.create("text-davinci-002", 4096),
  TEXT_DAVINCI_001: AIModel.create("text-davinci-001", 2049),
  TEXT_CURIE_001: AIModel.create("text-curie-001", 2049),
  TEXT_BABBAGE_001: AIModel.create("text-babbage-001", 2049),
  TEXT_ADA_001: AIModel.create("text-ada-001", 2049),
  GPT_TURBO_INSTRUCT: AIModel.create("gpt-3.5-turbo-instruct", 4096),
  GPT_TURBO_INSTRUCT_0914: AIModel.create("gpt-3.5-turbo-instruct-0914", 4096),
} as const

export const OpenAIFinetunedCompletionModel = {
  TEXT_BABBAGE_CODE_DESCRIBE: AIModel.create("babbage:ft-buildtai:code-describe-2023-01-27-03-10-07", 2049),
  TEXT_BABBAGE_CODE_DESCRIBE_V2: AIModel.create("babbage:ft-buildtai:chat-describe-v3-2023-02-09-06-53-10", 2049),
  TEXT_BABBAGE_CODE_DESCRIBE_V3: AIModel.create("curie:ft-buildtai:chat-describe-v3-2023-02-10-22-07-03", 2049),
  TEXT_BABBAGE_QUERY_ENHANCE: AIModel.create("babbage:ft-buildtai:query-enhance-2023-01-26-23-26-01", 2049),
  TEXT_BABBAGE_QUERY_ENHANCE_V2: AIModel.create("babbage:ft-buildtai-2023-02-02-05-11-47", 2049),
} as const

export const OpenAIFinetunedChatModel = {
  CHAT_GPT_TURBO_0613_HUNK_DESCRIBE_V0: AIModel.create("ft:gpt-3.5-turbo-0613:cosine-ai:hunk-desc-v0-0-0:804zXG7r", 4096),
  CHAT_GPT_TURBO_0613_TASK_PRED_V0: AIModel.create("ft:gpt-3.5-turbo-0613:cosine-ai:task-pred-v0-0-0:7y56Cevo", 4096),
  CHAT_GPT_TURBO_0613_THINK_V5: AIModel.create("ft:gpt-3.5-turbo-0613:cosine-ai:think-v0-0-5:80uKEueU", 4096),
  CHAT_GPT_TURBO_0613_THINK_V6: AIModel.create("ft:gpt-3.5-turbo-0613:cosine:think-v0-0-6:88pUrc60", 4096), // Historically troublesome, throws 500s
  CHAT_GPT_TURBO_0613_THINK_V7a: AIModel.create("ft:gpt-3.5-turbo-0613:cosine:think-v0-0-7a:88yaU5nX", 4096), // 7a is finetuned with real function calls
  CHAT_GPT_TURBO_0613_THINK_V7b: AIModel.create("ft:gpt-3.5-turbo-0613:cosine:think-v0-0-7b:88yfj0ny", 4096), // 7b is finetuned to output text in the form of a JSON function call
  CHAT_GPT_TURBO_0613_USER_SIM_V0: AIModel.create("ft:gpt-3.5-turbo-0613:cosine::89G7jV9I", 4096), // simulates a user's input, start with user message of <START>
  CHAT_GPT_TURBO_0613_UNDER_PLAN_V0: AIModel.create("ft:gpt-3.5-turbo-0613:cosine:underplan-v0-0-0:89Jm7RtA", 4096), // understand plan
  CHAT_GPT_TURBO_0613_TAGGER_V1: AIModel.create("ft:gpt-3.5-turbo-0613:cosine-ai:tagger-v0-0-1:85JZiwVr", 4096), // takes filestructure as input and labels them with themes/stacks
  CHAT_GPT_TURBO_0613_SEMANTIC_TAG_PICK_V0: AIModel.create("ft:gpt-3.5-turbo-0613:cosine-ai:sem-pick-v0-0-0:85s8JzuY", 4096), // takes a semantic search query and comes up with the themes, stacks, keywords, components, files and languages that are relevant
  CHAT_GPT_TURBO_0613_SEARCH_HEURISTIC_PICK_V0: AIModel.create("ft:gpt-3.5-turbo-0613:cosine-ai:search-pick-v0-0-1:837ma3zo", 4096), // takes a search query and decides which are the best heuristics to use to find the best results
  CHAT_GPT_TURBO_0613_SEARCH_HEURISTIC_PICK_V2: AIModel.create("ft:gpt-3.5-turbo-1106:cosine:search-pick-v0-0-2:8p2NTCGU", 16_384), // takes a search query and decides which are the best heuristics to use to find the best results
  CHAT_GPT_TURBO_0613_NO_RAG_V0: AIModel.create("ft:gpt-3.5-turbo-0613:cosine:no-rag-v0-0-0:8AcUKa14", 4096),
  CHAT_GPT_TURBO_0613_NO_RAG_V2: AIModel.create("ft:gpt-3.5-turbo-0613:cosine:no-rag-v0-0-2:8AfgLDxS", 4096),
  CHAT_GPT_TURBO_1106_COSINEBOT_V1: AIModel.create("ft:gpt-3.5-turbo-1106:cosine:cosinebot-v0-0-1:8n40c0ap", 16_384),
  CHAT_GPT_TURBO_1106_HYDE_V1: AIModel.create("ft:gpt-3.5-turbo-0613:cosine-ai:hyde-v0-0-1:81z6H3ui", 16_384),
  CHAT_GPT_TURBO_1106_TASK_EVAL_V1: AIModel.create("ft:gpt-3.5-turbo-1106:cosine:task-eval-v0-0-1:8XT6a6xo", 16_384),
  CHAT_GPT_TURBO_1106_REVIEWBOT_V2: AIModel.create("ft:gpt-3.5-turbo-1106:cosine:reviewbot-v0-0-2:8hmZDLx4", 16_384),
  CHAT_GPT_TURBO_1106_COMMIT_ESTIMATION_V0: AIModel.create("ft:gpt-3.5-turbo-1106:cosine:commit-est-v0-0-0:8ipd4OM8", 16_384),
  CHAT_GPT_TURBO_1106_GITBOT_V3: AIModel.create("ft:gpt-3.5-turbo-1106:cosine:gitagent-v0-0-3:8mllxXS2", 16_384),
  CHAT_GPT_TURBO_1106_LINBOT_V0: AIModel.create("ft:gpt-3.5-turbo-1106:cosine:linbot-v0-0-0:8ohlf4d3", 16_384),
  CHAT_GPT_TURBO_1106_RANKER_V0: AIModel.create("ft:gpt-3.5-turbo-1106:cosine:ranker-v0-0-0:8qOj4hTv", 16_384),
} as const

export const OpenAIChatModel = {
  CHAT_DAVINCI_003_ALPHA: AIModel.create("chat-davinci-003-alpha", 4096),
  CHAT_GPT_TURBO: AIModel.create("gpt-3.5-turbo", 16_384),
  CHAT_GPT_TURBO_0613: AIModel.create("gpt-3.5-turbo-0613", 4096),
  CHAT_GPT_TURBO_16K: AIModel.create("gpt-3.5-turbo-16k", 16_384),
  CHAT_GPT_TURBO_16K_0613: AIModel.create("gpt-3.5-turbo-16k-0613", 16_384),
  CHAT_GPT_TURBO_0125: AIModel.create("gpt-3.5-turbo-0125", 16_384),
  CHAT_GPT_4: AIModel.create("gpt-4", 8192),
  CHAT_GPT_4_0613: AIModel.create("gpt-4-0613", 8192),
  CHAT_GPT_4_32K: AIModel.create("gpt-4-32k", 32_768),
  CHAT_GPT_4_32K_0613: AIModel.create("gpt-4-32k-0613", 32_768),
  CHAT_GPT_4_TURBO_1106_PREVIEW: AIModel.create("gpt-4-1106-preview", 128_000),
  CHAT_GPT_TURBO_1106_16K: AIModel.create("gpt-3.5-turbo-1106", 16_384),
  /**
   * @deprecated GPT-4-32k-0301 is deprecated by OpenAI. Use a newer LTS.
   */
  CHAT_GPT_4_32K_0301: AIModel.create("gpt-4-32k-0301", 32_768),
  /**
   * @deprecated GPT-3.5-turbo-0301 is deprecated by OpenAI. Use a newer LTS.
   */
  CHAT_GPT_TURBO_0301: AIModel.create("gpt-3.5-turbo-0301", 4096),
  /**
   * @deprecated GPT-4-0314 is deprecated by OpenAI. Use a newer LTS.
   */
  CHAT_GPT_4_0314: AIModel.create("gpt-4-0314", 8192),
} as const

export const OpenAIEmbeddingModel = {
  EMBED_ADA_002: AIModel.create("text-embedding-ada-002", 8191),
  EMBED_TEXT_EMBEDDING_3_SMALL: AIModel.create("text-embedding-3-small", 8191),
  EMBED_TEXT_EMBEDDING_3_LARGE: AIModel.create("text-embedding-3-large", 8191),
} as const
