"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressSeparator = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var select_icon_1 = require("./icons/select.icon");
function ProgressSeparator(props) {
    var status = props.status, bottom = props.bottom;
    var icon = (0, select_icon_1.getIconForStatus)({ status: status, className: "w-3 h-3 sm:w-4 sm:h-4" });
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex w-8 flex-col items-center sm:w-10 md:w-12", children: [(0, jsx_runtime_1.jsx)("div", { className: "my-1.5 flex-none sm:my-2", children: icon }), bottom ? (0, jsx_runtime_1.jsx)("a", { className: "flex-1" }) : (0, jsx_runtime_1.jsx)("a", { className: "w-[1px] flex-1 bg-gray-600" })] }));
}
exports.ProgressSeparator = ProgressSeparator;
