"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodeMessage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var code_block_1 = require("../code/code.block");
var prepend_char_1 = require("../../utils/prepend.char");
var CodeMessage = function (props) {
    var message = props.message, corners = props.corners, onClick = props.onClick;
    var _a = message.data, path = _a[0], code = _a[1];
    var language = code.language;
    var text = (0, prepend_char_1.prependChar)(code.text, code.start.column);
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(code_block_1.CodeBlock, { text: text, language: language, path: path, onClick: function () { return onClick === null || onClick === void 0 ? void 0 : onClick(path, code.start, code.end); }, corners: corners }) }));
};
exports.CodeMessage = CodeMessage;
