"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Verify = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("../../common");
function Verify(props) {
    var email = props.email, onResend = props.onResend, isSubmitting = props.isSubmitting, onSubmit = props.onSubmit;
    return ((0, jsx_runtime_1.jsx)("div", { className: "w-full max-w-lg rounded-lg border border-surface-outline bg-surface shadow", children: (0, jsx_runtime_1.jsxs)("div", { className: "sm:p-4.5 space-y-4 p-4 sm:space-y-6 md:space-y-8 md:p-6 lg:p-8", children: [(0, jsx_runtime_1.jsx)("div", { className: "flex flex-row items-center", children: (0, jsx_runtime_1.jsx)("h1", { className: "truncate text-lg font-bold leading-tight tracking-tight text-white md:text-xl lg:text-2xl", children: "Verify Email" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "flex flex-col space-y-3 sm:space-y-4 md:space-y-5 lg:space-y-6", children: [(0, jsx_runtime_1.jsx)("p", { className: "text-xs text-white sm:text-sm md:text-base", children: "A verification email has been sent to ".concat(email, ". Please check your inbox and click the link to verify your email.") }), (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, className: "mt-32 w-full", label: "Continue", onClick: onSubmit, disabled: isSubmitting }), (0, jsx_runtime_1.jsx)("button", { className: "items-center text-sm text-white", type: "submit", onClick: onResend, disabled: isSubmitting, children: "Resend Email" })] })] }) }));
}
exports.Verify = Verify;
