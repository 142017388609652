import { Component } from "@cosine/code"
import { SearchChatMessage } from "./search.message"
import { SearchMessageType } from "./search.message.type"

export class CodeChatMessage extends SearchChatMessage<[string, Component]> {
  public override readonly searchType = SearchMessageType.Code

  public override get content(): string {
    const [path, component] = this.data
    const { text } = component
    return `[CODE] ${path} ${component.start.row}:${component.start.column} ${component.end.row}:${component.end.column} \n\`\`\`${text}\`\`\``
  }
}
