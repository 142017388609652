import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "."

export class ErrorChatMessage extends CustomChatMessage<string> {
  public override readonly type = CustomChatMessageType.Error
  public override readonly role = ChatRole.Assistant
  public override readonly content = `[Error] ${this.data}`

  constructor(public override data: string) {
    super()
  }
}
