"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsPanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("../../common");
var tabs_1 = require("../../common/components/tabs");
var panels_1 = require("./panels");
var SettingsPanel = function (props) {
    var general = props.general, search = props.search, account = props.account, developer = props.developer;
    var tabs = [
        { title: "General", tab: (0, jsx_runtime_1.jsx)(panels_1.GeneralPanel, __assign({}, general)) },
        { title: "Search", tab: (0, jsx_runtime_1.jsx)(panels_1.SearchPanel, __assign({}, search)) },
        { title: "Account", tab: (0, jsx_runtime_1.jsx)(panels_1.AccountPanel, __assign({}, account)) },
    ];
    if (developer) {
        tabs.push({ title: "Dev Tools", tab: (0, jsx_runtime_1.jsx)(panels_1.DevToolsPanel, __assign({}, developer)) });
    }
    return ((0, jsx_runtime_1.jsx)(common_1.Card, { className: "p-1 sm:p-2 lg:p-3", children: (0, jsx_runtime_1.jsx)(tabs_1.Tabs, { pages: tabs, page: "General", className: "pb-3 pt-2 sm:pt-3 md:py-4 lg:pt-5" }) }));
};
exports.SettingsPanel = SettingsPanel;
