"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanErrorMessage = exports.PlanMessage = void 0;
var PlanMessage;
(function (PlanMessage) {
    PlanMessage["Manage"] = "ManagePlan";
    PlanMessage["Subscribe"] = "SubscribeToPlan";
    PlanMessage["CurrentPlan"] = "CurrentPlan";
    PlanMessage["AvailablePlans"] = "AvailablePlans";
    PlanMessage["StartTrial"] = "StartTrial";
    PlanMessage["PlanStarted"] = "PlanStarted";
    PlanMessage["RefreshPlan"] = "RefreshPlan";
    PlanMessage["PlansRequired"] = "PlansRequired";
})(PlanMessage || (exports.PlanMessage = PlanMessage = {}));
var PlanErrorMessage;
(function (PlanErrorMessage) {
    PlanErrorMessage["Error"] = "PlanError";
})(PlanErrorMessage || (exports.PlanErrorMessage = PlanErrorMessage = {}));
