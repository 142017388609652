import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "./custom.chat.message"

export class TextChatMessage extends CustomChatMessage<string> {
  public override readonly id: string
  public override readonly role: ChatRole = ChatRole.Assistant
  public override readonly content: string
  public override type = CustomChatMessageType.Text
  public override data: string

  constructor(id: string, data: string) {
    super()
    this.id = id
    this.data = data
    this.content = data
  }
}
