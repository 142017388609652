import { Chat, ChatMessage, MessageContext, Modal, useMessage } from "@cosine/ui"
import React, { useContext, useState } from "react"
import { Project } from "./models/listener"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

function ProjectSelection({ value, setValue, options }: { value: Project | undefined, setValue: (p: Project) => void, options: Project[] }) {
  const [open, setOpen] = React.useState(false)
  console.log({ options })

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="link"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {value
            ? value.displayName :
            "Select a project"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search project..." />
          <CommandEmpty>No project found.</CommandEmpty>
          <CommandGroup>
            {options.map((framework) => (
              <CommandItem
                key={framework.uuid}
                onSelect={() => {
                  setValue(framework)
                  setOpen(false)
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value?.displayName === framework.displayName ? "opacity-100" : "opacity-0"
                  )}
                />
                {framework.displayName}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

function App() {


  const [project, setProject] = useState<Project>()
  const [projects, setProjects] = useState<Project[]>([])
  const { sendMessage } = useContext(MessageContext)
  const updateProject = (project: Project) => {
    sendMessage(ChatMessage.Cancel)
    sendMessage(ChatMessage.Clear, project)
    setProject(project)
  }

  const [show, setShow] = useState<boolean>(false)
  useMessage(ChatMessage.MessageLimit, () => setShow(true))
  useMessage("Projects", ({ projects, project }) => {
    setProjects([...projects])
    setProject(project)
  })

  const onMessages = (e: number) => {

    if ('parentIFrame' in window) {
      if (e === 1) {
        (window as any)?.parentIFrame?.sendMessage("onMessages")
      }

      if (e === 0) {
        (window as any)?.parentIFrame?.sendMessage("clear")
      }
    }

  }

  return (
    <div className="flex py-4 justify-center items-center h-full w-full flex-col">
      <div className="bg-neutral-700/80 md:bg-neutral-700 text-sm flex-col md:flex-row md:gap-4 gap-0 px-8 py-2 md:py-1 text-neutral-300 md:-mb-3 rounded-2xl md:rounded-t-full flex items-center">
        <h4>Ask Cosine about:</h4>
        <ProjectSelection setValue={updateProject} value={project} options={projects} />
      </div>

      <Chat variant="demo" onMessages={onMessages} showExamples showTokens />

      <Modal show={show} title={"Download Cosine"}>
        <label className="my-4 text-sm font-light text-on-surface-variant">
          You've reached your daily message limit. Download Cosine for VSCode to continue using the product on your own codebase.
        </label>
        <div className="mt-4 flex justify-end space-x-2">
          <a href="https://go.cosine.sh/vscode" target="_blank" rel="noreferrer">
            Download
          </a>
        </div>
      </Modal>
    </div>
  )
}

export default App
