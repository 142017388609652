"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = void 0;
var Status;
(function (Status) {
    Status["Waiting"] = "Waiting";
    Status["Loading"] = "Loading";
    Status["Done"] = "Done";
    Status["Failed"] = "Failed";
    Status["Cancelled"] = "Cancelled";
})(Status || (exports.Status = Status = {}));
