export class AIModel {
  private constructor(
    public id: string,
    public maxTokens: number,
    public maxCompletionTokens: number = 4096,
  ) {}

  public static create(id: string, maxTokens: number): AIModel {
    return new AIModel(id, maxTokens)
  }
}
