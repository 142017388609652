/* eslint-disable no-console */
import { PropsWithChildren } from "react"
import { MessageContext } from "@cosine/ui"
import { listener } from "./listener"

export type Message = {
  type: string
  data?: any
}

export type WebListener = (event: Message) => void

const receivePrefix = "receive"

export type WebMessageProviderProps = PropsWithChildren

export const WebMessageProvider = (props: WebMessageProviderProps) => {
  window.onmessage = (event: MessageEvent<Message>) => listener(event)
  const sendMessage = (type: string, data?: any) => window.postMessage({ type: `${receivePrefix}.${type}`, data })
  const addMessageListener = (type: string, callback: (data: any) => void) => {
    const listener: WebListener = (event: Message) => {
      if (!event.data.type) return
      const [prefix, receivedType] = event.data.type.split(/\.(.*)/s)
      if (prefix === receivePrefix) return
      if (receivedType === type) callback(event.data.data)
    }
    window.addEventListener("message", listener)
    return listener
  }
  const removeMessageListener = (listener: WebListener): void => window.removeEventListener("message", listener)
  return <MessageContext.Provider value={{ sendMessage, addMessageListener, removeMessageListener }}>{props.children}</MessageContext.Provider>
}
