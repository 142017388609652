import { parseCondaYaml } from "./conda-yaml.parser"
import { parsePackageJson } from "./package-json.parser"
import { parseRequirementsTxt } from "./requirements-txt.parser"
import { parsePipfile } from "./pipfile.parser"
import { parsePyProjectToml } from "./pyproject.parser"
import { Dependencies } from "../types"
import { parseGoMod } from "./go-mod.parser"
import { parseGopkgToml } from "./gopkg-toml.parser"
import { parseGemfile } from "./gemfile.parser"

export function parseDependencies(fileContents: string, fileName: string): Dependencies {
  try {
    if (fileName.toLowerCase().endsWith("conda.yaml")) {
      return parseCondaYaml(fileContents)
    } else if (fileName.toLowerCase().endsWith("package.json")) {
      return parsePackageJson(fileContents)
    } else if (fileName.toLowerCase().endsWith("requirements.txt")) {
      return parseRequirementsTxt(fileContents)
    } else if (fileName.toLowerCase().endsWith("Pipfile".toLowerCase())) {
      return parsePipfile(fileContents)
    } else if (fileName.toLowerCase().endsWith("pyproject.toml".toLowerCase())) {
      return parsePyProjectToml(fileContents)
    } else if (fileName.toLowerCase().endsWith("go.mod".toLowerCase())) {
      return parseGoMod(fileContents)
    } else if (fileName.toLowerCase().endsWith("Gopkg.toml".toLowerCase())) {
      return parseGopkgToml(fileContents)
    } else if (fileName.toLowerCase().endsWith("Gemfile".toLowerCase())) {
      return parseGemfile(fileContents)
    } else {
      return {}
    }
  } catch (err) {
    return {}
  }
}
