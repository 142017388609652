"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListBox = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@headlessui/react");
var react_2 = require("react");
var react_feather_1 = require("react-feather");
function ListBox(props) {
    var label = props.label, allOptions = props.allOptions, selectedOptions = props.selectedOptions, setSelectedOptions = props.setSelectedOptions;
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(react_1.Listbox, { value: selectedOptions, onChange: setSelectedOptions, by: function (a, z) { return a.value === z.value; }, multiple: true, children: (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)(react_1.Listbox.Button, { className: "group inline-flex items-center rounded-md bg-surface-container-low px-2 py-1.5 text-xs font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white sm:px-2.5 sm:py-2 md:px-3 md:py-2.5 md:text-sm", children: [(0, jsx_runtime_1.jsx)("p", { className: "text-xs md:text-sm", children: label }), (0, jsx_runtime_1.jsx)(react_feather_1.ChevronDown, { className: "ml-1.5 h-4 w-4 text-white transition duration-150 ease-in-out sm:ml-2 sm:h-5 sm:w-5", "aria-hidden": "true" })] }), (0, jsx_runtime_1.jsx)(react_1.Transition, { as: react_2.Fragment, leave: "transition ease-in duration-100 bg-red-500", leaveFrom: "opacity-100", leaveTo: "opacity-0", children: (0, jsx_runtime_1.jsx)(react_1.Listbox.Options, { className: "absolute z-10 mt-1 grid grid-cols-1 gap-2 overflow-auto rounded-md bg-white py-1 text-base opacity-100 shadow-lg focus:outline-none sm:text-sm lg:grid-cols-2 xl:grid-cols-3", children: allOptions.map(function (option, optionIdx) { return ((0, jsx_runtime_1.jsx)(react_1.Listbox.Option, { value: option, children: function (_a) {
                                    var selected = _a.selected;
                                    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("a", { className: "flex cursor-pointer items-center rounded-md p-1 transition duration-150 ease-in-out hover:bg-neutral-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 sm:rounded-lg sm:p-2", children: [(0, jsx_runtime_1.jsx)("span", { className: "left-0 flex items-center px-2 sm:pl-2 sm:pr-3", children: selected ? ((0, jsx_runtime_1.jsx)(react_feather_1.CheckCircle, { className: "h-3 w-3 text-neutral-400 sm:h-4 sm:w-4", "aria-hidden": "true" })) : ((0, jsx_runtime_1.jsx)(react_feather_1.Circle, { className: "h-3 w-3 text-neutral-300 sm:h-4 sm:w-4", "aria-hidden": "true" })) }), (0, jsx_runtime_1.jsx)("div", { className: "hidden h-7 w-7 shrink-0 items-center justify-center rounded-md text-white sm:flex sm:h-6 sm:w-6 md:h-8 md:w-8", children: typeof option.img === "string" ? (0, jsx_runtime_1.jsx)("img", { src: option.img, "aria-hidden": "true", alt: "Listbox Icon" }) : (0, jsx_runtime_1.jsx)(option.img, {}) }), (0, jsx_runtime_1.jsxs)("div", { className: "ml-1 mr-3 sm:ml-4", children: [(0, jsx_runtime_1.jsx)("p", { className: "text-xs font-medium text-neutral-900 sm:text-sm", children: option.title }), option.description && (0, jsx_runtime_1.jsx)("p", { className: "text-xs text-gray-500", children: option.description })] })] }, option.value) }));
                                } }, optionIdx)); }) }) })] }) }) }));
}
exports.ListBox = ListBox;
