import { Usage } from "../common/usage"
import { LogProbs } from "../common"

export type ChatToolCall = {
  id: string
  type: string
  function: {
    name: string
    arguments: string
  }
}
export type ChatFunctionCall<T = Record<string, any>> = {
  name: string
  arguments: T
}
export type ChatResult = {
  id: string
  model: string
  message: Message
  logprobs?: LogProbs
  finish_reason?: string
  usage?: Usage
  function_call?: ChatFunctionCall
}

export type Message = {
  role: ChatRole
  content?: string
  name?: string
  function_call?: {
    name: string
    arguments: string
  }
  tool_calls?: ChatToolCall[]
}

export type PartialChatResult = Omit<ChatResult, "function_call"> & {
  function_call?: {
    name?: string
    arguments?: string
  }
}

export type ChatResultStream = (partialMessages: PartialChatResult[]) => void

export enum ChatRole {
  System = "system",
  User = "user",
  Assistant = "assistant",
}
