import { SearchMessageType } from "./search.message.type"
import { SearchChatMessage } from "./search.message"

export class NotionChatMessage extends SearchChatMessage<NotionDocument> {
  public readonly searchType = SearchMessageType.Notion

  public get content(): string {
    const { project, title, text } = this.data
    return `${project}\n${title}\n${text}`
  }
}

export class NotionDocument {
  constructor(
    public readonly project: string,
    public readonly title: string,
    public readonly text: string,
    public readonly time: number,
    public readonly uri: string,
  ) {}
}
