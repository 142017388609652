import toml from "toml"
import { Dependencies } from "../types"

export function parseGopkgToml(fileContents: string): Dependencies {
  let gopkgTomlContents: any
  try {
    gopkgTomlContents = toml.parse(fileContents)
  } catch (err) {
    return {}
  }

  const dependencies: Dependencies = {}

  // The dependencies are located under the [[constraint]] sections in Gopkg.toml
  if (gopkgTomlContents["constraint"]) {
    gopkgTomlContents["constraint"].forEach((constraint: any) => {
      if (constraint["name"] && constraint["version"]) {
        dependencies[constraint["name"]] = constraint["version"]
      } else if (constraint["name"] && constraint["branch"]) {
        // If a branch is specified instead of a version, capture that
        dependencies[constraint["name"]] = `branch: ${constraint["branch"]}`
      } else if (constraint["name"] && constraint["revision"]) {
        // If a revision is specified instead of a version, capture that
        dependencies[constraint["name"]] = `revision: ${constraint["revision"]}`
      }
    })
  }

  return dependencies
}
