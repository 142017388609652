import { SearchMessageType } from "./search.message.type"
import { SearchChatMessage } from "./search.message"

export class LinearChatMessage extends SearchChatMessage<LinearTicket> {
  public override readonly searchType = SearchMessageType.Linear

  public get content(): string {
    const { id, title, description, assignee } = this.data
    return `${id} - ${title} (${assignee.name})\n${description}`
  }
}

export class LinearTicket {
  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly description: string,
    public readonly assignee: Assignee,
    public readonly uri: string,
  ) {}
}

export type Assignee = {
  image: string
  name: string
}
