import { ChatRole } from "@cosine/ai/types"
import { SearchMessageType } from "./search.message.type"
import { CustomChatMessage, CustomChatMessageType } from "../custom.chat.message"

export abstract class SearchChatMessage<T = any> extends CustomChatMessage<T> {
  public override readonly type = CustomChatMessageType.Search
  public override readonly role: ChatRole = ChatRole.Assistant

  public abstract override get content(): string
  public abstract readonly searchType: SearchMessageType

  public constructor(public override data: T) {
    super()
  }
}
