"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chat = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var chat_container_1 = require("./chat.container");
var common_1 = require("../../common");
var messages_1 = require("./messages");
var classification_1 = require("./types/classification");
var on_reaction_1 = require("./utils/on.reaction");
var message_reducer_1 = require("./components/misc/message.reducer");
var settings_1 = require("../settings");
var Chat = function (_a) {
    var onMessages = _a.onMessages, showHistory = _a.showHistory, showExamples = _a.showExamples, showTokens = _a.showTokens, _b = _a.variant, variant = _b === void 0 ? "vscode" : _b;
    var sendMessage = (0, react_1.useContext)(common_1.MessageContext).sendMessage;
    var _c = (0, react_1.useReducer)(message_reducer_1.messageReducer, []), messages = _c[0], setMessages = _c[1];
    var _d = (0, react_1.useState)([]), examples = _d[0], setExamples = _d[1];
    var _e = (0, react_1.useState)(0), tokens = _e[0], setTokens = _e[1];
    var _f = (0, react_1.useState)(0), maxTokens = _f[0], setMaxTokens = _f[1];
    var _g = (0, react_1.useState)(null), selection = _g[0], setSelection = _g[1];
    var _h = (0, react_1.useState)(false), showSelection = _h[0], setShowSelection = _h[1];
    var _j = (0, react_1.useState)(false), answering = _j[0], setAnswering = _j[1];
    var onSend = (0, react_1.useCallback)(function (prompt) { return sendMessage(messages_1.ChatMessage.Prompt, prompt); }, []);
    var onUpvote = (0, react_1.useCallback)(function (id, index) { return sendMessage(messages_1.ChatMessage.Reaction, (0, on_reaction_1.getReaction)(messages, id, index, classification_1.Classification.Good)); }, [messages]);
    var onDownvote = (0, react_1.useCallback)(function (id, index) { return sendMessage(messages_1.ChatMessage.Reaction, (0, on_reaction_1.getReaction)(messages, id, index, classification_1.Classification.Bad)); }, [messages]);
    var onTextChanged = (0, react_1.useCallback)(function (text) { return sendMessage(messages_1.ChatMessage.Tokens, text); }, []);
    var onStop = (0, react_1.useCallback)(function () { return sendMessage(messages_1.ChatMessage.Cancel); }, []);
    var onUseSelection = (0, react_1.useCallback)(function (use) { return sendMessage(messages_1.ChatMessage.UseSelected, use); }, []);
    var onOpenFile = (0, react_1.useCallback)(function (path, start, end) { return sendMessage(messages_1.ChatMessage.OpenFile, { path: path, start: start, end: end }); }, []);
    var _k = (0, react_1.useState)(false), focus = _k[0], setFocus = _k[1];
    (0, common_1.useMessage)(messages_1.ChatMessage.Answer, function (answer) { return setMessages({ type: "update", answer: answer }); });
    (0, common_1.useMessage)(messages_1.ChatMessage.Answering, function (status) { return setAnswering(status); });
    (0, common_1.useMessage)(messages_1.ChatMessage.Examples, function (examples) { return setExamples(examples); });
    (0, common_1.useMessage)(messages_1.ChatMessage.Tokens, function (tokens) { return setTokens(tokens); });
    (0, common_1.useMessage)(messages_1.ChatMessage.MaxTokens, function (maxTokens) { return setMaxTokens(maxTokens); });
    (0, common_1.useMessage)(messages_1.ChatMessage.TextSelection, function (selection) { return setSelection(selection); });
    (0, common_1.useMessage)(messages_1.ChatMessage.ClearSelection, function () { return setSelection(null); });
    (0, common_1.useMessage)(messages_1.ChatMessage.Clear, function () { return setMessages({ type: "clear" }); });
    (0, common_1.useMessage)(messages_1.ChatMessage.Conversation, function (answers) { return answers.forEach(function (answer) { return setMessages({ type: "update", answer: answer }); }); });
    (0, common_1.useMessage)(messages_1.ChatMessage.Focus, function () { return setFocus(true); });
    var _l = (0, settings_1.useSetting)(settings_1.Settings.AIModel, settings_1.ModelType.Faster), model = _l[0], setModel = _l[1];
    (0, react_1.useEffect)(function () { return setShowSelection(!!selection); }, [selection]);
    (0, react_1.useEffect)(function () { return sendMessage(messages_1.ChatMessage.Examples); }, []);
    (0, react_1.useEffect)(function () { return sendMessage(messages_1.ChatMessage.MaxTokens); }, []);
    (0, react_1.useEffect)(function () { return sendMessage(messages_1.ChatMessage.Conversation); }, []);
    (0, react_1.useEffect)(function () { return onMessages(messages.length); }, [messages]);
    return ((0, jsx_runtime_1.jsx)(chat_container_1.ChatContainer, { variant: variant, onSend: onSend, onUpvote: onUpvote, onDownvote: onDownvote, onTextChanged: onTextChanged, onUseSelection: onUseSelection, onStop: onStop, onOpenFile: onOpenFile, messages: messages, examples: examples, tokens: tokens, maxTokens: maxTokens, showExamples: showExamples, showSelection: showSelection, selection: selection, answering: answering, showTokens: showTokens, showHistory: showHistory, model: model, setModel: setModel, focus: focus, setFocus: setFocus }));
};
exports.Chat = Chat;
