import { SearchChatMessage } from "./search.message"
import { SearchMessageType } from "."

export class NoResultsChatMessage extends SearchChatMessage<string> {
  public override searchType = SearchMessageType.None
  public message = "No results found for"

  public get content(): string {
    return `${this.message} "${this.data}"`
  }
}
