import { CancellationToken } from "@cosine/cancellation"
import { Network as BaseNetwork } from "@cosine/network"
import { RequestInit } from "node-fetch"

export class Network extends BaseNetwork {
  constructor(url: string) {
    super({ url, headers: {}, agent: "cosine-web-demo", version: "2.0.1" })
  }
  public async fetch<T>(url: string, request: RequestInit, cancellationToken?: CancellationToken, stream = false, attempts = 0): Promise<T> {
    request.headers = {
      ...request.headers,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    }
    return await super.fetch<T>(url, request, cancellationToken, stream, attempts)
  }

  protected async getToken(): Promise<string | undefined> {
    return await Promise.resolve("mock_token")
  }
  protected async reauthenticate(): Promise<void> {
    return await Promise.resolve()
  }
}
