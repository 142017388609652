"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDownSetting = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var setting_1 = require("./setting");
var dropdown_1 = require("../../../common/components/dropdown");
function DropDownSetting(props) {
    var title = props.title, description = props.description, icon = props.icon, options = props.options, setOption = props.setOption, label = props.label;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex py-2 first:pt-2 last:pb-2 sm:py-4 first:sm:pt-3 last:sm:pb-2", children: [icon ? (0, jsx_runtime_1.jsx)("div", { className: "pr-4 pt-2", children: icon }) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), (0, jsx_runtime_1.jsx)(setting_1.Setting, { title: title, description: description, className: "flex-col items-end space-y-3 md:flex-row md:items-center md:space-x-5 md:space-y-0 lg:space-x-6", children: (0, jsx_runtime_1.jsx)(dropdown_1.DropDown, { label: label, options: options, setOption: setOption }) })] }));
}
exports.DropDownSetting = DropDownSetting;
