import { RateLimitData } from "../types"
import { RateLimitProps } from "../types"

export class RateLimitException extends Error {
  constructor(
    public limit: RateLimitProps,
    public data?: RateLimitData,
  ) {
    super(limit.reason)
  }
}
