import { TelemetryHandler } from "./telemetry.handler"
import { User, Event, Breadcrumb } from "./types"

export class Telemetry {
  protected static handlers: TelemetryHandler[] = []
  protected static enabled: boolean

  public static init = (handlers: TelemetryHandler[], enabled = true) => {
    this.handlers = handlers
    this.enabled = enabled
  }

  public static getHandlers() {
    return this.handlers
  }

  public static captureEvent = (event: Event) => {
    if (!this.enabled) {
      return
    }
    if (!this.handlers) {
      return
    }
    this.activeHandlers.forEach((handler) => handler.captureEvent(event))
  }

  public static captureException = (exception: any) => {
    if (!this.enabled) {
      return
    }
    if (!this.handlers) {
      return
    }
    this.activeHandlers.forEach((handler) => handler.captureException(exception))
  }

  public static captureMessage = (message: string) => {
    if (!this.enabled) {
      return
    }
    if (!this.handlers) {
      return
    }
    this.activeHandlers.forEach((handler) => handler.captureMessage(message))
  }

  public static addBreadcrumb = (breadcrumb: Breadcrumb) => {
    if (!this.enabled) {
      return
    }
    if (!this.handlers) {
      return
    }
    this.activeHandlers.forEach((handler) => handler.addBreadcrumb(breadcrumb))
  }

  private static get activeHandlers(): TelemetryHandler[] {
    return this.handlers.filter((handler) => handler.enabled)
  }

  public static setUser = async (user: User): Promise<void> => this.activeHandlers.forEach((handler) => handler.setUser(user))

  public static enable = (): boolean => (this.enabled = true)

  public static disable = (): boolean => (this.enabled = false)

  public static setTag = async (key: string, value: any): Promise<void> => this.activeHandlers.forEach((handler) => handler.setTag(key, value))

  public static flush = async (): Promise<any> => this.activeHandlers.forEach((handler) => handler.flush())

  public static close = async (): Promise<any> => this.activeHandlers.forEach((handler) => handler.close())
}
