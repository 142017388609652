"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMessage = void 0;
var ChatMessage;
(function (ChatMessage) {
    ChatMessage["Prompt"] = "Prompt";
    ChatMessage["Conversation"] = "Conversation";
    ChatMessage["Answer"] = "Answer";
    ChatMessage["Answering"] = "Answering";
    ChatMessage["Clear"] = "Clear";
    ChatMessage["Reaction"] = "Reaction";
    ChatMessage["Examples"] = "Examples";
    ChatMessage["Tokens"] = "Tokens";
    ChatMessage["MaxTokens"] = "MaxTokens";
    ChatMessage["Cancel"] = "Cancel";
    ChatMessage["TextSelection"] = "TextSelection";
    ChatMessage["ClearSelection"] = "ClearSelection";
    ChatMessage["UseSelected"] = "UseSelected";
    ChatMessage["Focus"] = "Focus";
    ChatMessage["OpenFile"] = "OpenFile";
    ChatMessage["MessageLimit"] = "MessageLimit";
    ChatMessage["Model"] = "Model";
})(ChatMessage || (exports.ChatMessage = ChatMessage = {}));
