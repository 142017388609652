"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevToolsPanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var components_1 = require("../components");
var environment_1 = require("../types/environment");
var DevToolsPanel = function (props) {
    var environments = [
        { title: "Local", value: environment_1.Environment.Develop },
        { title: "Staging", value: environment_1.Environment.Staging },
        { title: "Production", value: environment_1.Environment.Production },
    ];
    var _a = props.environment.properties, environmentTitle = _a.title, environmentDescription = _a.description;
    var _b = props.environment, environment = _b.value, setEnvironment = _b.onChange;
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex flex-col divide-y divide-surface-bright last:pb-1 last:sm:pb-2", children: (0, jsx_runtime_1.jsx)(components_1.SettingGroup, { title: "Network", children: (0, jsx_runtime_1.jsx)(components_1.RadioSetting, { title: environmentTitle, description: environmentDescription, options: environments, selected: environment, setSelected: setEnvironment }) }) }));
};
exports.DevToolsPanel = DevToolsPanel;
