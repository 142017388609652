"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCorners = void 0;
var getCorners = function (side, top, bottom) {
    var tr = top || side === "left";
    var tl = top || side === "right";
    var br = bottom || side === "left";
    var bl = bottom || side === "right";
    return { tr: tr, tl: tl, br: br, bl: bl };
};
exports.getCorners = getCorners;
