import { MockNetwork } from "@cosine/network/mock"
import { AIModel } from "../../models"
import { ChatResultStream, ChatResult, CompletionStreamListener, CompletionResult, EmbeddingResult, CustomEmbeddingResult } from "../../types"
import { CreateChatProps, CreateCompletionProps, CreateEmbeddingProps } from "../ai"
import { OpenAI } from "../openai"
import { RemoteAI } from "./remote.ai"
import { CancellationToken } from "@cosine/cancellation"

export class MockRemoteAi extends RemoteAI {
  private openAi: OpenAI

  constructor(openAi: OpenAI) {
    super(new MockNetwork(""))
    this.openAi = openAi
  }

  public override createChat(props: CreateChatProps, cancelToken?: CancellationToken, stream?: ChatResultStream | undefined): Promise<ChatResult | undefined> {
    return this.openAi.createChat(props, cancelToken, stream)
  }

  public override createCompletion(
    props: CreateCompletionProps,
    cancelToken: CancellationToken,
    streamCallback?: CompletionStreamListener | undefined,
  ): Promise<CompletionResult | undefined> {
    return this.openAi.createCompletion(props, cancelToken, streamCallback)
  }

  public override createEmbedding(props: CreateEmbeddingProps, cancelToken?: CancellationToken): Promise<EmbeddingResult | undefined> {
    return this.openAi.createEmbedding(props, cancelToken)
  }

  public override createEmbeddingCustom(_payloadprops: CreateEmbeddingProps, _cancelToken?: CancellationToken): Promise<CustomEmbeddingResult[] | undefined> {
    // const embedding = this.openAi.createEmbedding(payloadprops)
    // TODO: convert to custom embedding
    throw new Error("Function not yet mocked")
  }
  public override preprocessEmbeddings(
    inputs: string[],
    model: AIModel,
    targetRequestByteSize: number,
    cancelToken: CancellationToken,
  ): Promise<{ processedInput: string; originalIndex: number }[][]> {
    return this.openAi.preprocessEmbeddings(inputs, model, targetRequestByteSize, cancelToken)
  }
}
