/* eslint-disable no-unused-vars */
import { jsonrepair } from "jsonrepair"

/**
 * Determines whether a string is a JSON object
 * @param str A string potentially containing a JSON object
 * @returns whether the string represents a JSON object
 */
export function isJSON(str: string): boolean {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

/**
 * Extracts a JSON string from a string
 * @param str A string containing a JSON object
 * @returns The extracted JSON object
 */
export function extractJSON(str: string): string | undefined {
  const match = str.match(/(\[|{)(.|\n)+(\]|})/gm)
  if (match) {
    return match[0]
  }
  return undefined
}

/**
 * This function replaces unquoted strings with quoted strings
 * @param str A string containing a JSON object
 * @returns The JSON object
 */
function quoteUnquotedStrings(str: string) {
  // This is a very naive approach to quote unquoted strings.
  // This regex will quote any series of digits which contain any non-digit characters
  const matches = str.match(/(?<=: )\d+[^,\n]+(?!",)/g)
  if (matches) {
    const relevant = matches.filter((el) => el.match(/[^\d]+/g))
    for (const relevantMatch of relevant) {
      str = str.replace(relevantMatch, `"${relevantMatch}"`)
    }
  }
  // This regex finds all unquoted strings which come after a colon and replace them with quoted versions
  str = str.replace(/(?<=: )[a-zA-Z]+[^,\nfalsetrue]+(?!",)/g, `"$&"`)
  return str
}

/**
 * The main function for sanitising JSON, will attempt to repair the JSON if it is invalid
 * @param json A string containing a JSON object
 * @returns The JSON object
 */
export function sanitizeJSON(json: string): any | undefined {
  if (!json) {
    return undefined
  }
  json = json.replace("...", "")
  const tried = tryParse(json)
  if (tried) {
    return tried
  }
  let extracted = extractJSON(json)
  if (!extracted) {
    return undefined
  }
  const initial = tryParse(extracted)
  if (initial) {
    return initial
  }
  const qus = quoteUnquotedStrings(extracted)
  const quoted = tryParse(qus)
  if (quoted) {
    return quoted
  }
  const repaired = tryRepair(extracted)
  if (repaired) {
    return repaired
  }
  extracted = quoteUnquotedStrings(extracted)
  const repairedQuoted = tryRepair(extracted)
  if (repairedQuoted) {
    return repairedQuoted
  }
  return undefined
}

function tryParse(jsonString: string): any | undefined {
  try {
    const o = JSON.parse(jsonString)
    if (o && typeof o === "object") {
      return o
    }
  } catch (e) {}
  return undefined
}

function tryRepair(jsonString: string): any | undefined {
  try {
    const o = jsonrepair(jsonString)
    return tryParse(o)
  } catch (e) {}
  return undefined
}
