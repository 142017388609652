import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "./custom.chat.message"

export class FilesystemChatMessage extends CustomChatMessage<string> {
  public override readonly type = CustomChatMessageType.File
  public override readonly role: ChatRole = ChatRole.Assistant

  constructor(public override data: string) {
    super()
  }

  public override get content(): string {
    return `[FILESYSTEM]\n${this.data}`
  }
}
