import { InteractionEvent } from "./interaction.event"
import { Classification } from "./classification.type"
import { InteractionType } from "./interaction.type"

export type InteractionProps = {
  prompt: string
}

export class Interaction {
  public readonly prompt: string
  private events: InteractionEvent[] = []
  private classification?: Classification

  constructor(props: InteractionProps) {
    this.prompt = props.prompt
  }

  public get duration(): number {
    if (this.events.length < 2) {
      return 0
    }
    const start = this.events[0].timestamp
    const end = this.events[this.events.length - 1].timestamp
    return end - start
  }

  public addEvent(type: InteractionType, timestamp = Date.now()) {
    this.events.push({ type, timestamp })
  }

  public getEvents() {
    return this.events
  }

  public setClassification(classification: Classification) {
    this.classification = classification
  }

  public getClassification() {
    return this.classification
  }
}
