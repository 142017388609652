export enum Language {
  JavaScript = "JavaScript",
  TypeScript = "TypeScript",
  TSX = "TSX",
  Python = "Python",
  Golang = "Golang",
  Ruby = "Ruby",
  Java = "Java",
  C_Sharp = "C_Sharp",
  C = "C",
  CPP = "CPP",
  PHP = "PHP",
  Rust = "Rust",
  Swift = "Swift",
  Kotlin = "Kotlin",
  Scala = "Scala",
  Cobol = "Cobol",
  CommonLisp = "CommonLisp",
  HTML = "HTML",
  CSS = "CSS",
  Bash = "Bash",
  Haskell = "Haskell",
  SQL = "SQL",
  Dart = "Dart",
  Vue = "Vue",
  YAML = "YAML",
  JSON = "JSON",
  Lua = "Lua",
  Elm = "Elm",
  Toml = "Toml",
  Perl = "Perl",
  R = "R",
  ObjectiveC = "ObjectiveC",
  Assembly = "Assembly",
  Julia = "Julia",
  Elixir = "Elixir",
  Erlang = "Erlang",
  Groovy = "Groovy",
  Markdown = "Markdown",
  Nim = "Nim",
  Powershell = "Powershell",
  Latex = "Latex",
  Verilog = "Verilog",
  VHDL = "VHDL",
  XML = "XML",
  Clojure = "Clojure",
  FSharp = "FSharp",
  GraphQL = "GraphQL",
  Scheme = "Scheme",
  Matlab = "Matlab",
  Pug = "Pug",
  Pascal = "Pascal",
  Zig = "Zig",
  PlainText = "PlainText",
}

export type LanguageCount = {
  language: Language
  count: number
}

export const languageExtensions: Record<Language, string[]> = {
  [Language.PlainText]: [],
  [Language.JavaScript]: [
    ".js",
    "._js",
    ".bones",
    ".cjs",
    ".es",
    ".es6",
    ".frag",
    ".gs",
    ".jake",
    ".javascript",
    ".jsb",
    ".jscad",
    ".jsfl",
    ".jslib",
    ".jsm",
    ".jspre",
    ".jss",
    ".jsx",
    ".mjs",
    ".njs",
    ".pac",
    ".sjs",
    ".ssjs",
    ".xsjs",
    ".xsjslib",
  ],
  [Language.TypeScript]: [".ts", ".cts", ".mts"],
  [Language.TSX]: [".tsx"],
  [Language.Python]: [".py", ".cgi", ".fcgi", ".gyp", ".gypi", ".lmi", ".py3", ".pyde", ".pyi", ".pyp", ".pyt", ".pyw", ".rpy", ".smk", ".spec", ".tac", ".wsgi", ".xpy"],
  [Language.Golang]: [".go"],
  [Language.Ruby]: [".rb"],
  [Language.Java]: [".java"],
  [Language.C_Sharp]: [".cs"],
  [Language.C]: [".c", ".h"],
  [Language.CPP]: [".cpp", ".hpp", ".cc", ".hh", ".cxx", ".hxx", ".c++", ".h++", ".cp", ".hp", ".C", ".H", ".ino"],
  [Language.PHP]: [".php", ".php3", ".php4", ".php5", ".php7", ".phps", ".phtml"],
  [Language.Rust]: [".rs"],
  [Language.Swift]: [".swift"],
  [Language.Kotlin]: [".kt", ".kts"],
  [Language.Scala]: [".scala", ".sc"],
  [Language.Cobol]: [".cbl", ".cob", ".cpy", ".cobol"],
  [Language.CommonLisp]: [".lisp"],
  [Language.HTML]: [".html", ".htm", ".html.hl", ".inc", ".st", ".xht", ".xhtml"],
  [Language.CSS]: [".css", ".scss", ".sass"],
  [Language.Bash]: [".sh", ".bash", ".bats", ".cgi", ".command", ".fcgi", ".ksh", ".sh.in", ".tmux", ".tool", ".zsh"],
  [Language.Haskell]: [".hs", ".lhs"],
  [Language.SQL]: [".sql", ".dsql", ".plpgsql", ".pgsql", ".hql", ".mssql", ".mysql", ".sqlite", ".sqlite3", ".sqlproj", ".sqlx", ".xql", ".xqm", ".xquery", ".yql"],
  [Language.Dart]: [".dart"],
  [Language.Vue]: [".vue"],
  [Language.YAML]: [".yaml", ".yml"],
  [Language.JSON]: [
    ".json",
    ".jshintrc",
    ".bowerrc",
    ".jscsrc",
    ".eslintrc",
    ".babelrc",
    ".npmrc",
    ".node-inspectorrc",
    ".prettierrc",
    ".term-project",
    ".watchmanconfig",
    ".stylelintrc",
  ],
  [Language.Lua]: [".lua"],
  [Language.Elm]: [".elm"],
  [Language.Toml]: [".toml"],
  [Language.Perl]: [".pl", ".al", ".cgi", ".fcgi", ".perl", ".ph", ".plx", ".pm", ".pod", ".psgi", ".t"],
  [Language.R]: [".r", ".rd", ".rsx"],
  [Language.ObjectiveC]: [".m", ".h"],
  [Language.Assembly]: [".asm", ".a51", ".inc", ".nasm", ".s", ".s51"],
  [Language.Julia]: [".jl"],
  [Language.Elixir]: [".ex", ".exs"],
  [Language.Erlang]: [".erl", ".hrl", ".emakefile", ".escript", ".xrl", ".yrl"],
  [Language.Groovy]: [".groovy", ".grt", ".gtpl", ".gvy"],
  [Language.Markdown]: [".md", ".markdown", ".mdown", ".mdwn", ".mkd", ".mkdn", ".mkdown", ".ron"],
  [Language.Nim]: [".nim", ".nim.cfg", ".nimble", ".nimrod"],
  [Language.Powershell]: [".ps1", ".psd1", ".psm1"],
  [Language.Latex]: [".tex", ".aux", ".bbx", ".cbx", ".cls", ".dtx", ".ins", ".lbx", ".ltx", ".mkii", ".mkiv", ".mkvi", ".sty", ".toc", ".xkv", ".xwm"],
  [Language.Verilog]: [".v", ".veo", ".sv", ".svh", ".vh"],
  [Language.VHDL]: [".vhd", ".vhdl"],
  [Language.Clojure]: [".clj", ".boot", ".cl2", ".cljc", ".cljs", ".cljs.hl", ".cljscm", ".cljx", ".hic", ".hl", ".hxml", ".joke", ".lfe"],
  [Language.FSharp]: [".fs", ".fsi", ".fsx", ".fsscript"],
  [Language.GraphQL]: [".graphql", ".gql"],
  [Language.Scheme]: [".lisp", ".asd", ".cl", ".l", ".lsp", ".ny", ".podsl", ".sexp"],
  [Language.Matlab]: [".m"],
  [Language.XML]: [".xml"],
  [Language.Pug]: [".pug", ".jade"],
  [Language.Pascal]: [".pas", ".dfm", ".dpr", ".inc", ".lpr", ".pp", ".ppu"],
  [Language.Zig]: [".zig"],
}

export function isACodeFile(path: string): boolean {
  // Loop over the entries in the languageExtensions object
  for (const [language, extensions] of Object.entries(languageExtensions)) {
    // If the path ends with any of the extensions, return true
    if (extensions.some((extension) => path.endsWith(extension))) {
      const nonCodeLanguages = [Language.PlainText, Language.JSON, Language.YAML, Language.Toml, Language.XML, Language.Markdown]
      return !nonCodeLanguages.includes(language as Language)
    }
  }
  return false
}

export const commonSourceDirectories: Record<Language, string[]> = {
  [Language.PlainText]: [],
  [Language.JavaScript]: ["src", "lib", "app", "js", "pkg", "spec", "test"],
  [Language.TypeScript]: ["src", "lib", "app", "pkg", "spec", "test"],
  [Language.TSX]: ["src", "app", "pkg", "spec", "test"],
  [Language.Python]: ["src", "app", "my_module"],
  [Language.Golang]: ["src", "pkg", "cmd"],
  [Language.C]: ["src", "lib"],
  [Language.CPP]: ["src", "lib"],
  [Language.Java]: ["src/main/java", "src"],
  [Language.PHP]: ["src", "app", "lib", "public"],
  [Language.Ruby]: ["lib", "app", "src"],
  [Language.Rust]: ["src", "lib"],
  [Language.C_Sharp]: ["src", "app", "Controllers", "Models"],
  [Language.Swift]: ["Sources", "Tests", "Apps"],
  [Language.Kotlin]: ["src/main/kotlin", "src/test/kotlin", "src"],
  [Language.Scala]: ["src/main/scala", "src/main/resources", "src/test/scala", "src/test/resources", "src"],
  [Language.Cobol]: ["src"],
  [Language.CommonLisp]: ["src"],
  [Language.HTML]: ["src", "public", "app", "lib"],
  [Language.CSS]: ["src", "public", "app", "lib"],
  [Language.Bash]: ["src", "public", "app", "lib"],
  [Language.Haskell]: ["src", "lib", "app"],
  [Language.SQL]: ["src", "lib", "app"],
  [Language.Dart]: ["src"],
  [Language.Vue]: ["src", "components", "assets", "store", "router", "views"],
  [Language.YAML]: [],
  [Language.JSON]: [],
  [Language.Lua]: ["src", "lib"],
  [Language.Elm]: ["src", "lib"],
  [Language.Toml]: [],
  [Language.Perl]: ["lib", "t"],
  [Language.R]: ["R", "src", "inst", "tests", "data", "vignettes"],
  [Language.ObjectiveC]: ["Src", "Sources", "Classes", "App"],
  [Language.Assembly]: ["src", "lib"],
  [Language.Julia]: ["src"],
  [Language.Elixir]: ["lib", "test"],
  [Language.Erlang]: ["src", "include"],
  [Language.Groovy]: ["src", "spec"],
  [Language.Markdown]: [],
  [Language.Nim]: ["src", "tests"],
  [Language.Powershell]: ["src", "lib"],
  [Language.Latex]: ["tex"],
  [Language.Verilog]: ["src"],
  [Language.VHDL]: ["src"],
  [Language.XML]: [],
  [Language.Clojure]: ["src"],
  [Language.FSharp]: ["src"],
  [Language.GraphQL]: [],
  [Language.Scheme]: ["src"],
  [Language.Matlab]: ["src"],
  [Language.Pug]: [],
  [Language.Pascal]: [],
  [Language.Zig]: ["src"],
}

export const dependencyManagementFiles: Record<Language, string[]> = {
  [Language.PlainText]: [],
  [Language.TypeScript]: ["package.json"],
  [Language.JavaScript]: ["package.json"],
  [Language.TSX]: ["package.json"],
  [Language.Python]: ["requirements.txt", "Pipfile", "pyproject.toml", "conda.yaml"],
  [Language.Golang]: ["go.mod", "Gopkg.toml"],
  [Language.Ruby]: ["Gemfile", "gems.rb"],
  [Language.Java]: ["pom.xml", "build.gradle", "settings.gradle", "build.sbt", "ivy.xml"],
  [Language.CPP]: ["CMakeLists.txt", "conanfile.txt", "conanfile.py", "vcpkg.json"],
  [Language.C_Sharp]: ["packages.config", "PackageReference", "project.json", "paket.dependencies"],
  [Language.Kotlin]: ["build.gradle.kts", "settings.gradle.kts", "build.gradle", "settings.gradle"],
  [Language.Swift]: ["Package.swift", "Cartfile", "Cartfile.private", "Podfile"],
  [Language.C]: ["Makefile"],
  [Language.PHP]: ["composer.json"],
  [Language.Rust]: ["Cargo.toml"],
  [Language.Scala]: ["build.sbt", "build.sc", "project/build.properties", "project/Build.scala"],
  [Language.Cobol]: ["cobc.conf"],
  [Language.CommonLisp]: ["asdf.lisp", "ASDF", "Quicklisp", "ASDF-Install", "cl-project"],
  [Language.HTML]: ["package.json"],
  [Language.CSS]: ["package.json"],
  [Language.Bash]: [],
  [Language.Haskell]: [".cabal", "cabal.project", "cabal.project.freeze", "stack.yaml", "stack.lock"],
  [Language.SQL]: [],
  [Language.Dart]: ["pubspec.yaml"],
  [Language.Vue]: ["package.json"],
  [Language.YAML]: [],
  [Language.JSON]: [],
  [Language.Lua]: ["rockspec.lua", "rockspec"],
  [Language.Elm]: ["elm.json"],
  [Language.Toml]: ["Gopkg.toml", "Pipfile", "pyproject.toml", "Cargo.toml"],
  [Language.Perl]: ["META.json", "META.yml", "Build.pl", "Makefile.PL"],
  [Language.R]: ["DESCRIPTION"],
  [Language.ObjectiveC]: ["Podfile", "Cartfile"],
  [Language.Assembly]: [],
  [Language.Julia]: ["Project.toml", "Manifest.toml"],
  [Language.Elixir]: ["mix.exs"],
  [Language.Erlang]: ["rebar.config"],
  [Language.Groovy]: ["build.gradle"],
  [Language.Markdown]: [],
  [Language.Nim]: ["nimble.nimble", "config.nims"],
  [Language.Powershell]: ["ps1xml", "psc1", "psd1", "pssc", "cdxml", "ps1"],
  [Language.Latex]: ["latexmkrc"],
  [Language.Verilog]: [],
  [Language.VHDL]: [],
  [Language.XML]: ["pom.xml"],
  [Language.Clojure]: ["project.clj", "deps.edn"],
  [Language.FSharp]: ["paket.dependencies"],
  [Language.GraphQL]: [],
  [Language.Scheme]: [".asd"],
  [Language.Matlab]: [],
  [Language.Pug]: [],
  [Language.Pascal]: [],
  [Language.Zig]: ["build.zig"],
}

export const likelyHelpfulFiles: Record<Language, string[]> = {
  [Language.PlainText]: [],
  [Language.TypeScript]: ["README.md", "package.json", "index.ts"],
  [Language.JavaScript]: ["README.md", "package.json", "index.js"],
  [Language.TSX]: ["README.md", "package.json", "index.tsx"],
  [Language.Python]: ["README.md", "requirements.txt", "Pipfile", "pyproject.toml", "conda.yaml", "index.py"],
  [Language.Golang]: ["README.md", "go.mod", "main.go"],
  [Language.Ruby]: ["README.md", "Gemfile", "gems.rb", "app.rb"],
  [Language.Java]: ["README.md", "pom.xml", "build.gradle", "Main.java"],
  [Language.CPP]: ["README.md", "Makefile", "CMakeLists.txt", "conanfile.txt", "conanfile.py", "vcpkg.json", "main.cpp"],
  [Language.C_Sharp]: ["README.md", "packages.config", "PackageReference", "project.json", "paket.dependencies", "Program.cs", "App.config", "Program.cs", ".csproj", "web.config"],
  [Language.Kotlin]: ["README.md", "build.gradle.kts", "Main.kt"],
  [Language.Swift]: ["README.md", "Package.swift", "AppDelegate.swift", "Podfile", "Cartfile"],
  [Language.C]: ["README.md", "Makefile", "main.c"],
  [Language.PHP]: ["README.md", "composer.json", "index.php"],
  [Language.Rust]: ["README.md", "Cargo.toml", "main.rs"],
  [Language.Scala]: ["README.md", "build.sbt", "build.sc", "Main.scala"],
  [Language.Cobol]: ["README.md", "cobc.conf", "main.cob"],
  [Language.CommonLisp]: ["README.md", "asdf.lisp", "ASDF", "Quicklisp", "ASDF-Install", "cl-project", "main.lisp"],
  [Language.HTML]: ["README.md", "index.html"],
  [Language.CSS]: ["README.md", "index.css"],
  [Language.Bash]: ["README.md", "index.sh"],
  [Language.Haskell]: ["README.md", ".cabal", "cabal.project", "cabal.project.freeze", "stack.yaml", "stack.lock", "Main.hs"],
  [Language.SQL]: ["README.md", "schema.sql", "data.sql", "query.sql"],
  [Language.Dart]: ["README.md", "pubspec.yaml", "main.dart"],
  [Language.Vue]: ["README.md", "package.json", "main.js", "App.vue"],
  [Language.YAML]: ["README.md", "config.yaml", ".travis.yml", "docker-compose.yml"],
  [Language.JSON]: ["README.md", "package.json", "config.json"],
  [Language.Lua]: ["README.md", "rockspec.lua", "rockspec", "main.lua"],
  [Language.Elm]: ["README.md", "elm.json", "Main.elm"],
  [Language.Toml]: ["README.md", "config.toml"],
  [Language.Perl]: ["README.md", "META.json", "META.yml", "Build.pl", "Makefile.PL", "main.pl"],
  [Language.R]: ["README.md", "DESCRIPTION"],
  [Language.ObjectiveC]: ["README.md", "AppDelegate.h", "AppDelegate.m", "main.m", "Podfile", "Cartfile"],
  [Language.Assembly]: ["README.md", "main.asm"],
  [Language.Julia]: ["README.md", "Project.toml", "Manifest.toml", "main.jl"],
  [Language.Elixir]: ["README.md", "mix.exs", "app.ex"],
  [Language.Erlang]: ["README.md", "rebar.config", "app.erl"],
  [Language.Groovy]: ["README.md", "build.gradle", "main.groovy"],
  [Language.Markdown]: ["README.md"],
  [Language.Nim]: ["README.md", "nimble.nimble", "config.nims", "main.nim"],
  [Language.Powershell]: ["README.md", "main.ps1"],
  [Language.Latex]: ["README.md", "main.tex"],
  [Language.Verilog]: ["README.md", "main.v"],
  [Language.VHDL]: ["README.md", "main.vhdl"],
  [Language.XML]: ["README.md", "pom.xml", "config.xml"],
  [Language.Clojure]: ["README.md", "project.clj", "deps.edn", "main.clj"],
  [Language.FSharp]: ["README.md", "paket.dependencies"],
  [Language.GraphQL]: ["README.md", "schema.graphql"],
  [Language.Scheme]: ["README.md", ".asd"],
  [Language.Matlab]: ["README.md", "main.m"],
  [Language.Pug]: ["README.md", "index.pug"],
  [Language.Pascal]: ["README.md", "main.pas"],
  [Language.Zig]: ["README.md", "build.zig", "main.zig"],
}
