import { SearchMessageType } from "./search.message.type"
import { SearchChatMessage } from "./search.message"

export class SlackChatMessage extends SearchChatMessage<SlackThread> {
  public override readonly searchType = SearchMessageType.Slack

  public get content(): string {
    const { messages, channel } = this.data
    return `#${channel}\n${messages.map((m) => `${m.name}:\n${m.text}`).join("\n")}`
  }
}

export class SlackThread {
  constructor(
    public readonly messages: SlackMessage[],
    public readonly channel: string,
    public readonly uri: string,
  ) {}
}

export class SlackMessage {
  constructor(
    public readonly name: string,
    public readonly image: string,
    public readonly text: string,
    public readonly time: number,
  ) {}
}
