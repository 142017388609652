"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MigrateCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@headlessui/react");
var common_1 = require("../../../../common");
var messages_1 = require("../../messages");
var progress_area_1 = require("./progress.area");
var progress_bar_1 = require("./progress.bar");
var progress_sub_bar_1 = require("./progress.sub.bar");
var task_1 = require("./task");
function MigrateCard(props) {
    var _a;
    var status = props.status, onUpgrade = props.onUpgrade, onRetry = props.onRetry, onNext = props.onNext, onRestart = props.onRestart;
    var _b = (0, react_1.useState)(), task = _b[0], setTask = _b[1];
    (0, common_1.useMessage)(messages_1.MigrateMessage.Progress, function (data) {
        setTask({
            preTitle: "Upgrade Index",
            postTitle: "Index Upgraded",
            status: status,
            subtasks: data.map(function (datum) { return ({
                id: datum.id,
                preTitle: datum.title,
                postTitle: datum.title,
                status: datum.status,
                progress: [
                    {
                        percent: datum.percent,
                        subtitle: datum.title,
                        status: datum,
                    },
                ],
            }); }),
        });
    });
    (0, react_1.useEffect)(function () {
        if (!task) {
            return;
        }
        setTask(__assign(__assign({}, task), { status: status, subtasks: task.subtasks.map(function (subtask) {
                return __assign(__assign({}, subtask), { status: status, progress: subtask.progress.map(function (progress) {
                        return __assign(__assign({}, progress), { status: status });
                    }) });
            }) }));
    }, [status]);
    var title = (_a = task === null || task === void 0 ? void 0 : task.preTitle) !== null && _a !== void 0 ? _a : "Upgrade Index";
    return ((0, jsx_runtime_1.jsx)("div", { className: "w-full", children: (0, jsx_runtime_1.jsx)(react_2.Transition, { appear: true, show: true, enter: "transform transition ease-in-out duration-300 sm:duration-500", enterFrom: "translate-y-5 opacity-0", enterTo: "translate-y-0 opacity-100", children: (0, jsx_runtime_1.jsx)(common_1.Card, { className: "overflow-hidden px-3 py-2 sm:p-4 md:px-5 md:py-6", children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(progress_area_1.ProgressArea, { title: title, status: status, bottom: true, children: task === null || task === void 0 ? void 0 : task.subtasks.map(function (subtask, index) {
                                return task.subtasks.length === 1 ? (0, jsx_runtime_1.jsx)(progress_bar_1.ProgressBar, { subtask: subtask }, index) : (0, jsx_runtime_1.jsx)(progress_sub_bar_1.SubProgressBar, { subtask: subtask }, index);
                            }) }), (0, jsx_runtime_1.jsx)(ButtonRow, { status: status, onUpgrade: onUpgrade, onRetry: onRetry, onRestart: onRestart, onNext: onNext })] }) }) }) }));
}
exports.MigrateCard = MigrateCard;
function ButtonRow(props) {
    var status = props.status, onUpgrade = props.onUpgrade, onRetry = props.onRetry, onRestart = props.onRestart, onNext = props.onNext;
    switch (status) {
        case task_1.Status.Waiting:
            return (0, jsx_runtime_1.jsx)(WaitingButtons, { onUpgrade: onUpgrade });
        case task_1.Status.Loading:
            return (0, jsx_runtime_1.jsx)(ProgressButtons, {});
        case task_1.Status.Done:
            return (0, jsx_runtime_1.jsx)(CompletedButtons, { onContinue: onNext });
        case task_1.Status.Failed:
            return (0, jsx_runtime_1.jsx)(RetryButtons, { onRetry: onRetry, onRestart: onRestart });
        case task_1.Status.Cancelled:
            return (0, jsx_runtime_1.jsx)(RetryButtons, { onRetry: onRetry, onRestart: onRestart });
    }
}
function CompletedButtons(props) {
    var onContinue = props.onContinue;
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex flex-row justify-end", children: (0, jsx_runtime_1.jsx)("div", { className: "mt-5 flex sm:mt-8 md:mt-10", children: (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, label: "Continue", onClick: function () { return onContinue(); } }) }) }));
}
function ProgressButtons() {
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex flex-row justify-end", children: (0, jsx_runtime_1.jsx)("div", { className: "mt-5 flex sm:mt-8 md:mt-10", children: (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, disabled: true, label: "Continue" }) }) }));
}
function RetryButtons(props) {
    var onRetry = props.onRetry, onRestart = props.onRestart;
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex flex-row justify-end", children: (0, jsx_runtime_1.jsxs)("div", { className: "mt-5 flex sm:mt-8 md:mt-10 gap-2", children: [(0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, label: "Clear & Restart", onClick: function () { return onRestart(); } }), (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, label: "Retry", onClick: function () { return onRetry(); } })] }) }));
}
function WaitingButtons(props) {
    var onUpgrade = props.onUpgrade;
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex flex-row justify-end", children: (0, jsx_runtime_1.jsx)("div", { className: "mt-5 flex sm:mt-8 md:mt-10", children: (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, label: "Upgrade", onClick: function () { return onUpgrade(); } }) }) }));
}
