import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "./custom.chat.message"

export class SystemPromptMessage extends CustomChatMessage<string> {
  public override type: CustomChatMessageType = CustomChatMessageType.SystemPrompt
  public override role: ChatRole = ChatRole.System
  public override content: string
  public override data: string = this.content

  constructor(prompt: string) {
    super()
    this.content = prompt
  }
}
