import gemfileParser = require("gemfile-parser")
import { Dependencies } from "../types"

export function parseGemfile(fileContents: string): Dependencies {
  try {
    const parsed = gemfileParser.parseGemfile(fileContents) as { defaultGroup: { [key: string]: string } }
    return Object.keys(parsed.defaultGroup).reduce((acc, cur) => {
      const versionJSON = parsed.defaultGroup[cur].split(",").map((v) => v.trim())
      const version = versionJSON[0]
      if (version) {
        acc[cur] = versionJSON.slice(1, versionJSON.length).join(", ")
      }
      return acc
    }, {} as Dependencies)
  } catch (e) {
    return {}
  }
}
