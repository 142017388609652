import { get_encoding as getEncoding, encoding_for_model as encodingForModel, TiktokenModel, Tiktoken, TiktokenEncoding } from "tiktoken"
// import { getEncoding, encodingForModel, TiktokenModel, Tiktoken, TiktokenEncoding } from "js-tiktoken"

export type NewTiktokenModel =
  | "gpt-3.5-turbo-0613"
  | "gpt-3.5-turbo-16k-0613"
  | "gpt-3.5-turbo-16k"
  | "gpt-3.5-turbo-1106"
  | "gpt-4-0613"
  | "gpt-3.5-turbo-instruct"
  | "text-embedding-3-small"
  | "text-embedding-3-large"
  | "ft:gpt-3.5-turbo-0613:cosine-ai:hunk-desc-v0-0-0:804zXG7r"
  | "ft:gpt-3.5-turbo-0613:cosine-ai:task-pred-v0-0-0:7y56Cevo"
  | "ft:gpt-3.5-turbo-0613:cosine-ai:think-v0-0-5:80uKEueU"
  | "ft:gpt-3.5-turbo-0613:cosine:no-rag-v0-0-0:8AcUKa14"
  | "ft:gpt-3.5-turbo-0613:cosine:no-rag-v0-0-1:8AeRDVXn"
  | "ft:gpt-3.5-turbo-0613:cosine:no-rag-v0-0-2:8AfgLDxS"
  | "ft:gpt-3.5-turbo-1106:cosine:cosinebot-v0-0-0:8QOfkq8c"

export type CustomTiktokenModel = TiktokenModel | NewTiktokenModel

const encoders: Map<CustomTiktokenModel, Tiktoken> = new Map()

export type TokenizerModel = TiktokenModel

function getEncoder(modelName: CustomTiktokenModel) {
  if (!encoders.has(modelName)) {
    const encoding = getEncodingForModel(modelName)
    encoders.set(modelName, encoding)
  }
  return encoders.get(modelName)!
}

/**
 * Estimate the number of tokens in a function object
 *
 * @param input The functions object that we are estimating
 * @param modelName The name of the model that will be consuming the functions object
 * @returns The estimated number of tokens that it will take
 */
export function estimateTokens(input: object | string | number | undefined | null, modelName: CustomTiktokenModel): number {
  if (!input || input === null) {
    return 0
  }
  // Check if the current item is an object
  if (Array.isArray(input)) {
    return input.reduce((sum, item) => sum + estimateTokens(item, modelName), 0)
  } else if (typeof input === "object" && input !== null) {
    let sum = 0
    // For any key that has a string value, estimate the tokens
    // Otherwise recursively call self on the value
    for (const key in input) {
      if (typeof input[key] === "string") {
        sum += estimateTokens(input[key], modelName)
      } else {
        sum += estimateTokens(input[key], modelName)
      }
    }
    return sum
  } else if (typeof input === "string") {
    try {
      const encoder = getEncoder(modelName)
      // When we encode we have to allow all special tokens to be added to the input e.g. e-o-t
      const encoded = encoder.encode(input, "all").length
      return encoded
    } catch (e: any) {
      throw new Error(`Could not estimate tokens for input: ${modelName} ` + e.message)
    }
  } else {
    return 0
  }
}

/**
 * Estimate the number of tokens in a function object
 *
 * @param obj The functions object that we are estimating
 * @param modelName The name of the model that will be consuming the functions object
 * @returns The estimated number of tokens that it will take
 */
export function estimateFunctionTokens(obj: any, modelName: TiktokenModel): number {
  let sum = 0

  // Check if the current item is an object
  if (typeof obj === "object" && obj !== null) {
    // For any key that has a string value, estimate the tokens
    // Otherwise recursively call self on the value
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        sum += estimateTokens(obj[key], modelName)
      } else {
        sum += estimateFunctionTokens(obj[key], modelName)
      }
    }
  }

  return sum
}

export function getEncodingForModel(model: CustomTiktokenModel): Tiktoken {
  try {
    let tiktokenModel = model as TiktokenModel
    if (tiktokenModel.includes(":")) {
      tiktokenModel = tiktokenModel.split(":")[0] as TiktokenModel
    }
    return encodingForModel(tiktokenModel)
  } catch (error) {
    const newEncoding: TiktokenEncoding | undefined = newModelToEncoding[model] ?? "cl100k_base"
    if (newEncoding) {
      return getEncoding(newEncoding)
    }
    throw error
  }
}

export function encodeToTokens(string: string, model: CustomTiktokenModel): number[] {
  const encoder = getEncoder(model)
  return Array.from(encoder.encode(string, "all"))
}

export function decodeTokens(tokens: number[], model: CustomTiktokenModel): string {
  const encoder = getEncoder(model)
  return new TextDecoder("utf-8").decode(encoder.decode(new Uint32Array(tokens)))
}

const newModelToEncoding: Record<NewTiktokenModel, TiktokenEncoding> = {
  "gpt-3.5-turbo-0613": "cl100k_base",
  "gpt-3.5-turbo-16k": "cl100k_base",
  "gpt-3.5-turbo-16k-0613": "cl100k_base",
  "gpt-3.5-turbo-1106": "cl100k_base",
  "gpt-4-0613": "cl100k_base",
  "gpt-3.5-turbo-instruct": "cl100k_base",
  "ft:gpt-3.5-turbo-0613:cosine-ai:hunk-desc-v0-0-0:804zXG7r": "cl100k_base",
  "ft:gpt-3.5-turbo-0613:cosine-ai:task-pred-v0-0-0:7y56Cevo": "cl100k_base",
  "ft:gpt-3.5-turbo-0613:cosine-ai:think-v0-0-5:80uKEueU": "cl100k_base",
  "ft:gpt-3.5-turbo-0613:cosine:no-rag-v0-0-0:8AcUKa14": "cl100k_base",
  "ft:gpt-3.5-turbo-0613:cosine:no-rag-v0-0-1:8AeRDVXn": "cl100k_base",
  "ft:gpt-3.5-turbo-0613:cosine:no-rag-v0-0-2:8AfgLDxS": "cl100k_base",
  "ft:gpt-3.5-turbo-1106:cosine:cosinebot-v0-0-0:8QOfkq8c": "cl100k_base",
  "text-embedding-3-small": "cl100k_base",
  "text-embedding-3-large": "cl100k_base",
}
