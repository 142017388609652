/* eslint-disable no-console */
import { TelemetryHandler } from "./telemetry.handler"
import { Breadcrumb, Event, User } from "./types"

export class MockTelemetryHandler implements TelemetryHandler {

  public enabled: boolean = true

  public async setEnabled(enabled: boolean): Promise<void> {
    this.enabled = enabled
  }

  public async captureEvent(event: Event): Promise<any> {
    console.info("Captured event: ")
    console.error(event)
  }

  public async captureException(exception: any): Promise<any> {
    console.info("Captured exception: ")
    console.error(exception)
  }

  public async captureMessage(message: string): Promise<any> {
    console.info("Captured message: ")
    console.info(message)
  }

  public async addBreadcrumb(_breadcrumb: Breadcrumb): Promise<any> {
    //console.info("Added breadcrumb")
  }

  public async setUser(user: User | null): Promise<void> {
    console.info("Set user: ", user?.username)
  }

  public async setTag(key: string, value: any): Promise<void> {
    console.info("Set tag: ", key, value)
  }

  public async flush(): Promise<any> {
    console.info("Flushed")
  }

  public async close(): Promise<any> {
    console.info("Closed")
  }
}
