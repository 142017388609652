import { Classification } from "./classification.type"
import { CustomChatMessageType } from "@cosine/chat/types"

export type ReactionProps = {
  prompt: string
  classification: Classification
  message: string
  type: CustomChatMessageType
  previousMessageTypes: CustomChatMessageType[]
  timestamp: number
}

export class Reaction {
  public readonly prompt: string
  public readonly message: string
  public readonly type: CustomChatMessageType
  public readonly previousMessageTypes: CustomChatMessageType[]
  public readonly classification: Classification
  public readonly timestamp: number

  constructor(props: ReactionProps) {
    this.prompt = props.prompt
    this.classification = props.classification
    this.message = props.message
    this.type = props.type
    this.previousMessageTypes = props.previousMessageTypes
    this.timestamp = props.timestamp
  }
}
