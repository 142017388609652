"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExampleCard = exports.ExamplePrompts = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@headlessui/react");
var ExamplePrompts = function (props) {
    var visible = props.visible, examples = props.examples, onClick = props.onClick, onShown = props.onShown, onHidden = props.onHidden;
    return ((0, jsx_runtime_1.jsx)("div", { className: "grid grid-flow-col grid-rows-3 gap-1 sm:gap-2 md:gap-3 lg:grid-rows-2", children: examples.map(function (example, index) {
            var value = "".concat(index * 20, "ms");
            var isFirst = index === 0;
            var isLast = index === examples.length - 1;
            return ((0, jsx_runtime_1.jsx)(react_1.Transition, { appear: true, show: visible, className: "h-full w-full", style: visible ? { transitionDelay: value } : {}, enter: "transform transition ease-in-out duration-300", leave: "transform transition ease-in-out duration-200", enterFrom: "translate-y-4 opacity-0 scale-90", enterTo: "translate-y-0 opacity-100 scale-100", leaveFrom: "translate-y-0 opacity-100", leaveTo: "translate-y-4 opacity-0", beforeEnter: function () { return (isFirst ? onShown() : undefined); }, afterLeave: function () { return (isLast ? onHidden() : undefined); }, children: (0, jsx_runtime_1.jsx)(exports.ExampleCard, { text: example, onClick: function () { return onClick(example); } }, index) }, index));
        }) }));
};
exports.ExamplePrompts = ExamplePrompts;
var ExampleCard = function (props) {
    return ((0, jsx_runtime_1.jsx)("div", { onClick: props.onClick, className: "flex h-full w-56 cursor-pointer items-center justify-center rounded-md bg-surface-dim p-2 text-center text-xs text-on-surface-variant transition duration-75 ease-in-out hover:scale-105 hover:bg-surface hover:text-on-surface hover:shadow-md sm:p-3 md:rounded-lg md:p-3", children: props.text }));
};
exports.ExampleCard = ExampleCard;
