import { Dependencies } from "../types"

type Package = { version: string; path: string }

export function parseGoMod(fileContents: string): Dependencies {
  const lines = fileContents.split("\n")
  const module: { requires: Package[]; name: string } = {
    requires: [],
    name: "",
  }

  let expectingPackageName = false
  let packageGroup: Package[] | undefined = undefined
  let currentPackage: Package | undefined = undefined

  const parseParts = (parts: string[]): { path: string; version: string } | undefined => {
    if (parts.length >= 2) {
      const parsed = { path: parts[0], version: parts[1] }
      if (parsed.version.startsWith("v")) {
        return parsed
      }
    }
    return undefined
  }

  for (const line of lines) {
    const trimmedLine = line.trim()

    if (trimmedLine.startsWith("module")) {
      module.name = trimmedLine.split(/\s+/)[1]
    } else if (trimmedLine.startsWith("require")) {
      module.requires = []
      packageGroup = module.requires
      if (trimmedLine.endsWith("(")) {
        expectingPackageName = true
      } else {
        const replaced = trimmedLine.replace("require", "").trim()
        const parts = replaced.split(/\s+/)
        const parsed = parseParts(parts)
        if (parsed) {
          currentPackage = parsed
          if (packageGroup) {
            packageGroup.push(currentPackage)
          }
        }
      }
    } else if (expectingPackageName) {
      const parts = trimmedLine.split(/\s+/)
      const parsed = parseParts(parts)
      if (parsed) {
        currentPackage = parsed
        if (packageGroup) {
          packageGroup.push(currentPackage)
        }
      }
    }
  }

  return module.requires.reduce((acc, cur) => {
    acc[cur.path] = cur.version
    return acc
  }, {} as Dependencies)
}
