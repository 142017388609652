import { Interaction } from "./types/interaction"
import { InteractionType } from "./types/interaction.type"
import { BehaviourClassifier } from "./behaviour.classifier"

export type BehaviourServiceProps = {
  classifier: BehaviourClassifier
}

export class BehaviourService {
  private interaction?: Interaction
  private classifier: BehaviourClassifier

  constructor(props: BehaviourServiceProps) {
    const { classifier } = props
    this.classifier = classifier
  }

  public async classify(comparison?: Interaction): Promise<Interaction | null> {
    if (!this.interaction) {
      return null
    }
    if (!comparison) {
      const classification = await this.classifier.classify(this.interaction)
      this.interaction.setClassification(classification)
      return this.interaction
    }
    // Check the new interaction against the previous one
    const compared = await this.classifier.compareInteractions(this.interaction, comparison)
    if (!compared) {
      return null
    }
    const { isReworded, isRegenerated, isContinuation, isIncompleteContinuation } = compared
    if (isReworded) {
      this.interaction?.addEvent(InteractionType.REWORDED_QUERY)
    }
    if (isRegenerated) {
      this.interaction?.addEvent(InteractionType.REGENERATE_RESULTS)
    }
    if (isContinuation) {
      this.interaction?.addEvent(InteractionType.CONVERSATION_CONTINUED)
    }
    if (isIncompleteContinuation) {
      this.interaction?.addEvent(InteractionType.CONTINUE_INCOMPLETE_CONVERSATION)
    }
    if (!this.interaction) {
      return null
    }
    const classification = await this.classifier.classify(this.interaction)
    this.interaction?.setClassification(classification)
    return this.interaction
  }

  public start(interaction: Interaction): void {
    if (this.interaction) {
      throw new Error("Existing interaction in progress")
    }
    this.interaction = interaction
  }

  public onEvent(eventType: InteractionType, timestamp = Date.now()): void {
    if (!this.interaction) {
      return
    }
    this.interaction?.addEvent(eventType, timestamp)
  }

  public end(): void {
    if (!this.interaction) {
      return
    }
    this.interaction = undefined
  }

  public get hasInteraction(): boolean {
    return !!this.interaction
  }
}
