"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatHistoryEvent = void 0;
var MessageEvent_1 = require("@/common/contexts/MessageEvent");
exports.ChatHistoryEvent = {
    GetAllConversations: new MessageEvent_1.MessageEventType("GetAllConversations"),
    GetPaginatedConversations: new MessageEvent_1.MessageEventType("GetPaginatedConversations"),
    DeleteConversation: new MessageEvent_1.MessageEventType("DeleteConversation"),
    ShowAllConversations: new MessageEvent_1.MessageEventType("ShowAllConversations"),
};
