"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoFileMessage = exports.FileMessage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("@cosine/chat/types");
var code_1 = require("../code");
var FileMessage = function (props) {
    var message = props.message, corners = props.corners, onClick = props.onClick;
    var _a = message.data, fileContents = _a.fileContents, filePath = _a.filePath, fileType = _a.fileType;
    switch (fileType) {
        case types_1.FileChatMessageType.File:
            return (0, jsx_runtime_1.jsx)(code_1.CodeBlock, { text: fileContents, path: filePath, onClick: function () { return onClick === null || onClick === void 0 ? void 0 : onClick(filePath); }, corners: corners });
        case types_1.FileChatMessageType.NotFound:
            return (0, jsx_runtime_1.jsx)(exports.NoFileMessage, { message: message });
    }
};
exports.FileMessage = FileMessage;
var NoFileMessage = function (props) {
    var message = props.message;
    return ((0, jsx_runtime_1.jsx)("div", { className: "inline-flex flex-row space-x-2 rounded-lg bg-surface px-3 py-2 text-xs", children: (0, jsx_runtime_1.jsxs)("p", { className: "font-sem-bold text-on-surface", children: ["Could not find ", message.data.filePath] }) }));
};
exports.NoFileMessage = NoFileMessage;
