"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseTasks = void 0;
var status_1 = require("./status");
var getBaseTasks = function (props) {
    var understandTask = {
        preTitle: "Understanding Codebase",
        postTitle: "Understood Codebase",
        status: status_1.Status.Loading,
        subtasks: [],
    };
    var _a = props.understand, semantic = _a.semantic, graph = _a.graph, overview = _a.overview, tagging = _a.tagging;
    if (semantic) {
        understandTask.subtasks.push({
            id: "semantic",
            preTitle: "Performing Semantic Analysis",
            postTitle: "Completed Semantic Analysis",
            status: status_1.Status.Loading,
            progress: [
                {
                    subtitle: "Parsing code",
                    percent: 0,
                    status: status_1.Status.Loading,
                },
                {
                    subtitle: "Analyzing code",
                    percent: 0,
                    status: status_1.Status.Loading,
                },
                {
                    subtitle: "Saving results",
                    percent: 0,
                    status: status_1.Status.Loading,
                },
            ],
        });
    }
    if (graph) {
        understandTask.subtasks.push({
            id: "graph",
            preTitle: "Graphing Codebase",
            postTitle: "Generated Codebase Graph",
            status: status_1.Status.Loading,
            progress: [
                {
                    subtitle: "Generating & linking symbols",
                    percent: 0,
                    status: status_1.Status.Loading,
                },
                {
                    subtitle: "Tagging codebase",
                    percent: 0,
                    status: status_1.Status.Loading,
                },
            ],
        });
    }
    if (overview) {
        understandTask.subtasks.push({
            id: "overview",
            preTitle: "Generating Project Overview",
            postTitle: "Generated Project Overview",
            status: status_1.Status.Loading,
            progress: [
                {
                    subtitle: "Exploring codebase",
                    percent: 0,
                    status: status_1.Status.Loading,
                },
            ],
        });
    }
    if (tagging) {
        understandTask.subtasks.push({
            id: "tagging",
            preTitle: "Tagging codebase",
            postTitle: "Codebase Tagged",
            status: status_1.Status.Loading,
            progress: [
                {
                    subtitle: "Exploring codebase",
                    percent: 0,
                    status: status_1.Status.Loading,
                },
            ],
        });
    }
    return [understandTask];
};
exports.getBaseTasks = getBaseTasks;
