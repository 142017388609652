"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLanguages = exports.languages = void 0;
var types_1 = require("@cosine/code/types");
var language_item_1 = require("./language.item");
exports.languages = [
    {
        id: types_1.Language.TypeScript,
        name: "TypeScript",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/typescript.png",
        support: language_item_1.SupportStatus.Full,
    },
    {
        id: types_1.Language.TSX,
        name: "TSX (React)",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/tsx.png",
        support: language_item_1.SupportStatus.Full,
    },
    {
        id: types_1.Language.JavaScript,
        name: "JavaScript",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/javascript.png",
        support: language_item_1.SupportStatus.Full,
    },
    {
        id: types_1.Language.Python,
        name: "Python",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/python.png",
        support: language_item_1.SupportStatus.Full,
    },
    {
        id: types_1.Language.Golang,
        name: "Golang",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/golang.png",
        support: language_item_1.SupportStatus.Full,
    },
    {
        id: types_1.Language.Ruby,
        name: "Ruby",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/ruby.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.C_Sharp,
        name: "C#",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/c%23.png",
        support: language_item_1.SupportStatus.Full,
    },
    {
        id: types_1.Language.C,
        name: "C",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/c.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.CPP,
        name: "C++",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/c++.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.PHP,
        name: "PHP",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/php.png",
        support: language_item_1.SupportStatus.Full,
    },
    {
        id: types_1.Language.Kotlin,
        name: "Kotlin",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/kotlin.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Java,
        name: "Java",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/java.png",
        support: language_item_1.SupportStatus.Full,
    },
    {
        id: types_1.Language.Rust,
        name: "Rust",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/rust.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Swift,
        name: "Swift",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/swift.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Scala,
        name: "Scala",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/scala.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Cobol,
        name: "Cobol",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/cobol.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.CommonLisp,
        name: "Common Lisp",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/lisp.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.HTML,
        name: "HTML",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/html.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.CSS,
        name: "CSS",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/css.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Bash,
        name: "Bash",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/bash.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Haskell,
        name: "Haskell",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/haskell.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.SQL,
        name: "SQL",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/sql.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Dart,
        name: "Dart",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/dart.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Vue,
        name: "Vue",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/vue.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.YAML,
        name: "YAML",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/yaml.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.JSON,
        name: "JSON",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/json.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Lua,
        name: "Lua",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/lua.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Elm,
        name: "Elm",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/elm.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Toml,
        name: "Toml",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/toml.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Perl,
        name: "Perl",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/perl.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.R,
        name: "R",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/r.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.ObjectiveC,
        name: "ObjectiveC",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/objective-c.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Assembly,
        name: "Assembly",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/assembly.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Julia,
        name: "Julia",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/julia.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Elixir,
        name: "Elixir",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/elixir.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Erlang,
        name: "Erlang",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/erlang.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Groovy,
        name: "Groovy",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/groovy.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Markdown,
        name: "Markdown",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/markdown.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Nim,
        name: "Nim",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/nim.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Powershell,
        name: "Powershell",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/powershell.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Latex,
        name: "Latex",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/latex.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Verilog,
        name: "Verilog",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/verilog.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.VHDL,
        name: "VHDL",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/vhdl.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.XML,
        name: "XML",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/xml.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Clojure,
        name: "Clojure",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/clojure.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.FSharp,
        name: "FSharp",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/fsharp.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.GraphQL,
        name: "GraphQL",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/graphql.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Scheme,
        name: "Scheme",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/scheme.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Matlab,
        name: "Matlab",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/matlab.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Pug,
        name: "Pug",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/pug.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Pascal,
        name: "Pascal",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/pascal.png",
        support: language_item_1.SupportStatus.Partial,
    },
    {
        id: types_1.Language.Zig,
        name: "Zig",
        icon: "https://storage.googleapis.com/images.buildt.ai/languages/zig.png",
        support: language_item_1.SupportStatus.Partial,
    },
];
function getLanguages(objs) {
    var ids = new Set(objs.map(function (obj) { return obj.language; }));
    var counts = new Map(objs.map(function (obj) { return [obj.language, obj.count]; }));
    var compare = function (a, b) { var _a, _b; return ((_a = counts.get(b.id)) !== null && _a !== void 0 ? _a : 0) - ((_b = counts.get(a.id)) !== null && _b !== void 0 ? _b : 0); };
    return exports.languages.filter(function (lang) { return ids.has(lang.id); }).sort(compare);
}
exports.getLanguages = getLanguages;
