"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndexError = exports.ProgressMessageType = exports.MigrateMessage = exports.IndexMessage = void 0;
__exportStar(require("./progress.message"), exports);
__exportStar(require("./initialize.message"), exports);
__exportStar(require("./project.message"), exports);
__exportStar(require("./properties.message"), exports);
var IndexMessage;
(function (IndexMessage) {
    IndexMessage["Project"] = "Project";
    IndexMessage["Ignore"] = "Ignore";
    IndexMessage["GetIgnored"] = "GetIgnored";
    IndexMessage["Initialize"] = "Initialize";
    IndexMessage["RetryInitialize"] = "RetryInitialize";
    IndexMessage["Progress"] = "Progress";
    IndexMessage["Reset"] = "Reset";
    IndexMessage["EngineFeatures"] = "EngineFeatures";
    IndexMessage["Cancel"] = "CancelIndexing";
    IndexMessage["Cancelled"] = "IndexingCancelled";
})(IndexMessage || (exports.IndexMessage = IndexMessage = {}));
var MigrateMessage;
(function (MigrateMessage) {
    MigrateMessage["Migrate"] = "Migrate";
    MigrateMessage["Progress"] = "MigrationProgress";
    MigrateMessage["Complete"] = "MigrationComplete";
    MigrateMessage["Failed"] = "MigrationFailed";
})(MigrateMessage || (exports.MigrateMessage = MigrateMessage = {}));
var ProgressMessageType;
(function (ProgressMessageType) {
    ProgressMessageType["Semantic"] = "Semantic";
    ProgressMessageType["Overview"] = "Overview";
    ProgressMessageType["Graph"] = "Graph";
    ProgressMessageType["Tag"] = "Tag";
})(ProgressMessageType || (exports.ProgressMessageType = ProgressMessageType = {}));
var IndexError;
(function (IndexError) {
    IndexError["IgnoreFailed"] = "IgnoreFailed";
    IndexError["LanguageFailed"] = "LanguageFailed";
    IndexError["InitializeFailed"] = "InitializeFailed";
})(IndexError || (exports.IndexError = IndexError = {}));
