import { createHash } from "crypto"

export function enumToString(list: any[]): string {
  return list.map((el) => `'${el}'`).join(" | ")
}

export function capitalise(str: string): string {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function regexInString(str: string, regex: RegExp): boolean {
  return regex.test(str)
}

export function camelCaseToSnakeCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
}

export function snakeToCamelCase(str: string): string {
  return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export function isPascalCase(str: string): boolean {
  const regex = /^[A-Z][A-Za-z]*$/
  return regex.test(str)
}

export function pascalCase(string: string) {
  if (isPascalCase(string)) {
    return string
  }
  return `${string}`
    .toLowerCase()
    .replace(/[-_]+/g, " ")
    .replace(/[^\w\s]/g, "")
    .replace(/\s+(.)(\w*)/g, ($1, $2, $3) => `${$2.toUpperCase() + $3}`)
    .replace(/\w/, (s) => s.toUpperCase())
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export function hashString(str: string): string {
  return createHash("sha256").update(str).digest("hex")
}

export function escapeString(str: string): string {
  return str.replace(/'/g, `''`)
}

export function similarity(s1: string, s2: string): number {
  let longer = s1
  let shorter = s2
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  const longerLength = longer.length
  if (longerLength === 0) {
    return 1.0
  }
  return (longerLength - editDistance(longer, shorter)) / longerLength
}

export function editDistance(s1: string, s2: string) {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs: number[] = []
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) {
        costs[j] = j
      } else {
        if (j > 0) {
          let newValue = costs[j - 1]
          if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          }
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue
    }
  }
  return costs[s2.length]
}

export function formatJSONAsObjectLiteral(obj: any): string {
  let str = JSON.stringify(obj, null, 2)
  const arr = str.match(/".*?":/g)
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      str = str.replace(arr[i], arr[i].replace(/"/g, ""))
    }

    return str.replace(/"{{/g, "").replace(/}}"/g, "")
  }
  return str.replace(/"{{/g, "").replace(/}}"/g, "")
}

export function oxfordList(items: string[]): string {
  if (items.length === 1) {
    return items[0]
  } else if (items.length === 2) {
    return items.join(" and ")
  } else {
    return items.slice(0, -1).join(", ") + ", and " + items[items.length - 1]
  }
}

export function getLineNumber(str: string, index: number): number {
  const portion = str.substring(0, index)
  const lines = portion.split("\n")
  return lines.length - 1
}

export function extractSubstrings(originalString: string, substrings: string[]): string[] {
  const result: string[] = []
  let currentIndex = 0

  substrings.forEach((substring) => {
    const substringIndex = originalString.indexOf(substring, currentIndex)
    if (substringIndex > currentIndex) {
      result.push(originalString.substring(currentIndex, substringIndex))
    }
    result.push(substring)
    currentIndex = substringIndex + substring.length
  })

  if (currentIndex < originalString.length) {
    result.push(originalString.substring(currentIndex))
  }

  return result
}

export function prependChar(text: string, char: string, count: number): string {
  return char.repeat(count) + text
}

export function extractSurroundingKeywordLines(keywords: string[], text: string, n: number): string {
  const lines = text.split("\n")
  const resultLines: string[] = []

  const escapeRegExp = (s: string) => {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
  }

  for (let i = 0; i < lines.length; i++) {
    if (keywords.some((keyword) => new RegExp(escapeRegExp(keyword), "i").test(lines[i]))) {
      const start = Math.max(0, i - n)
      const end = Math.min(lines.length, i + n + 1)
      const slice = lines.slice(start, end)
      const lastLineIndentation = (slice[slice.length - 1].match(/^\s*/) || [""])[0]
      resultLines.push(...slice, lastLineIndentation + "...\n")
    }
  }
  return resultLines.join("\n")
}

export function wrapText(text: string, maxLineLength: number = 80): string {
  const words = text.split(" ")
  const lines: string[] = []
  let currentLine = ""

  for (const word of words) {
    if ((currentLine + word).length <= maxLineLength) {
      currentLine += " " + word
    } else {
      lines.push(currentLine)
      currentLine = word
    }
  }

  lines.push(currentLine)

  return lines.join("\n").trim()
}

export function hashStringToInteger(str: string): number {
  let hash = 5381
  let i = str.length

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i)
  }

  return hash >>> 0
}

export function extractNumberFromString(input: string): number | undefined {
  const regex = /\d+/g
  const matches = input.match(regex)
  if (!matches || matches.length === 0) return undefined
  const result = parseInt(matches[0])
  return isNaN(result) ? undefined : result
}
