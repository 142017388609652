import { AI, CreateChatProps, OpenAIChatModel } from "@cosine/ai"
import { Interaction } from "./types/interaction"
import { InteractionComparison } from "./types/interaction.comparison"
import { CustomTiktokenModel, estimateTokens } from "@cosine/common"
import { PromptChatMessage, SystemPromptMessage } from "@cosine/chat"

export class InteractionComparitor {
  constructor(private ai: AI) {}

  public async compareInteractions(interactionA: Interaction, interactionB: Interaction): Promise<InteractionComparison | undefined> {
    if (interactionA.prompt === interactionB.prompt) {
      return { isReworded: false, isRegenerated: true, isContinuation: false, isIncompleteContinuation: false }
    }

    const model = OpenAIChatModel.CHAT_GPT_4_0613
    const tokenModel = model.id as CustomTiktokenModel
    const functions = [
      {
        name: "classify_response",
        description: `Analyzes a sequence of user queries, it discerns the underlying intentions or relationships among them. Identifies various patterns such as specific rewording, continuation of a topic, or an incomplete query that needs further input.\n\nExample Good Outputs:\n{"observations":"The user seems to be trying to find the authentication middleware in both queries, however the second query is worded more specifically","classification":"rewording"}\n{"observations":"It seems that the original query the user posed wasn\'t completed correctly as they\'ve asked for the last part of their previous query to be executed in isolation, implying that it didn\'t happen the first time around","classification":"incomplete_continuation"}\n{"observations":"The user has asked a followup question related to the previous question, presumably looking to find out more","classification":"continuation"}`,
        parameters: {
          type: "object",
          properties: {
            message_1_intention: {
              type: "string",
              description:
                "What was the user's intention in Message 1? Must **always** be in the form of 'Given the user's intention in the first message was <intention of the first message>",
            },
            message_2_intention: {
              type: "string",
              description:
                "What was the user's intention in Message 2? Must **always** be in the form of 'And the user's intention in the second message was <intention of the second message>",
            },
            logical_deduction: {
              type: "string",
              description:
                "What can we deduce from the two messages in terms of the quality of the output following Message 1? Must **always** be in the form of 'For the user to ask <Message 2> then the result of <Message 1> was likely (explanation of what the result of Message 1 likely was). Example good output: 'For the user to ask `Suggest a better way of doing this` this must mean that the result of the first message was good, otherwise they wouldn't be able to ask the second message'",
            },
            classification: {
              type: "string",
              description:
                "This mandatory field assigns a category to the user's queries based on the observations made. The category reflects the nature of the user’s interaction and helps in understanding the user's intention. It is a label that summarizes the type of communication or issue detected in the queries. Here's a breakdown of the options:\n-The 'rewording' option applies when the user's second message should yeild the same result as the first, but using differnt wording.\n- The 'continuation' option applies when it is clear that the second message was prompted by a good/useful result from the first message.\n- The 'incomplete_continuation' option applies when the second message was prompted by an unclear/inconclusive result from the first message",
              enum: ["rewording", "continuation", "incomplete_continuation"],
            },
          },
          required: ["message_1_intention", "message_2_intention", "logical_deduction", "classification"],
        },
      },
    ]
    const promptATokens = estimateTokens(interactionA.prompt, tokenModel)
    const promptBTokens = estimateTokens(interactionB.prompt, tokenModel)
    const functionsTokena = estimateTokens(functions, tokenModel)
    let promptA = interactionA.prompt
    let promptB = interactionB.prompt

    // If combination of prompts is too big then brutally cut them down to size
    if (promptATokens + promptBTokens > model.maxTokens - functionsTokena) {
      const percentLarger = (promptATokens + promptBTokens) / (model.maxTokens - functionsTokena - (5 * model.maxTokens) / 100)
      const multiplyer = 1 / percentLarger

      promptA = interactionA.prompt.slice(0, Math.floor(interactionA.prompt.length * multiplyer))
      promptB = interactionB.prompt.slice(0, Math.floor(interactionB.prompt.length * multiplyer))
    }
    const props: CreateChatProps = {
      model: model,
      messages: [
        new SystemPromptMessage(
          "You are an NLP expert, trying to classify two subsequent messages from the same person in a conversation. You won't see the other side of the conversation, just the user's messages.",
        ),
        new PromptChatMessage(`Message 1: ${promptA}\nMessage 2: ${promptB}`),
      ],
      functions: functions as any,
      function_call: { name: "classify_response" },
      functionValidator: (functionCall) => {
        const classification = functionCall?.arguments?.classification
        if (!classification) {
          return false
        }
        if (classification !== "rewording" && classification !== "continuation" && classification !== "incomplete_continuation") {
          return false
        }
        return true
      },
      availableAttempts: 3,
    }
    const result = await this.ai.createChat(props)
    if (!result?.function_call?.arguments?.classification) {
      return undefined
    }
    const classification = result.function_call.arguments.classification
    const returnValue = { isReworded: false, isRegenerated: false, isContinuation: false, isIncompleteContinuation: false }
    if (classification === "rewording") {
      returnValue.isReworded = true
    }
    if (classification === "continuation") {
      returnValue.isContinuation = true
    }
    if (classification === "incomplete_continuation") {
      returnValue.isIncompleteContinuation = true
    }
    return returnValue
  }
}
