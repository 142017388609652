"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_feather_1 = require("react-feather");
var ErrorMessage = function (props) {
    var message = props.message;
    var title = message.data;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "inline-flex flex-row items-center space-x-2 rounded-lg bg-surface px-2 py-1.5 text-xs sm:px-3 sm:py-2", children: [(0, jsx_runtime_1.jsx)(react_feather_1.AlertTriangle, { className: "h-4 w-4 flex-none text-red-400" }), (0, jsx_runtime_1.jsx)("div", { className: "flex flex-col space-y-0.5 md:flex-row md:space-x-2 md:space-y-0", children: (0, jsx_runtime_1.jsx)("p", { className: "text-red-400", children: title }) })] }));
};
exports.ErrorMessage = ErrorMessage;
