export function isUndefined(result: any | undefined): boolean {
  if (result === "") {
    return false
  }
  return result === undefined || result === null
}

export async function delayed<T>(value: T, ms: number): Promise<T> {
  return await new Promise((resolve) => setTimeout(() => resolve(value), ms))
}

export function missing(obj: any): string {
  const keys = Object.keys(obj)
  const missing = keys.filter((key) => isUndefined(obj[key]))
  return missing.join(", ")
}

export function isApproximatelyValue(received: number, expected: number, epsilon: number = 1e-9): boolean {
  return Math.abs(received - expected) <= epsilon
}
