import { Network } from "@cosine/network"
import { ChatResult, CompletionResult, CustomEmbeddingResult, PartialChatResult, ChatResultStream, CompletionStreamListener, PartialCompletionResult } from "../../types"
import { AI, CreateChatProps, CreateCompletionProps, CreateEmbeddingProps } from "../ai"
import { EmbeddingResult } from "../../types"
import { AIModel } from "../../models"
import { CancellationToken, StreamCancelledError } from "@cosine/cancellation"
import { chunkEmbeddings } from "../../utils/preprocess.utils"
import { Readable } from "stream"
import { RequestInit } from "node-fetch"

export class RemoteAI implements AI {
  private network: Network

  constructor(network: Network) {
    this.network = network
  }

  public async createChat(props: CreateChatProps, cancelToken?: CancellationToken, stream?: ChatResultStream): Promise<ChatResult | undefined> {
    props = this.cleanMessages(props)
    const request: RequestInit = { method: "POST", body: JSON.stringify(props), headers: { "Content-Type": "application/json" } }
    if (props.stream && stream) {
      const response: Readable = await this.network.fetch("/chat", request, cancelToken, props.stream)
      const result = await this.handleStreamResponse<PartialChatResult, ChatResult>(response, combineChatResult, stream)
      return result
    }
    const temp = await this.network.fetch("/chat", request, cancelToken)
    return temp as any
  }

  public async createCompletion(props: CreateCompletionProps, cancelToken?: CancellationToken, streamCallback?: CompletionStreamListener): Promise<CompletionResult | undefined> {
    const request: RequestInit = { method: "POST", body: JSON.stringify(props), headers: { "Content-Type": "application/json" } }
    if (props.stream && streamCallback) {
      const response: Readable = await this.network.fetch("/completions", request, cancelToken, props.stream)
      const result = await this.handleStreamResponse<PartialCompletionResult, CompletionResult>(response, combineCompletionResult, streamCallback)
      return result
    }
    return await this.network.fetch("/completions", request, cancelToken)
  }

  public async createEmbedding(props: CreateEmbeddingProps, cancelToken?: CancellationToken): Promise<EmbeddingResult | undefined> {
    const request: RequestInit = { method: "POST", body: JSON.stringify(props), headers: { "Content-Type": "application/json" } }
    return await this.network.fetch("/embeddings", request, cancelToken)
  }

  public async preprocessEmbeddings(
    inputs: string[],
    model: AIModel,
    targetRequestByteSize: number,
    cancelToken?: CancellationToken,
  ): Promise<{ processedInput: string; originalIndex: number }[][]> {
    return chunkEmbeddings(inputs, model, targetRequestByteSize, cancelToken)
  }

  public async createEmbeddingCustom(props: CreateEmbeddingProps, cancelToken?: CancellationToken): Promise<CustomEmbeddingResult[] | undefined> {
    const request: RequestInit = { method: "POST", body: JSON.stringify(props), headers: { "Content-Type": "application/json" } }
    return await this.network.fetch("/embedding/custom", request, cancelToken)
  }

  private cleanMessages(props: CreateChatProps): CreateChatProps {
    const messages = props.messages.map(({ role, content, name }) => ({ role, content, name }))
    return { ...props, messages }
  }

  private async handleStreamResponse<P, R>(response: Readable, combine: (current: P | undefined, partial: P) => P, stream: (partialMessage: P[]) => void): Promise<R> {
    return new Promise((resolve, reject) => {
      let result: P | undefined = undefined
      let buffer = ""
      response.on("data", (chunk: Buffer) => {
        const lines = chunk
          .toString("utf-8")
          .split("\n")
          .filter((m) => m.length > 0)
        for (const line of lines) {
          buffer += line
          buffer = buffer.replace(/^data: /, "")
          const json = parseJSON(buffer)
          if (!json) {
            continue
          }
          buffer = ""
          result = combine(result, json)
          stream([result!])
        }
      })
      response.on("end", () => resolve(result as unknown as R))
      response.on("error", (error: Error) => reject(error))
    }).catch((err) => {
      if (err.name === "AbortError") {
        throw new StreamCancelledError(null)
      }
      throw err
    }) as R
  }
}

function combineChatResult(current: PartialChatResult | undefined, partial: PartialChatResult): PartialChatResult {
  if (!current && partial) {
    return partial
  }
  return {
    ...partial,
    message: {
      ...partial.message,
      content: (current?.message?.content ?? "") + (partial.message?.content ?? ""),
    },
  }
}

function combineCompletionResult(current: PartialCompletionResult | undefined, partial: PartialCompletionResult): PartialCompletionResult {
  if (!current && partial) {
    return partial
  }
  return {
    ...partial,
    text: (current?.text ?? "") + (partial.text ?? ""),
    finish_reason: partial.finish_reason,
  }
}

const parseJSON = (text: string) => {
  try {
    return JSON.parse(text)
  } catch (error) {
    return null
  }
}
