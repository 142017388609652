"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchMessageType = void 0;
var SearchMessageType;
(function (SearchMessageType) {
    SearchMessageType["Code"] = "Code";
    SearchMessageType["Slack"] = "Slack";
    SearchMessageType["Notion"] = "Notion";
    SearchMessageType["Confluence"] = "Confluence";
    SearchMessageType["Linear"] = "Linear";
    SearchMessageType["None"] = "None";
})(SearchMessageType || (exports.SearchMessageType = SearchMessageType = {}));
