import * as yaml from "js-yaml"
import { IdeateStep } from ".."

/**
 * Function to extract and parse YAML from a given string.
 */
export function extractAndParseYAML(str: string): any {
  if (!str) {
    return undefined
  }
  let rawYaml = str

  // 1. Sometime the output has the yaml within a code black. So remove first/last line
  if (rawYaml.indexOf("```yaml") !== -1) {
    const lines = rawYaml.split("\n")
    rawYaml = lines.slice(lines.indexOf("```yaml") + 1, lines.length - 1).join("\n")
  }

  // 2. Sometimes the output has "---", followed by the yaml. This addresses that case
  if (rawYaml.indexOf("---") !== -1) {
    const lines = str.split("\n")
    rawYaml = lines.slice(lines.indexOf("---")).join("\n")
  }

  // 3. Sometime there is a trailing non-indented "```" at the end of the yaml. This addresses that case
  //    The leading \n is very important here, because we are only checking for non-indented
  if (rawYaml.endsWith("\n```") || rawYaml.endsWith("\n```\n")) {
    rawYaml = rawYaml.slice(0, rawYaml.length - 4)
  }

  // 4. Sometimes the rock gives paths with '*'. That breaks the YAML parsing, so this should fix that
  const fixedYaml = rawYaml.replace(/\*(\w+)/g, "'*$1'")

  try {
    return yaml.load(fixedYaml, { schema: yaml.JSON_SCHEMA })
  } catch (e) {
    // If the YAML tell us the line number of the error, then slice it a that number and try again
    if ((e as any).mark.line) {
      const stitch =
        fixedYaml
          .split("\n")
          .slice((e as any).mark.line)
          .join("\n") + "\n"
      try {
        return yaml.load(stitch, { schema: yaml.JSON_SCHEMA })
      } catch (e) {
        return undefined
      }
    }

    return undefined
  }
}

export function isSingleIdeateStep(data: any): data is IdeateStep {
  if (!data || typeof data !== "object") {
    return false
  }
  // const keys = Object.keys(data)
  return data.index && data.relative_file_path && data.action && data.title && data.description
}

export function parseIdeationYaml(data: string): IdeateStep[] | undefined {
  const parsedYaml: any = extractAndParseYAML(data)
  if (!parsedYaml) {
    return
  }
  if (!Array.isArray(parsedYaml)) {
    if (isSingleIdeateStep(parsedYaml)) {
      return [yamlToIdeateStep(parsedYaml)]
    }
    return undefined
  }
  const steps: IdeateStep[] = parsedYaml.map((yam) => yamlToIdeateStep(yam))

  return steps
}

function yamlToIdeateStep(yam: any): IdeateStep {
  return {
    index: yam?.index,
    path: yam?.relative_file_path,
    action: yam?.action,
    title: yam?.title,
    description: yam?.description,
  }
}
