type RateLimitEvent = {
  isLimited: boolean
  timestamp: number
}

export class RateLimitHandler {
  static readonly RATELIMIT_DEBOUNCE_WINDOW = 15_000
  static readonly NON_RATELIMIT_DEBOUNCE_WINDOW = 10_000
  static readonly CHECK_IN_TIMEOUT = 60_000

  private lastEvent?: RateLimitEvent
  private checkInId: NodeJS.Timeout | null = null

  constructor(private emitRateLimit: (isLimited: boolean) => void) {}

  /**
   * Check with the current state of the rate limit.
   * If we were previously rate limited and that was more than CHECK_IN_TIMEOUT ago, then assume we are no longer rate limited
   */
  private scheduleCheckIn(): void {
    if (this.checkInId !== null) {
      clearTimeout(this.checkInId)
    }
    this.checkInId = setTimeout(() => {
      // If conditions are met, then clear the rate limit state, and emit that we are no longer rate limited
      if (this.lastEvent?.isLimited && Date.now() - this.lastEvent.timestamp > RateLimitHandler.RATELIMIT_DEBOUNCE_WINDOW) {
        this.lastEvent = undefined
        this.emitRateLimit(false)
      }
    }, RateLimitHandler.CHECK_IN_TIMEOUT)
  }

  public async handleRateLimit(isLimited: boolean): Promise<void> {
    const now = Date.now()

    const emitEvent: () => void = () => {
      this.lastEvent = { isLimited, timestamp: now }
      this.emitRateLimit(isLimited)

      // Only schedule a check in if we are rate limited
      if (isLimited) {
        this.scheduleCheckIn()
      }
    }

    // Emit event if:
    //   - there was no previous event
    //   - we are now rate limited
    //
    // By default we are non-ratelimited. So there is no point starting a window for that
    // In essence we only want to quick of this whole process the first time we are rate limited
    if (!this.lastEvent && isLimited) {
      return emitEvent()
    } else if (!this.lastEvent) {
      return
    }

    // Emit event if:
    //   - we were already rate limited (old)
    //   - we are still rate limited   (new)
    //
    // Effectively resetting the window
    if (this.lastEvent.isLimited && isLimited) {
      return emitEvent()
    }

    // Emit event if:
    //   - we were rate limited (old)
    //   - the ratelimit window has expired
    //
    // We want to update to the latest value
    if (now - this.lastEvent.timestamp > RateLimitHandler.RATELIMIT_DEBOUNCE_WINDOW && this.lastEvent) {
      return emitEvent()
    }

    // Emit event if:
    //   - we were not rate limited (old)
    //   - we are now rate limited  (new)
    //   - the non-ratelimit window has expired
    //
    // We want to emit that we are now rate limited
    if (now - this.lastEvent.timestamp > RateLimitHandler.NON_RATELIMIT_DEBOUNCE_WINDOW && !this.lastEvent.isLimited && isLimited) {
      return emitEvent()
    }

    // In any other case we can just drop the event e.g.
    //   - we do not want to refresh the window on sequential non-ratelimited requests
  }
}
