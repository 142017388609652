"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanSettings = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var setting_1 = require("./setting");
var setting_group_1 = require("./setting.group");
var common_1 = require("../../../common");
var payments_1 = require("../../payments");
var plan_settings_message_1 = require("../messages/plan.settings.message");
var plans_1 = require("./plans");
var PlanSettings = function () {
    var _a, _b, _c, _d;
    var sendMessage = (0, react_1.useContext)(common_1.MessageContext).sendMessage;
    var _e = (0, react_1.useState)(), currentPlan = _e[0], setPlan = _e[1];
    var _f = (0, react_1.useState)(), plansRequired = _f[0], setPlansRequired = _f[1];
    (0, react_1.useEffect)(function () { return sendMessage(plan_settings_message_1.PlanMessage.CurrentPlan, false); }, []);
    (0, common_1.useMessage)(plan_settings_message_1.PlanMessage.CurrentPlan, function (plan) { return setPlan(plan); });
    (0, common_1.useMessage)(plan_settings_message_1.PlanMessage.PlanStarted, function (plan) { return setPlan(plan); });
    (0, common_1.useMessage)(plan_settings_message_1.PlanMessage.PlansRequired, function (required) { return setPlansRequired(required); });
    if (!currentPlan || !plansRequired) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    return ((0, jsx_runtime_1.jsx)(setting_group_1.SettingGroup, { title: "Payment Plan", children: (0, jsx_runtime_1.jsx)(setting_1.Setting, { children: (0, jsx_runtime_1.jsxs)("div", { className: "w-full py-2", children: [currentPlan.trial && (0, jsx_runtime_1.jsx)(payments_1.FreePlan, { daysRemaining: (_b = (_a = currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.trial) === null || _a === void 0 ? void 0 : _a.daysRemaining) !== null && _b !== void 0 ? _b : 0, trialLength: (_d = (_c = currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.trial) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 1 }), (0, jsx_runtime_1.jsx)(plans_1.Plans, { userPlan: currentPlan })] }) }) }));
};
exports.PlanSettings = PlanSettings;
