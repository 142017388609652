import { ChatRole } from "@cosine/ai/types"
import { ChatMessage } from "./chat.message"
import { randomUUID } from "crypto"

export abstract class CustomChatMessage<T = any> implements ChatMessage<T> {
  public readonly id: string = randomUUID()
  public abstract type: CustomChatMessageType
  public abstract role: ChatRole
  public abstract data?: T
  public abstract readonly content: string
}

export enum CustomChatMessageType {
  SystemPrompt = "SystemPrompt",
  Question = "Question",
  ExplainCode = "ExplainCode",
  Text = "Text",
  Search = "Search",
  Process = "Process",
  File = "File",
  Filesystem = "Filesystem",
  Understand = "Understand",
  Ideate = "Ideate",
  Cancelled = "Cancelled",
  Error = "Error",
  Context = "Context",
}
