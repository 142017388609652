"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessMessage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("../../../../common");
var ProcessMessage = function (props) {
    var _a = props.message.data, title = _a.title, description = _a.description;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "inline-flex flex-row space-x-2 rounded-lg bg-surface px-2 py-1.5 text-xs sm:px-3 sm:py-2", children: [(0, jsx_runtime_1.jsx)(common_1.Spinner, { className: "none h-4 w-4 flex-none" }), (0, jsx_runtime_1.jsxs)("div", { className: "flex flex-col space-y-0.5", children: [(0, jsx_runtime_1.jsx)("p", { className: "flex-none font-bold text-gray-300", children: title }), description && (0, jsx_runtime_1.jsx)("p", { className: "truncate text-gray-400", children: description })] })] }));
};
exports.ProcessMessage = ProcessMessage;
