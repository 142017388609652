"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Settings = void 0;
var Settings;
(function (Settings) {
    Settings["Theme"] = "Theme";
    Settings["Experimental"] = "Experimental";
    Settings["CodeSelect"] = "CodeSelect";
    Settings["Highlight"] = "Highlight";
    Settings["AIProvider"] = "AIProvider";
    Settings["AIModel"] = "AIModel";
    Settings["Environment"] = "Environment";
    Settings["Region"] = "Region";
    Settings["Developer"] = "Developer";
    Settings["ShowTokens"] = "ShowTokens";
    Settings["ShowExamples"] = "ShowExamples";
})(Settings || (exports.Settings = Settings = {}));
