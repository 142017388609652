import toml from "toml"
import { Dependencies } from "../types"

export function parsePipfile(fileContents: string): Dependencies {
  let pipFileContents: any
  try {
    pipFileContents = toml.parse(fileContents)
  } catch (err) {
    return {}
  }
  const dependencies: Dependencies = {}

  // The dependencies are located under the [packages] section in the Pipfile
  if (pipFileContents["packages"]) {
    for (const [packageName, version] of Object.entries(pipFileContents["packages"])) {
      if (version && typeof version === "string") {
        dependencies[packageName] = String(version)
      } else if (typeof version === "object" && version?.["version"]) {
        dependencies[packageName] = String(version["version"])
      }
    }
  }

  // You can also include dev-packages if needed
  // They are located under the [dev-packages] section
  if (pipFileContents["dev-packages"]) {
    for (const [packageName, version] of Object.entries(pipFileContents["dev-packages"])) {
      if (version && typeof version === "string") {
        dependencies[packageName] = String(version)
      } else if (typeof version === "object" && version?.["version"]) {
        dependencies[packageName] = String(version["version"])
      }
    }
  }

  return dependencies
}
