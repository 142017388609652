import { AIModel } from "../models"
import { ChatFunctionCall, Message, ChatResultStream } from "../types"
import { ChatResult } from "../types"
import { CompletionResult, CompletionStreamListener } from "../types/completion/completion.result"
import { EmbeddingResult } from "../types"
import { CancellationToken } from "@cosine/cancellation"

export type ContentValidator = (message: string) => boolean
export type FunctionValidator = (call?: ChatFunctionCall) => boolean
export type CreateCompletionProps = {
  model: string
  prompt: string
  stream?: boolean
  temperature?: number
  max_tokens?: number
  top_p?: number
  presence_penalty?: number
  frequency_penalty?: number
  stop?: string[]
  includeStop?: boolean
  validator?: ContentValidator
  availableAttempts?: number
  logit_bias?: { [key: string]: number }
  completion_prefix?: string
  available_network_attempts?: number
  seed?: number
  deterministic?: boolean
}

export interface JsonSchema<T = Record<string, any>> {
  $schema?: string
  $id?: string
  title?: string
  description?: string
  type?: "string" | "number" | "object" | "array" | "boolean" | "null"
  items?: JsonSchema | JsonSchema[]
  enum?: string[]
  properties?: { [P in keyof T]: JsonSchema }
  additionalProperties?: boolean | JsonSchema
  required?: (keyof T)[]
}

type JsonValue = string | number | boolean | null | JsonObject | JsonArray

interface JsonObject {
  [key: string]: JsonValue
}

interface JsonArray extends Array<JsonValue> {}

export type ChatFunction<T = Record<string, any>> = {
  name: string
  description: string
  parameters: JsonSchema<T>
}

export type FunctionCall = "none" | "auto" | Record<"name", string>

export type ChatTool<T = Record<string, any>> = {
  type: "function"
  function: ChatFunction<T>
}
export namespace ChatTool {
  export const create = (tool: ChatFunction): ChatTool => ({ type: "function", function: tool })
}

export type ToolChoice = "none" | "auto" | { type: "function"; function: { name: string } }
export namespace ToolChoice {
  export const create = (choice: string): ToolChoice => ({ type: "function", function: { name: choice } })
}

export type CreateChatProps = {
  model: AIModel
  messages: Message[]
  stream?: boolean
  temperature?: number
  max_tokens?: number
  top_p?: number
  presence_penalty?: number
  frequency_penalty?: number
  stop?: string[]
  includeStop?: boolean
  validator?: ContentValidator
  functionValidator?: FunctionValidator
  availableAttempts?: number
  logit_bias?: { [key: string]: number }
  responderName?: string
  completion_prefix?: string
  available_network_attempts?: number
  /**
   * @deprecated tools should be used instead
   */
  functions?: ChatFunction[]
  /**
   * @deprecated tool_choice should be used instead
   */
  function_call?: FunctionCall
  tools?: ChatTool[]
  tool_choice?: ToolChoice
  shrink?: boolean
  response_format?: { type: "json_object" | "text" }
  seed?: number
  deterministic?: boolean
  label?: string
}
export type CreateEmbeddingProps = {
  model: string
  input: string[] | { text: string; reference: number }[]
  available_network_attempts?: number
}

export interface AI {
  createChat: (props: CreateChatProps, cancelToken?: CancellationToken, stream?: ChatResultStream) => Promise<ChatResult | undefined>
  createCompletion: (props: CreateCompletionProps, cancelToken?: CancellationToken, streamCallback?: CompletionStreamListener) => Promise<CompletionResult | undefined>
  createEmbedding: (props: CreateEmbeddingProps, cancelToken?: CancellationToken) => Promise<EmbeddingResult | undefined>
  preprocessEmbeddings(inputs: string[], model: AIModel, targetRequestByteSize: number, token?: CancellationToken): Promise<{ processedInput: string; originalIndex: number }[][]>
}
