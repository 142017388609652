"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioSetting = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var setting_1 = require("./setting");
var components_1 = require("../../../common/components");
function RadioSetting(props) {
    var title = props.title, description = props.description, icon = props.icon, options = props.options, selected = props.selected, setSelected = props.setSelected;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex py-2 first:pt-2 last:pb-2 sm:py-4 first:sm:pt-3 last:sm:pb-2", children: [icon ? (0, jsx_runtime_1.jsx)("div", { className: "pr-4 pt-2", children: icon }) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), (0, jsx_runtime_1.jsx)(setting_1.Setting, { title: title, description: description, className: "flex-col space-y-3 lg:flex-row lg:space-x-5 lg:space-y-0 xl:space-x-6", children: (0, jsx_runtime_1.jsx)("div", { className: "flex-1", children: (0, jsx_runtime_1.jsx)(components_1.Radio, { options: options, selected: selected, setSelected: setSelected }) }) })] }));
}
exports.RadioSetting = RadioSetting;
