"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitializeCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("../../../../common");
var languages_1 = require("../../types/languages");
var react_1 = require("@headlessui/react");
var listbox_1 = require("../../../../common/components/listbox");
var react_2 = require("react");
var language_item_1 = require("../../types/language.item");
var InitializeCard = function (props) {
    var visible = props.visible, onComplete = props.onComplete;
    var _a = (0, react_2.useState)([]), allLanguages = _a[0], setLanguages = _a[1];
    var _b = (0, react_2.useState)([]), selectedLanguages = _b[0], setSelectedLanguages = _b[1];
    var _c = (0, react_2.useState)(), name = _c[0], setName = _c[1];
    var loading = !name && !selectedLanguages;
    (0, react_2.useEffect)(function () {
        var _a;
        if ((_a = props.languages) === null || _a === void 0 ? void 0 : _a.length) {
            setLanguages((0, languages_1.getLanguages)(props.languages));
        }
        if (props.name) {
            setName(props.name);
        }
    }, [props.name, props.languages]);
    if (!allLanguages && !loading) {
        return (0, jsx_runtime_1.jsx)(NoLanguages, {});
    }
    var setDescription = function (support) { return (support === language_item_1.SupportStatus.Full ? "Fully Supported" : undefined); };
    var options = allLanguages.map(function (l) { return ({ title: l.name, value: l.id, description: setDescription(l.support), img: l.icon }); });
    var onClick = function () {
        if (!selectedLanguages) {
            return;
        }
        var languages = selectedLanguages.map(function (l) { return l.value; });
        onComplete(name, languages);
    };
    var btnEnabled = (name === null || name === void 0 ? void 0 : name.length) && selectedLanguages.length;
    var selectedLength = selectedLanguages.length;
    var label = selectedLength ? "".concat(selectedLength, " Selected") : "Select Language";
    var fullSupport = selectedLanguages.length ? selectedLanguages.every(function (l) { var _a; return ((_a = allLanguages.find(function (lang) { return lang.id === l.value; })) === null || _a === void 0 ? void 0 : _a.support) === language_item_1.SupportStatus.Full; }) : null;
    var fullSupportMsg = "Cosine can link all code in your codebase, so quality will be maximized";
    var partialSupportMsg = "Cosine can link some languages in your codebase, but not all. Quality might be reduced.";
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(react_1.Transition, { appear: true, show: visible, enter: "transform transition ease-in-out duration-300 sm:duration-500", enterFrom: "translate-y-5 opacity-0", enterTo: "translate-y-0 opacity-100", leave: "transform transition ease-in-out duration-300 sm:duration-500", leaveFrom: "translate-y-0 opacity-100", leaveTo: "-translate-y-5 opacity-0", children: (0, jsx_runtime_1.jsxs)(common_1.Card, { className: "p-2 sm:p-4 lg:p-6", children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: "project_name", className: "mb-2 block text-xs font-medium text-on-surface sm:text-sm", children: "Project Name" }), (0, jsx_runtime_1.jsx)("div", { className: "max-w-md", children: loading ? (0, jsx_runtime_1.jsx)(LoadingInput, {}) : (0, jsx_runtime_1.jsx)(common_1.Input, { defaultValue: name, onChange: function (event) { return setName(event.target.value); } }) }), (0, jsx_runtime_1.jsx)("div", { className: "h-2 sm:h-3 md:h-4" }), (0, jsx_runtime_1.jsxs)("div", { className: "relative flex flex-row content-center items-center gap-4", children: [loading ? (0, jsx_runtime_1.jsx)(LoadingListBox, {}) : (0, jsx_runtime_1.jsx)(listbox_1.ListBox, { allOptions: options, label: label, selectedOptions: selectedLanguages, setSelectedOptions: setSelectedLanguages }), fullSupport !== null && ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("p", { className: "text-xs font-medium text-white", children: fullSupport ? "Full Language Support" : "Partial Language Support" }), (0, jsx_runtime_1.jsx)("p", { className: "text-xs text-neutral-400", children: fullSupport ? fullSupportMsg : partialSupportMsg })] }))] }), (0, jsx_runtime_1.jsx)("div", { className: "mt-4 flex w-full justify-end sm:mt-6 md:mt-8", children: (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, disabled: !btnEnabled, label: "Continue", onClick: onClick }) })] }) }) }));
};
exports.InitializeCard = InitializeCard;
var LoadingInput = function () {
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex animate-pulse", children: (0, jsx_runtime_1.jsx)("div", { className: "block h-6 w-full rounded-md border-surface-container-outline bg-surface-container sm:h-8 sm:rounded-lg md:h-10" }) }));
};
var LoadingListBox = function () {
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex w-1/2 max-w-md animate-pulse sm:w-1/3 md:w-2/5", children: (0, jsx_runtime_1.jsx)("div", { className: "block h-6 w-full rounded-md border-surface-container-outline bg-surface-container sm:h-8 sm:rounded-lg md:h-10" }) }));
};
var NoLanguages = function () {
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(common_1.Card, { className: "p-2 sm:p-4 lg:p-6", children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: "supported_languages", className: "mb-2 block text-base font-medium text-on-surface sm:text-lg", children: "No Supported Languages" }), (0, jsx_runtime_1.jsx)("p", { className: "text-xs font-semibold sm:text-sm", children: "We couldn't find any supported languages in this project!" }), (0, jsx_runtime_1.jsx)("p", { className: "text-xs sm:text-sm", children: "Currently supported languages are:" }), (0, jsx_runtime_1.jsx)("div", { className: "mt-3 flex flex-col space-y-2 overflow-auto", children: languages_1.languages
                        .sort(function (a, b) { return (a.name > b.name ? 1 : -1); })
                        .map(function (language) { return ((0, jsx_runtime_1.jsxs)("div", { className: "flex flex-row items-center space-x-2", children: [(0, jsx_runtime_1.jsx)("img", { src: language.icon, className: "h-6 w-6", alt: language.name }), (0, jsx_runtime_1.jsx)("p", { className: "font-semibold", children: language.name })] }, language.id)); }) })] }) }));
};
