import yaml from "js-yaml"
import { Dependencies } from "../types"

export function parseCondaYaml(fileContents: string): Dependencies {
  let condaContents: any
  try {
    condaContents = yaml.load(fileContents) as any
  } catch (err) {
    return {}
  }

  const dependencies: Dependencies = {}

  // The dependencies are located under the 'dependencies' section in conda.yaml
  if (condaContents["dependencies"]) {
    condaContents["dependencies"].forEach((entry: string | Record<string, any>) => {
      if (typeof entry === "string") {
        // Entries can be strings like 'packageName=version'
        const [packageName, version] = entry.split("=")
        dependencies[packageName] = version
      } else if (typeof entry === "object") {
        // Entries can be objects with 'pip' sub-dependencies
        if (entry["pip"]) {
          entry["pip"].forEach((pipEntry: string) => {
            const [packageName, version] = pipEntry.split("==")
            dependencies[packageName] = version
          })
        }
      }
    })
  }

  return dependencies
}
