"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchPanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var components_1 = require("../components");
var model_type_1 = require("../types/model.type");
var ai_providers_1 = require("../types/ai.providers");
var SearchPanel = function (props) {
    var _a = props.provider.properties, providerTitle = _a.title, providerDescription = _a.description;
    var _b = props.provider, provider = _b.value, onChangeProvider = _b.onChange;
    var _c = props.model.properties, modelTitle = _c.title, modelDescription = _c.description;
    var _d = props.model, model = _d.value, onChangeModel = _d.onChange;
    var onReset = props.onReset;
    var aiOptions = Object.values(ai_providers_1.AIProvider).map(function (provider) { return ({
        title: provider.name,
        value: provider.id,
        description: provider.description,
        img: provider.icon,
    }); });
    var models = [
        { title: "Faster", value: model_type_1.ModelType.Faster, description: "The fastest model, great for most everyday tasks" },
        { title: "Smarter", value: model_type_1.ModelType.Smarter, description: "The most capable model, great for tasks that require creativity and advanced reasoning" },
    ];
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex flex-col divide-y divide-surface-bright last:pb-1 last:sm:pb-2", children: (0, jsx_runtime_1.jsxs)(components_1.SettingGroup, { title: "Artificial Intelligence", children: [(0, jsx_runtime_1.jsx)(components_1.DropDownSetting, { title: providerTitle, description: providerDescription, options: aiOptions, label: provider, setOption: onChangeProvider }), (0, jsx_runtime_1.jsx)(components_1.RadioSetting, { title: modelTitle, description: modelDescription, options: models, selected: model, setSelected: onChangeModel }), (0, jsx_runtime_1.jsx)(components_1.ButtonSetting, { title: "Reset Understanding", description: "Deletes the internal understanding of your project", text: "Reset", onClick: onReset, className: "bg-[length:0 px_0px] bg-red-500 text-white enabled:hover:bg-red-700" })] }) }));
};
exports.SearchPanel = SearchPanel;
