"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsMessage = void 0;
var settings_1 = require("../types/settings");
exports.SettingsMessage = {
    Set: Object.assign.apply(Object, __spreadArray([{}], Object.entries(settings_1.Settings).map(function (_a) {
        var _b;
        var key = _a[0], value = _a[1];
        return (_b = {}, _b[key] = "".concat(value, ".set"), _b);
    }), false)),
    Get: Object.assign.apply(Object, __spreadArray([{}], Object.entries(settings_1.Settings).map(function (_a) {
        var _b;
        var key = _a[0], value = _a[1];
        return (_b = {}, _b[key] = "".concat(value, ".get"), _b);
    }), false)),
};
