import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "."

export class ContextChatMessage extends CustomChatMessage<string> {
  public override readonly type = CustomChatMessageType.Context
  public override readonly role = ChatRole.User
  public override data: string

  constructor(data: string) {
    super()
    this.data = data
  }

  public get content(): string {
    return this.data
  }
}
