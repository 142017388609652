import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "./custom.chat.message"
import { Component } from "@cosine/code"

export type ExplainCodeChatProps = {
  question: string
  components: Component[]
  path?: string
}

export class ExplainCodeChatMessage extends CustomChatMessage<ExplainCodeChatProps> {
  public override readonly type = CustomChatMessageType.ExplainCode
  public override readonly role: ChatRole = ChatRole.User
  public inactive = false

  public constructor(public override data: ExplainCodeChatProps) {
    super()
  }

  public override get content(): string {
    const codeBlock = this.data.components
      .map((component) => {
        const { text, start, end } = component
        const pathText = this.data.path ? this.data.path + " " : ""
        return `${pathText}${start.row}:${start.column} ${end.row}:${end.column} \n\`\`\`${text}\`\`\``
      })
      .join("\n\n")

    return `[CODE]\n\n${codeBlock}\n\n${this.data.question}`
  }
}
