"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExplainCodeMessage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_feather_1 = require("react-feather");
var markdown_message_1 = require("./markdown.message");
var code_overlay_1 = require("../code/code.overlay");
var message_card_1 = require("../code/message.card");
var ExplainCodeMessage = function (props) {
    var message = props.message, corners = props.corners;
    var _a = message.data, question = _a.question, path = _a.path, components = _a.components;
    var component = components[0];
    var start = component.start, end = component.end, language = component.language;
    var lines = component === null || component === void 0 ? void 0 : component.text.split("\n");
    var reducedText = lines.slice(0, 5).join("\n");
    var header = {
        title: path,
        subtitle: "[".concat(start.row + 1, ":").concat(start.column + 1, " - ").concat(end.row + 1, ":").concat(end.column + 1, "]"),
        icon: (0, jsx_runtime_1.jsx)(react_feather_1.HelpCircle, { className: "h-2 w-2 stroke-white sm:h-3 sm:w-3" }),
        className: "bg-surface-dim",
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex flex-col items-end space-y-1", children: [(0, jsx_runtime_1.jsx)(message_card_1.MessageCard, { header: header, corners: corners, className: "bg-surface", children: (0, jsx_runtime_1.jsx)(code_overlay_1.CodeHighlightOverlay, { corners: corners, text: reducedText, language: language, expanded: false, reduced: lines.length > 5, color: "to-surface", className: "text-xs" }) }), (0, jsx_runtime_1.jsx)(markdown_message_1.MarkdownMessage, { text: question, corners: corners, isUser: true })] }));
};
exports.ExplainCodeMessage = ExplainCodeMessage;
