/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */

import {
  ChatMessage,
  CustomChatMessage,
  CustomChatMessageType,
  TextChatMessage,
  PromptChatMessage,
  ErrorChatMessage,
  CancelledChatMessage,
  IdeateChatMessage,
} from "@cosine/chat/types"
import { CancellationToken, CancelledException, StreamCancelledError } from "@cosine/cancellation"
import { AnswerMessage, ChatMessage as ChatUIMessage } from "@cosine/ui"
import { BehaviourClassifier, BehaviourService, Interaction } from "@cosine/behaviour"

import { v4 as uuidv4 } from "uuid"
import { Network } from "./network"
import { SmoothOperator } from "../StreamedComponent"
import { join } from "path"
import "dotenv/config"
import { RateLimitException } from "@cosine/ratelimit"
import { RequestInit } from "node-fetch"
import { Data, CoreDataHandler } from "@cosine/data"
import { Lineage } from "@cosine/common"

export interface Message {
  type: string
  data?: any
}

const sendPrefix = "send"

const send = ({ type, data }: Message) => window.postMessage({ type: `${sendPrefix}.${type}`, data }, "*")

let history: ChatMessage[] = []
let project: Project
let projects: Project[]
let cancellationToken = new CancellationToken()
const env = process.env.ENVIRONMENT
const urls: Record<string, string> = {
  development: "http://localhost:8080",
  staging: "https://staging.api.cosine.sh",
  production: "https://api.cosine.sh",
}
const url = urls[env ?? "development"]
const network = new Network(url)
getProjects().then((p) => {
  projects = p
  if (!project) {
    const randomIndex = Math.floor(Math.random() * projects.length)
    project = projects[randomIndex]
  }
  send({ type: "Projects", data: { projects, project } })
})
let conversationId = uuidv4()

const userID = uuidv4()
const dataHandler = new CoreDataHandler(network, userID)
Data.init([dataHandler])
const classifier = new BehaviourClassifier(network)
const behaviourService = new BehaviourService({ classifier })

export const listener = async (event: MessageEvent<Message>) => {
  if (!event.data.type) {
    return
  }
  const [prefix, type] = event.data.type.split(/\.(.*)/s)
  if (type === undefined) {
    return
  }
  const data = event.data.data
  if (prefix === sendPrefix) {
    return
  }
  switch (type) {
    case ChatUIMessage.Prompt:
      const id = uuidv4()
      const projectId = project.uuid
      const prompt = new PromptChatMessage(data)
      history.push(prompt)
      const question: AnswerMessage = { id, prompt, messages: [] }
      cancellationToken = new CancellationToken()
      send({ type: ChatUIMessage.Answering, data: true })
      send({ type: ChatUIMessage.Answer, data: question })
      const interaction = new Interaction({ prompt: prompt.content })
      if (behaviourService.hasInteraction) {
        const classify = async () => {
          const timestamp = Date.now()
          const previous = await behaviourService.classify(interaction)
          if (previous) {
            Data.collect(new Lineage(conversationId, "conversation"), { interaction: previous }, timestamp)
          }
          try {
            behaviourService.end()
          } catch (e) {}
          behaviourService.start(interaction)
        }
        classify()
      } else {
        behaviourService.start(interaction)
      }
      const smoothOperator = new SmoothOperator()
      let latestMessage: ChatMessage[] | null = null
      smoothOperator.onSmoothMessage((message) => {
        if (projectId !== project.uuid) {
          return
        }
        send({ type: ChatUIMessage.Answer, data: message })
      })
      Data.collect(new Lineage(conversationId, "conversation"), { prompt: prompt.content }).catch((err) => console.error(err))
      return await submitPrompt(data, history, project.uuid, cancellationToken, (partials) => {
        if (projectId !== project.uuid) {
          return
        }
        const answer: AnswerMessage = {
          id,
          prompt,
          messages: partials.flat(),
        }
        const mostRecent = answer.messages?.[answer.messages.length - 1]
        if ((mostRecent as any)?.type === CustomChatMessageType.Process) {
          send({ type: ChatUIMessage.Answer, data: answer })
        }

        // Only stream stepped ideate messages to the UI. This is because the message can alter
        // between stepped/non-stepped, and streaming both results in horrible UX
        if ((mostRecent as IdeateChatMessage)?.type === CustomChatMessageType.Ideate && (mostRecent as IdeateChatMessage)?.steps) {
          send({ type: ChatUIMessage.Answer, data: answer })
        }
        latestMessage = partials.flat()
        if ((mostRecent as any)?.type !== CustomChatMessageType.Ideate) {
          smoothOperator.newMessage(answer)
        }
      })
        .catch((err) => {
          if (err instanceof RateLimitException) {
            send({ type: ChatUIMessage.MessageLimit })
            return [new ErrorChatMessage(`Message Limit Reached`)]
          }
          if (err instanceof StreamCancelledError) {
            return latestMessage
          }
          if (err instanceof CancelledException) {
            return [new CancelledChatMessage()]
          }
          return [new ErrorChatMessage("Error generating answer")]
        })
        .then(async (result) => {
          if (projectId !== project.uuid) {
            return
          }
          if ((result?.[result.length - 1] as any)?.type === CustomChatMessageType.Process) {
            result = [new ErrorChatMessage("Request completed too early")]
          }

          const answer = { id, prompt, messages: result } as AnswerMessage
          history.push(...answer.messages)
          send({ type: ChatUIMessage.Answering, data: false })
          if ((answer.messages?.[answer.messages.length - 1] as any)?.type === CustomChatMessageType.Ideate) {
            return send({ type: ChatUIMessage.Answer, data: answer })
          }
          if ((answer.messages?.[answer.messages.length - 1] as any)?.type === CustomChatMessageType.Process) {
            return send({ type: ChatUIMessage.Answer, data: answer })
          }
          smoothOperator.newMessage(answer)
          await smoothOperator.complete()
          if (result) {
            Data.collect(new Lineage(conversationId, "conversation"), { id, answer: result.map((a) => a.content) }).catch((err) => console.error(err))
          }
        })
    case ChatUIMessage.Clear:
      history = []
      project = data ?? project
      const lastInteraction = await behaviourService.classify()
      if (lastInteraction) {
        Data.collect(new Lineage(conversationId, "conversation"), { lastInteraction })
      }
      try {
        behaviourService.end()
      } catch (e) {}
      conversationId = uuidv4()
      return send({ type: ChatUIMessage.Clear })
    case ChatUIMessage.Cancel:
      return cancellationToken.cancel()
    case ChatUIMessage.Examples:
      return send({ type: ChatUIMessage.Examples, data: [] }) // TODO send the example prompts
    case ChatUIMessage.OpenFile:
      // Get line range if available
      let line = ""
      if (data.start && data.end) {
        const start = Number(data.start.row) + 1
        const end = Number(data.end.row) + 1
        line = `#L${start}-L${end}`
      }

      const p = projects.find((p) => p.uuid === project.uuid)!
      const url = join(`${p.url}/blob/${p.commit}`, data.path, line)
      return window.open(url, "_blank", "noreferrer")
    case ChatUIMessage.Reaction:
      return Data.collect(new Lineage(conversationId, "conversation"), { data })
  }
}

async function submitPrompt(
  prompt: string,
  history: ChatMessage[],
  project: string,
  cancellationToken: CancellationToken,
  stream: (partialMessage: ChatMessage[][]) => void,
): Promise<ChatMessage[]> {
  const body = JSON.stringify({ prompt, history, project, uid: userID })
  const request: RequestInit = {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  }
  const response: ReadableStream = await network.fetch("/demo/ask", request, cancellationToken, true).then((res) => res as ReadableStream)
  const reader = response.getReader()
  return await handleStreamResponse<ChatMessage[], ChatMessage[]>(reader, combineChatResult, stream, cancellationToken)
}

export type Project = {
  uuid: string
  displayName: string
  url: string
  commit: string
  samplePrompts: string[]
  languages: string[]
}

export async function getProjects(): Promise<Project[]> {
  return await network.fetch<Project[]>("/demo/projects", {})
}

const handleStreamResponse = async <P, R>(
  readable: ReadableStreamDefaultReader<any>,
  combine: (current: P | undefined, partial: P) => P,
  stream: (partialMessage: P[]) => void,
  cancelToken: CancellationToken,
): Promise<R> => {
  return new Promise<R>(async (resolve, reject) => {
    let result: P | undefined
    let buffer = ""
    await streamToCallback(
      readable,
      (chunk) => {
        const lines = chunk.split("\n").filter((m) => m.length > 0)
        for (const line of lines) {
          buffer += line
          buffer = buffer.replace(/^data: /, "")
          const json = parseJSON(buffer)
          if (!json) {
            continue
          }
          if (json.statusCode >= 500) {
            reject()
            return
          }
          buffer = ""
          result = combine(result, json)
          stream([result!])
        }
      },
      cancelToken,
    ).catch((err) => {
      if (!(err instanceof CancelledException)) {
        reject(err)
      }

      if (Array.isArray(result) && result?.[0]?.type === CustomChatMessageType.Process) {
        reject(err)
      } else if (Array.isArray(result)) {
        reject(new StreamCancelledError(result?.[0]?.id))
      }

      reject(err)
    })
    resolve(result as unknown as R)
  }).catch((err) => {
    if (err?.name === "AbortError") {
      throw new StreamCancelledError(null)
    }
    throw err
  })
}

async function streamToCallback(reader: ReadableStreamDefaultReader<Uint8Array>, callback: (chunk: string) => void, cancelToken: CancellationToken): Promise<void> {
  const { value, done } = await reader.read().catch(() => ({ value: undefined, done: true }))
  cancelToken?.throwIfCancelled()
  if (done) {
    return
  }
  callback(new TextDecoder("utf-8").decode(value))
  return await streamToCallback(reader, callback, cancelToken)
}

function combineChatResult(current: ChatMessage[] | undefined, partials: ChatMessage[]): ChatMessage[] {
  if (!current) {
    return partials
  }
  const last = current[current.length - 1] as CustomChatMessage
  if (last?.type === CustomChatMessageType.Ideate) {
    return [...current.slice(0, -1), combineIdeate(last, partials[0])]
  }
  if (last?.type === CustomChatMessageType.Process) {
    return [...current.slice(0, -1), ...partials]
  }
  if (last?.type !== CustomChatMessageType.Text) {
    return [...current, ...partials]
  }
  const text = partials.find((p) => p.id === last.id) as TextChatMessage
  if (!text.content) {
    return [...current]
  }
  return [...current.slice(0, -1), new TextChatMessage(last.id, last.content + text.content)]
}

function combineIdeate(old: CustomChatMessage, recent: ChatMessage): IdeateChatMessage {
  const data = old.data + recent.data
  const icm = new IdeateChatMessage(old.id, data, true)
  return icm
}

const parseJSON = (text: string) => {
  try {
    return JSON.parse(text)
  } catch (error) {
    return null
  }
}
