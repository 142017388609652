import { CreateChatCompletionResponse, CreateCompletionResponse } from "openai"
import { ChatFunctionCall, ChatResult, Message, PartialChatResult } from "../../types"
import { IncomingMessage } from "http"
import { CompletionResult, PartialCompletionResult } from "../../types/completion/completion.result"
import { ChatRole } from "../../types"
import { estimateTokens, sanitizeJSON, isJSON, TokenizerModel } from "@cosine/common"

export interface CreateChatCompletionStreamResponse {
  id: string
  object: string
  created: number
  model: string
  choices: Array<CreateChatCompletionStreamResponseChoicesInner>
}

export interface CreateChatCompletionStreamResponseChoicesInner {
  delta: { role?: string; content?: string; function_call?: { name?: string; arguments?: string } }
  index: number
  finish_reason: string
}

export type CreateChatCompletionStreamResponseCallback = (response: CreateChatCompletionStreamResponse) => void

export function extractStreamChatResult(update: CreateChatCompletionStreamResponse): PartialChatResult | undefined {
  if (!update.choices || update.choices.length === 0) {
    return undefined
  }
  const choice = update.choices[0]
  const delta = choice.delta
  const message = {
    role: (delta.role as ChatRole) ?? ChatRole.Assistant,
    content: delta.content,
  }
  const result: PartialChatResult = {
    id: update.id,
    model: update.model,
    message,
    finish_reason: choice.finish_reason,
  }
  if (delta.function_call) {
    result.function_call = {
      name: delta.function_call.name,
      arguments: delta.function_call.arguments,
    }
  }
  return result
}

export type ExtractionPrefs = { includeStop: boolean; stop: string[]; prefix?: string }

export function extractChatResult(data: CreateChatCompletionResponse, stopPrefs?: ExtractionPrefs): ChatResult | undefined {
  if (!data.choices || data.choices.length === 0) {
    return undefined
  }
  const choice = data.choices[0]
  const message = choice.message as Message
  if (!message) {
    return undefined
  }
  if (stopPrefs) {
    const { includeStop, stop, prefix } = stopPrefs
    if (includeStop && choice.finish_reason === "stop" && stop.length === 1) {
      message.content = message.content + stop[0]
    }
    if (prefix) {
      message.content = prefix + message.content
    }
  }
  let functionCall: ChatFunctionCall | undefined = undefined
  if (choice.message?.function_call && choice.message.function_call.name && choice.message.function_call.arguments) {
    functionCall = {
      name: choice.message.function_call.name,
      arguments: sanitizeJSON(choice.message.function_call.arguments),
    }
  }
  if (choice.finish_reason === "content_filter") throw new Error("Inappropriate content detected")
  return {
    id: data.id,
    model: data.model,
    message,
    finish_reason: choice.finish_reason,
    usage: data.usage,
    function_call: functionCall,
    logprobs: (choice as any).logprobs, // TODO: Update this when the OpenAI NPM package is updated
  }
}

export interface CreateCompletionStreamResponse {
  id: string
  object: string
  created: number
  model: string
  choices: Array<CreateCompletionStreamResponseChoicesInner>
}

export interface CreateCompletionStreamResponseChoicesInner {
  text: string
  index: number
  logprobs: any
  finish_reason: string
}

export type CreateCompletionStreamResponseCallback = (response: CreateCompletionStreamResponse) => void

export function extractCompletionResult(data: CreateCompletionResponse, stopPrefs?: ExtractionPrefs): CompletionResult | undefined {
  if (!data.choices || data.choices.length === 0) {
    return undefined
  }
  const choice = data.choices[0]
  if (!choice.text) {
    return undefined
  }
  if (stopPrefs) {
    const { includeStop, stop, prefix } = stopPrefs
    if (includeStop && choice.finish_reason === "stop" && stop.length === 1) {
      choice.text = choice.text + stop[0]
    }
    if (prefix) {
      choice.text = prefix + choice.text
    }
  }
  return {
    id: data.id,
    model: data.model,
    text: choice.text,
    finish_reason: choice.finish_reason,
    usage: data.usage,
  }
}

export function extractStreamCompletionResult(update: CreateCompletionStreamResponse): PartialCompletionResult | undefined {
  if (!update.choices || update.choices.length === 0) {
    return undefined
  }
  const choice = update.choices[0]
  return {
    id: update.id,
    model: update.model,
    text: choice.text,
    finish_reason: choice.finish_reason,
  }
}

export const castPartialStreamChatResult = (result: PartialChatResult): ChatResult => {
  const function_call =
    result.function_call && isJSON(result.function_call!.arguments!)
      ? {
          name: result.function_call!.name!,
          arguments: JSON.parse(result.function_call!.arguments!),
        }
      : undefined
  return {
    id: result.id,
    model: result.model,
    message: result.message,
    finish_reason: result.finish_reason,
    usage: result.usage,
    function_call,
  }
}

export async function handleStream<PartialResultType, StreamResponseType>(
  response: IncomingMessage,
  model: string,
  extraction: (current: StreamResponseType) => PartialResultType | undefined,
  combine: (current: PartialResultType | undefined, partial: PartialResultType) => PartialResultType,
  callback: (response: PartialResultType[]) => void,
): Promise<PartialResultType> {
  return new Promise((resolve, reject) => {
    let result: PartialResultType | undefined
    let estimatedTokenUsage = 0
    let buffer = ""
    response.on("data", (data: Buffer) => {
      const messages = data
        .toString("utf8")
        .split("\n")
        .filter((m) => m.length > 0)
      for (const message of messages) {
        buffer += message.replace(/^data: /, "")
        if (buffer === "[DONE]") {
          return
        }
        const json = parseJSON(buffer)
        if (!json) {
          continue
        }
        const partial = extraction(json)
        if (!partial) {
          continue
        }
        buffer = ""
        const content = partial?.["message"]?.["content"]
        if (content) {
          estimatedTokenUsage += estimateTokens(content, model as TokenizerModel)
        }
        result = combine(result, partial)
        callback([partial])
      }
    })
    response.on("end", () => {
      if (result) {
        result["usage"] = { completion_tokens: estimatedTokenUsage }
      }
      resolve(result!)
    })
    response.on("error", (error: Error) => reject(error))
  })
}

export function combineChatResult(current: PartialChatResult | undefined, partial: PartialChatResult): PartialChatResult {
  if (!current && partial) {
    return partial
  }
  return {
    ...partial,
    message: {
      ...partial.message,
      content: (current?.message?.content ?? "") + (partial.message?.content ?? ""),
    },
  }
}

export function combineCompletionResult(current: PartialCompletionResult | undefined, partial: PartialCompletionResult): PartialCompletionResult {
  if (!current && partial) {
    return partial
  }
  return {
    ...partial,
    text: (current?.text ?? "") + (partial.text ?? ""),
    finish_reason: partial.finish_reason,
  }
}

const parseJSON = (text: string) => {
  try {
    return JSON.parse(text)
  } catch (error) {
    return null
  }
}
