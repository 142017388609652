export interface AIProvider {
  id: string
  name: string
  description: string
  icon: string
}

export enum AIProviderIds {
  OpenAI = "OpenAI",
  // Anthropic = "Anthropic",
  // Cohere = "Cohere",
}

export const AIProvider: Record<AIProviderIds, AIProvider> = {
  [AIProviderIds.OpenAI]: {
    id: AIProviderIds.OpenAI,
    name: "OpenAI",
    description: "OpenAI is an AI research and deployment company.",
    icon: "https://firebasestorage.googleapis.com/v0/b/buildt-internal.appspot.com/o/providers%2Fopenai.svg?alt=media&token=8ea3fb8f-25e0-49b3-aaae-7ff63e2cbd21",
  },
  // [AIProviderIds.Anthropic]: {
  //   id: AIProviderIds.Anthropic,
  //   name: "Anthropic",
  //   description: "Anthropic is a research lab building large-scale AI systems.",
  //   icon: "https://firebasestorage.googleapis.com/v0/b/buildt-internal.appspot.com/o/providers%2Fanthropic.png?alt=media&token=3e9ebaf3-504e-4f07-92dd-81281bf849f4",
  // },
  // [AIProviderIds.Cohere]: {
  //   id: AIProviderIds.Cohere,
  //   name: "Cohere",
  //   description: "Cohere provides access to advanced LLM and NLP tools",
  //   icon: "https://firebasestorage.googleapis.com/v0/b/buildt-internal.appspot.com/o/providers%2Fcohere.png?alt=media&token=0801fa8c-9d5e-4f09-9616-71603496a027",
  // },
} as const
