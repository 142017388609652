"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageGroup = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var styled_message_1 = require("./styled.message");
var message_1 = require("./message");
var types_1 = require("@cosine/chat/types");
var types_2 = require("@cosine/ai/types");
var canReact = function (msg) {
    if (!msg) {
        return false;
    }
    var nonReactMsg = msg.type !== types_1.CustomChatMessageType.Process && msg.type !== types_1.CustomChatMessageType.Cancelled && msg.type !== types_1.CustomChatMessageType.Error;
    return nonReactMsg && msg.role == types_2.ChatRole.Assistant;
};
function MessageGroup(_a) {
    var id = _a.id, messages = _a.messages, prompt = _a.prompt, delay = _a.delay, onOpenFile = _a.onOpenFile, onUpvote = _a.onUpvote, onDownvote = _a.onDownvote, disableReaction = _a.disableReaction, disableTransition = _a.disableTransition, linkToMessage = _a.linkToMessage;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "mb-3 flex w-full flex-col justify-between", children: [(0, jsx_runtime_1.jsx)("div", { className: "mx-0 mb-1", children: (0, jsx_runtime_1.jsx)(styled_message_1.StyledMessage, { id: prompt.id, side: "right", children: (0, jsx_runtime_1.jsx)(message_1.Message, { disableTransition: disableTransition, message: prompt, isUser: true, side: "right", bottom: true, top: true, delay: "0" }) }) }), (messages === null || messages === void 0 ? void 0 : messages.length) > 0 && ((0, jsx_runtime_1.jsx)("div", { className: "mb-1 sm:mb-2 sm:mt-6 md:my-3 lg:my-4", children: messages.map(function (message, index) {
                    var bottom = index === messages.length - 1;
                    var top = index === 0;
                    var custom = message;
                    return ((0, jsx_runtime_1.jsx)(styled_message_1.StyledMessage, { id: message.id, side: "left", linkToMessage: linkToMessage, canReact: disableReaction ? false : canReact(custom), onUpvote: function () { return onUpvote === null || onUpvote === void 0 ? void 0 : onUpvote(id, index); }, onDownvote: function () { return onDownvote === null || onDownvote === void 0 ? void 0 : onDownvote(id, index); }, children: (0, jsx_runtime_1.jsx)(message_1.Message, { disableTransition: disableTransition, message: message, isUser: false, side: "left", bottom: bottom, top: top, delay: delay, onOpenFile: onOpenFile }) }, index));
                }) }))] }));
}
exports.MessageGroup = MessageGroup;
