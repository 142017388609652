"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@headlessui/react");
var progress_area_1 = require("./progress.area");
var progress_sub_bar_1 = require("./progress.sub.bar");
var progress_bar_1 = require("./progress.bar");
var useTask_1 = require("./task/useTask");
var status_1 = require("./task/status");
var utils_1 = require("./task/utils");
var common_1 = require("../../../../common");
function ProgressCard(props) {
    var onComplete = props.onComplete, onRetry = props.onRetry, onCancel = props.onCancel, onBack = props.onBack, onNext = props.onNext, active = props.active;
    var tasks = (0, useTask_1.useTask)(props.tasks)[0];
    var _a = (0, react_1.useState)(false), done = _a[0], setDone = _a[1];
    (0, react_1.useEffect)(function () {
        if (!done && tasks.every(function (task) { return task.status === status_1.Status.Done; })) {
            setDone(true);
        }
    }, tasks);
    (0, react_1.useEffect)(function () {
        if (!done) {
            return;
        }
        onComplete();
    }, [done]);
    return ((0, jsx_runtime_1.jsx)("div", { className: "w-full", children: (0, jsx_runtime_1.jsx)(react_2.Transition, { appear: true, show: true, enter: "transform transition ease-in-out duration-300 sm:duration-500", enterFrom: "translate-y-5 opacity-0", enterTo: "translate-y-0 opacity-100", children: (0, jsx_runtime_1.jsx)(common_1.Card, { className: "overflow-hidden px-3 py-2 sm:p-4 md:px-5 md:py-6", children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [tasks.map(function (task, index) {
                            var _a;
                            var bottom = index === tasks.length - 1;
                            var status = task.status;
                            var title = (_a = props.title) !== null && _a !== void 0 ? _a : (0, utils_1.getTitle)(task);
                            return ((0, jsx_runtime_1.jsx)(progress_area_1.ProgressArea, { title: title, status: status, bottom: bottom, children: task.subtasks.map(function (subtask, index) {
                                    return task.subtasks.length === 1 ? (0, jsx_runtime_1.jsx)(progress_bar_1.ProgressBar, { subtask: subtask }, index) : (0, jsx_runtime_1.jsx)(progress_sub_bar_1.SubProgressBar, { subtask: subtask }, index);
                                }) }, index));
                        }), (0, jsx_runtime_1.jsxs)("div", { className: "flex flex-row justify-end", children: [!active && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, className: "mt-5 flex sm:mt-8 md:mt-12", label: "Back", onClick: function () { return onBack(); } }), (0, jsx_runtime_1.jsx)("div", { className: "mx-2 mt-5 flex sm:mt-8 md:mt-12", children: (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, disabled: active, label: "Retry", onClick: function () { return onRetry(); } }) })] })), props.showButton !== false && active && !done && ((0, jsx_runtime_1.jsx)("div", { className: "mx-2 mt-5 flex sm:mt-8 md:mt-12", children: (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, label: "Cancel", onClick: function () { return onCancel(); } }) })), props.showButton !== false && active && ((0, jsx_runtime_1.jsx)("div", { className: "mt-5 flex sm:mt-8 md:mt-12", children: (0, jsx_runtime_1.jsx)(common_1.Button, { gradient: true, disabled: !done, label: "Continue", onClick: function () { return onNext(); } }) }))] })] }) }) }) }));
}
exports.ProgressCard = ProgressCard;
