import { similarity } from "./strings.utils"

export function flattenArray(arr: any[][]): any[] {
  return arr.reduce((a, b) => a.concat(b), [])
}

export function arrayContainsFuzzy(arr: any[], item: any, tolerance = 0.8): boolean {
  return arr.some((el) => similarity(el, item) >= tolerance)
}

export function chunkArray<T>(arr: T[], size: number): T[][] {
  const chunkedArr: T[][] = []
  let index = 0
  while (index < arr.length) {
    chunkedArr.push(arr.slice(index, size + index))
    index += size
  }
  return chunkedArr
}

export function findLastIndex<T>(array: T[], predicate: (value: T, index: number, obj: T[]) => boolean): number {
  return array.length - 1 - array.slice().reverse().findIndex(predicate)
}

export async function asyncSort<T>(array: T[], callback: (a: T, b: T) => Promise<number>): Promise<T[]> {
  const sortedArray = [...array] // Create a copy of the array
  for (let i = 0; i < sortedArray.length; i++) {
    for (let j = i + 1; j < sortedArray.length; j++) {
      // Use the callback function to determine the order
      if ((await callback(sortedArray[i], sortedArray[j])) > 0) {
        // Swap elements if necessary
        const temp = sortedArray[i]
        sortedArray[i] = sortedArray[j]
        sortedArray[j] = temp
      }
    }
  }
  return sortedArray
}

export async function asyncFilter<T>(array: T[], callback: (item: T) => Promise<boolean>): Promise<T[]> {
  const filteredItems: T[] = []
  for (const item of array) {
    if (await callback(item)) {
      filteredItems.push(item)
    }
  }
  return filteredItems
}

export type DiffResult<T> = {
  inserted: T[]
  removed: T[]
}

export function diffArrays<T>(oldArray: T[], newArray: T[]): DiffResult<T> {
  const inserted: T[] = []
  const removed: T[] = []

  // Find elements that are inserted
  for (const element of newArray) {
    if (!oldArray.includes(element)) {
      inserted.push(element)
    }
  }

  // Find elements that are removed
  for (const element of oldArray) {
    if (!newArray.includes(element)) {
      removed.push(element)
    }
  }

  return {
    inserted,
    removed,
  }
}

export function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }

  const sortedArr1 = [...arr1].sort()
  const sortedArr2 = [...arr2].sort()

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false
    }
  }

  return true
}

export function removeElementsByIndices<T>(array: T[], indicesToRemove: number[]): T[] {
  return array.filter((_, index) => !indicesToRemove.includes(index))
}
