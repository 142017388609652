import { MemoryType } from "./types/memory"
import { VectorDatabase } from "@cosine/database"
import { CancellationToken } from "@cosine/cancellation"
import { AI, AIModel, ContentValidator, CreateChatProps } from "@cosine/ai"
import { v4 } from "uuid"
import { ChatMessage, TextChatMessage } from "./types"
import { SystemPromptMessage } from "./types/messages/system.prompt.message"
import { Lineage, hashString } from "@cosine/common"
import { Data } from "@cosine/data"
import { ChatHistory, ChatHistoryEvents } from "./history"

export type ChatbotProps = {
  ai: AI
  memoryType: MemoryType
  systemPrompt: string
  model: AIModel
  additionalOptions?: Partial<CreateChatProps>
  db?: VectorDatabase<ChatMessage>
  listeners?: Partial<ChatHistoryEvents>
}

export class ChatBot {
  protected ai: AI
  protected memoryType: MemoryType
  protected systemPrompt: string
  protected model: AIModel
  protected db?: VectorDatabase<ChatMessage>
  protected conversation!: string
  protected contextWindow: ChatMessage[] = []
  protected additionalOptions?: Partial<CreateChatProps>

  protected history: ChatHistory = new ChatHistory()

  constructor({ ai, memoryType, model, systemPrompt, db, listeners, additionalOptions }: ChatbotProps) {
    if (memoryType === MemoryType.Discontiguous && !db) {
      throw new Error("Discontiguous memory requires a database")
    }
    this.ai = ai
    this.memoryType = memoryType
    this.model = model
    this.systemPrompt = systemPrompt
    this.db = db
    this.additionalOptions = additionalOptions

    if (listeners) {
      this.history = new ChatHistory(listeners)
    }
  }

  public getSystemPrompt = (): SystemPromptMessage => new SystemPromptMessage(this.systemPrompt)

  public async ask(prompt: ChatMessage, cancelToken: CancellationToken, stream?: ChatStream, validator?: ContentValidator): Promise<ChatMessage[]> {
    const sysPrompt = this.getSystemPrompt()
    const props: CreateChatProps = {
      model: this.model,
      messages: [this.getSystemPrompt(), ...(await this.getContextWindow()), prompt],
      stream: !!stream,
      temperature: 0,
      validator,
      availableAttempts: validator ? 3 : undefined,
      ...(this.additionalOptions || {}),
    }
    cancelToken.throwIfCancelled()
    const response = await this.ai.createChat(props, cancelToken, (messages) => stream?.(messages.map((m) => new TextChatMessage(m.id, m.message.content!))))
    if (!response) {
      throw new Error("Failed to generate chat response")
    }
    Data.collect(new Lineage(this.conversationId, "chatbot"), { props, response, type: "prompt" })
    const textMsg = new TextChatMessage(response.id, response.message.content!)
    this.history.push(prompt, textMsg)
    return [textMsg]
  }

  public setModel = (model: AIModel) => (this.model = model)

  public clearMessages() {
    this.history.clear()
  }

  public loadConversationContext(id: string, messages: ChatMessage[]) {
    this.history.load(id, messages)
  }

  public getHistory() {
    return this.history.messages
  }

  public async getContextWindow() {
    return await this.history.contextWindow(this.memoryType, this.getSystemPrompt(), this.model, this.ai)
  }

  public pushMessage(message: ChatMessage) {
    this.history.push(message)
  }

  public get conversationId() {
    return this.history.conversationId
  }
}

export type ChatStream = (messages: ChatMessage[]) => void
