import { Dependencies } from "../types"

export function parseRequirementsTxt(fileContents: string): Dependencies {
  const lines = fileContents.split("\n")
  const dependencies: Dependencies = {}

  lines.forEach((line) => {
    if (line.includes("==") && !line.startsWith("#")) {
      const [packageName, version] = line.split("==")
      if (packageName && version) {
        dependencies[packageName.trim()] = version.trim()
      }
    }
  })

  return dependencies
}
