"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FreePlan = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var FreePlan = function (props) {
    var daysRemaining = props.daysRemaining, trialLength = props.trialLength;
    var progress = Math.min(Math.max(trialLength - daysRemaining, 0), 100);
    var percentage = Math.round((progress / trialLength) * 100);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "mb-4 w-full", children: [(0, jsx_runtime_1.jsxs)("div", { children: ["You have ", props.daysRemaining, " days left of your free trial period"] }), (0, jsx_runtime_1.jsx)("div", { className: "mt-2 h-2 w-full flex-1 rounded-full bg-gray-700", children: (0, jsx_runtime_1.jsx)("div", { className: "h-2 w-full rounded-full bg-blue-600", style: { width: "".concat(percentage, "%"), transition: "width 1s" } }) })] }));
};
exports.FreePlan = FreePlan;
