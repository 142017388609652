"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YourHistoryContainer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var use_message_1 = require("@/common/contexts/use.message");
var events_1 = require("./events");
var common_1 = require("@/common");
var react_feather_1 = require("react-feather");
__exportStar(require("./events"), exports);
var formatDate = function (date) {
    var diff = Date.now() - date.getTime();
    var seconds = Math.floor(diff / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    if (days > 0)
        return date.toLocaleDateString(navigator.language, { month: 'short', day: 'numeric' });
    if (hours > 0)
        return "".concat(hours, "h ago");
    if (minutes > 0)
        return "".concat(minutes, "m ago");
    if (seconds > 0)
        return "Just now";
};
var conversationEvent = new common_1.MessageEventType("Conversation");
/**
 * UI for displaying a snippet of your previous conversations in the main home UI
*/
var YourHistoryContainer = function () {
    var loadConversation = (0, use_message_1.useSendMessage)(conversationEvent);
    var showAll = (0, use_message_1.useSendMessage)(events_1.ChatHistoryEvent.ShowAllConversations);
    var deleteConversation = (0, use_message_1.useSendMessage)(events_1.ChatHistoryEvent.DeleteConversation);
    var _a = (0, use_message_1.useMessageSubscription)(events_1.ChatHistoryEvent.GetPaginatedConversations, { page: 1, pageSize: 10 }), data = _a.data, isSuccess = _a.isSuccess, refetch = _a.refetch;
    if (!isSuccess || !data || data.length === 0) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: " " });
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: "px-4 flex flex-col", children: [(0, jsx_runtime_1.jsxs)("div", { className: "flex justify-between items-center mb-2", children: [(0, jsx_runtime_1.jsx)("h2", { className: "text-neutral-300", children: "Continue from where you left off" }), (0, jsx_runtime_1.jsx)("button", { className: "text-brand-purple-500 hover:underline", onClick: function () {
                            showAll.send();
                            refetch({ page: 1, pageSize: 1000 });
                        }, children: "Show All" })] }), (0, jsx_runtime_1.jsx)("div", { className: "flex gap-2 flex-col text-xs", children: data.map(function (conversation) { return ((0, jsx_runtime_1.jsxs)("div", { className: "flex bg-neutral-700 hover:bg-neutral-600 justify-between items-center rounded-md", children: [(0, jsx_runtime_1.jsx)("button", { onClick: function () {
                                loadConversation.send(conversation.id);
                            }, className: "text-neutral-300 w-full grow text-left truncate py-2 pl-4 pr-2", children: conversation.title }), (0, jsx_runtime_1.jsx)("div", { className: "group text-right text-neutral-500 grow whitespace-nowrap", children: (0, jsx_runtime_1.jsxs)("div", { className: "w-full", children: [(0, jsx_runtime_1.jsx)("button", { onClick: function () {
                                            deleteConversation.send(conversation.id);
                                        }, className: "group-hover:block gap-1 items-center float-right text-xs rounded-md text-right px-3 py-2.5 bg-rose-500 hidden text-white", children: (0, jsx_runtime_1.jsx)(react_feather_1.Trash, { className: "h-3 w-3 stroke-white" }) }), (0, jsx_runtime_1.jsx)("span", { className: "group-hover:hidden pr-3", children: formatDate(new Date(conversation.created_at)) })] }) })] }, conversation.id)); }) })] }));
};
exports.YourHistoryContainer = YourHistoryContainer;
