"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToggleSetting = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var setting_1 = require("./setting");
var toggle_1 = require("../../../common/components/toggle");
function ToggleSetting(props) {
    var title = props.title, description = props.description, icon = props.icon, checked = props.checked, setChecked = props.setChecked;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex py-2 first:pt-2 last:pb-2 sm:py-4 first:sm:pt-3 last:sm:pb-2", children: [icon ? (0, jsx_runtime_1.jsx)("div", { className: "hidden sm:block sm:pr-4 sm:pt-2", children: icon }) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), (0, jsx_runtime_1.jsx)(setting_1.Setting, { title: title, description: description, className: "flex-row items-center space-x-3 sm:space-x-4 md:space-x-5 lg:space-x-6", children: (0, jsx_runtime_1.jsx)(toggle_1.Toggle, { checked: checked, label: undefined, gradient: true, classNameUnchecked: "bg-surface-container-low", setChecked: setChecked, size: "large" }) })] }));
}
exports.ToggleSetting = ToggleSetting;
