"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetting = void 0;
var common_1 = require("../../../common");
var react_1 = require("react");
var messages_1 = require("../messages");
var useSetting = function (setting, defaultValue, message) {
    if (message === void 0) { message = messages_1.SettingsMessage; }
    var _a = (0, react_1.useState)(defaultValue), value = _a[0], setValue = _a[1];
    var sendMessage = (0, react_1.useContext)(common_1.MessageContext).sendMessage;
    (0, common_1.useMessage)(message.Set[setting], function (value) { return setValue(value); });
    (0, react_1.useEffect)(function () { return sendMessage(message.Get[setting]); }, []);
    var setSetting = function (value) { return sendMessage(message.Set[setting], value); };
    return [value, setSetting];
};
exports.useSetting = useSetting;
