"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelledMessage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_feather_1 = require("react-feather");
var CancelledMessage = function (props) {
    var content = props.message.content;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "inline-flex flex-row space-x-2 rounded-lg bg-surface px-3 py-2 text-xs", children: [(0, jsx_runtime_1.jsx)(react_feather_1.AlertCircle, { className: "h-4 w-4 flex-none text-orange-400" }), (0, jsx_runtime_1.jsx)("p", { className: "font-sem-bold text-orange-400", children: content })] }));
};
exports.CancelledMessage = CancelledMessage;
