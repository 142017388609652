export function getCurrentDateTime(): string {
  const date = new Date()

  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const year = String(date.getFullYear())

  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")
  const seconds = String(date.getSeconds()).padStart(2, "0")

  return `${day}-${month}-${year}T${hours}:${minutes}:${seconds}`
}

export function formatTime(seconds: number): string {
  // Round to the nearest minute (60 seconds)
  const minute = 60
  const roundedSeconds = Math.round(seconds / minute) * minute

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(roundedSeconds / 86400)
  const hours = Math.floor((roundedSeconds % 86400) / 3600)
  const minutes = Math.floor((roundedSeconds % 3600) / 60)
  const remainingSeconds = roundedSeconds % 60

  // Pad hours, minutes, and seconds with leading zeros
  const paddedHours = hours.toString().padStart(2, "0")
  const paddedMinutes = minutes.toString().padStart(2, "0")
  const _paddedSeconds = remainingSeconds.toString().padStart(2, "0")

  // Format the output string
  return `${days > 0 ? days + "d:" : ""}${paddedHours}h:${paddedMinutes}m`
}

export function convertToSeconds(timeStr: string): number {
  // Regular expression to extract days, hours, and minutes
  const regex = /(?:(\d+)d:)?(\d+)h:(\d+)m/
  const match = timeStr.match(regex)

  if (!match) {
    throw new Error("Invalid time format")
  }

  // Extract days, hours, and minutes from the string
  const days = match[1] ? parseInt(match[1], 10) : 0
  const hours = parseInt(match[2], 10)
  const minutes = parseInt(match[3], 10)

  // Convert days, hours, and minutes to seconds and sum them up
  return days * 86400 + hours * 3600 + minutes * 60
}

export function timeAgo(date: Date, includeAgo = true): string {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)

  let interval = seconds / 31_536_000

  if (interval > 1) {
    return Math.floor(interval) + " years" + (includeAgo ? " ago" : "")
  }
  interval = seconds / 2_592_000
  if (interval > 1) {
    return Math.floor(interval) + " months" + (includeAgo ? " ago" : "")
  }
  interval = seconds / 86_400
  if (interval > 1) {
    return Math.floor(interval) + " days" + (includeAgo ? " ago" : "")
  }
  interval = seconds / 3_600
  if (interval > 1) {
    return Math.floor(interval) + " hours" + (includeAgo ? " ago" : "")
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + " minutes" + (includeAgo ? " ago" : "")
  }
  return Math.floor(seconds) + " seconds" + (includeAgo ? " ago" : "")
}
