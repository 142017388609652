import { Classification } from "./types/classification.type"
import { Interaction } from "./types/interaction"
import { InteractionEvent } from "./types/interaction.event"
import { InteractionType } from "./types/interaction.type"
import { InteractionComparison } from "./types/interaction.comparison"
import { findLastIndex } from "@cosine/common"
import { Network } from "@cosine/network"
import { RequestInit, BodyInit } from "node-fetch"

export class BehaviourClassifier {
  constructor(private network: Network) {}

  public async compareInteractions(interactionA: Interaction, interactionB: Interaction): Promise<InteractionComparison | undefined> {
    // Skip the network check if possible
    if (interactionA.prompt === interactionB.prompt) {
      return { isReworded: false, isRegenerated: true, isContinuation: false, isIncompleteContinuation: false }
    }

    const url = "/data/compare"
    const body: BodyInit = JSON.stringify({ interactionA, interactionB })
    const request: RequestInit = {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    }
    const response = (await this.network.fetch(url, request)) as InteractionComparison | undefined
    return response
  }

  // TODO: Need to look at the interaction events and classify the interaction as good or bad
  public async classify(interaction: Interaction): Promise<Classification> {
    const interactions = interaction.getEvents()
    const lastEvent = interactions[interactions.length - 1]
    if (lastEvent) {
      // If they reword or regenerate we can assume it was a bad interaction
      if (
        lastEvent.type === InteractionType.REGENERATE_RESULTS ||
        lastEvent.type === InteractionType.REWORDED_QUERY ||
        lastEvent.type === InteractionType.CONTINUE_INCOMPLETE_CONVERSATION
      ) {
        return Classification.Bad
      }
      // If they clicked on a result and nothing more it was a good interaction
      if (lastEvent.type === InteractionType.RESULT_CLICKED && interactions.length === 1) {
        return Classification.Good
      }
      // If they just continue the conversation it was a good interaction
      if (lastEvent.type === InteractionType.CONVERSATION_CONTINUED) {
        return Classification.Good
      }
    }
    // Look at some predefined sequences of events to see if they match
    const clickNoClose = this.checkForSequence(interaction, [InteractionType.RESULT_CLICKED], [InteractionType.CLOSED_FILE, InteractionType.RESULTS_EXPANDED])
    if (clickNoClose) {
      return Classification.Good
    }
    // If they clicked, scrolled around and didn't leave
    const clickedAndScrolled = this.checkForSequence(
      interaction,
      [InteractionType.RESULT_CLICKED, InteractionType.SCROLLED_IN_FILE],
      [InteractionType.EXPLORED_ADJACENT_FILES, InteractionType.EXPLORED_DISTANT_FILES, InteractionType.CLOSED_FILE],
    )
    if (clickedAndScrolled) {
      if (clickedAndScrolled.timeTaken < 1200) {
        return Classification.Bad
      }
      if (clickedAndScrolled.multipleFound) {
        return Classification.Bad
      }
      return Classification.Good
    }
    // If they clicked, scrolled around and left
    const clickedClosedExplored = this.checkForSequence(interaction, [InteractionType.RESULT_CLICKED, InteractionType.CLOSED_FILE, InteractionType.EXPLORED_DISTANT_FILES], [])
    if (clickedClosedExplored) {
      return Classification.Bad
    }
    return Classification.Good
  }

  private checkForSequence(
    interaction: Interaction,
    sequence: InteractionType[],
    notContained: InteractionType[],
  ): { sequence: InteractionEvent[]; timeTaken: number; multipleFound: boolean } | undefined {
    const events = interaction.getEvents()
    if (events.length < sequence.length) {
      return undefined
    }
    // We need to find a sequence of events that start with the first event in the sequence, contain all the events in the sequence and end with the last event in the sequence
    const firstEvent = sequence[0]
    const lastEvent = sequence[sequence.length - 1]
    const firstEventIndex = events.findIndex((event) => event.type === firstEvent)
    if (firstEventIndex === -1) {
      return undefined
    }
    const lastEventIndex = findLastIndex(events, (event) => event.type === lastEvent)
    if (lastEventIndex === -1) {
      return undefined
    }
    const sequenceEvents = events.slice(firstEventIndex, lastEventIndex + 1)
    const notContainedEvents = sequenceEvents.filter((event) => notContained.some((notContainedEvent) => notContainedEvent === event.type))
    if (notContainedEvents.length > 0) {
      return undefined
    }
    const allContained = sequence.every((event) => sequenceEvents.some((sequenceEvent) => sequenceEvent.type === event))
    if (!allContained) {
      return undefined
    }
    if (sequenceEvents[firstEventIndex] === undefined) {
      return undefined
    }
    if (sequenceEvents[lastEventIndex] === undefined) {
      return undefined
    }
    const timeTaken = sequenceEvents[firstEventIndex].timestamp - sequenceEvents[lastEventIndex].timestamp
    const multipleFound = sequenceEvents.length > sequence.length
    return { sequence: sequenceEvents, timeTaken, multipleFound }
  }
}
