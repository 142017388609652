import { euclidean } from "ml-distance-euclidean"
import distanceMatrix from "ml-distance-matrix"

/**
 * Calculate Silhouette Coefficient
 * @param {Array<Array<number>>} data - list of input data samples
 * @param {Array<number>} labels - label values for each sample
 * @returns {number} score - Silhouette Score for input clustering
 */
export function silhouetteScore(data: Array<Array<number>>, labels: Array<number>): number {
  const dist = distanceMatrix(data, euclidean)
  const result = silhouetteSamples(dist, labels, silhouetteReduce)
  return result.reduce((p: number, c: number, i: number) => p + (c - p) / (i + 1), 0)
}

/**
 * Calculate Silhouette for each data sample
 * @param {Array<Array<number>>} data - list of input data samples
 * @param {Array<number>} labels - label values for each sample
 * @param {Function|Mock} reduceFunction - reduce function to apply on samples
 * @returns {Array<number>} arr - Silhouette Coefficient for each sample
 */
function silhouetteSamples(data: Array<Array<number>>, labels: Array<number>, reduceFunction: any): Array<number> {
  const labelsFreq = countBy(labels)
  const samples = reduceFunction(data, labels, labelsFreq)
  const denom = labels.map((val) => labelsFreq[val] - 1)
  const intra = samples.intraDist.map((val: number, ind: number) => val / denom[ind])
  const inter = samples.interDist
  return inter.map((val: number, ind: number) => val - intra[ind]).map((val: number, ind: number) => val / Math.max(intra[ind], inter[ind]))
}

/**
 * Count the number of occurrences of each value in array.
 * @param {Array<number>} arr - Array of positive Integer values
 * @return {Array<number>} out - number of occurrences of each value starting from
 * 0 to max(arr).
 */
function countBy(arr: Array<number>): Array<number> {
  const valid = arr.every((val) => {
    if (typeof val !== "number") {
      return false
    }
    return val >= 0.0 && Math.floor(val) === val && val !== Infinity
  })
  if (!valid) {
    throw new Error("Array must contain only natural numbers")
  }

  const out = Array.from({ length: Math.max(...arr) + 1 }, () => 0)
  arr.forEach((value) => {
    out[value]++
  })
  return out
}

function silhouetteReduce(dataChunk: any, labels: any, labelFrequencies: any) {
  const clusterDistances = dataChunk.map((row: any) =>
    labelFrequencies.map((_: any, mInd: number) => labels.reduce((acc: number, val: number, rInd: number) => (val === mInd ? acc + row[rInd] : acc + 0), 0)),
  )
  const intraDist = clusterDistances.map((val: any, ind: any) => val[labels[ind]])
  const interDist = clusterDistances
    .map((mVal: any, mInd: any) => {
      mVal[labels[mInd]] += Infinity
      labelFrequencies.forEach((fVal: any, fInd: any) => (mVal[fInd] /= fVal))
      return mVal
    })
    .map((val: any) => Math.min(...val))
  return {
    intraDist: intraDist,
    interDist: interDist,
  }
}
