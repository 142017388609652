"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.messageReducer = void 0;
var messageReducer = function (state, data) {
    var type = data.type, answer = data.answer;
    if (type === "clear") {
        return [];
    }
    if (!answer) {
        return state;
    }
    if (!answer.messages) {
        return state;
    }
    var index = state.findIndex(function (item) { return item.id === answer.id; });
    if (index === -1) {
        return __spreadArray(__spreadArray([], state, true), [answer], false);
    }
    var newState = __spreadArray([], state, true);
    newState[index] = answer;
    return newState;
};
exports.messageReducer = messageReducer;
