"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTitle = exports.showBar = void 0;
var status_1 = require("./status");
var showBar = function (status) {
    switch (status) {
        case status_1.Status.Done:
        case status_1.Status.Failed:
        case status_1.Status.Cancelled:
            return false;
        case status_1.Status.Loading:
        case status_1.Status.Waiting:
            return true;
    }
};
exports.showBar = showBar;
var getTitle = function (props) {
    var preTitle = props.preTitle, postTitle = props.postTitle, status = props.status;
    if (status === status_1.Status.Done) {
        return postTitle;
    }
    return preTitle;
};
exports.getTitle = getTitle;
