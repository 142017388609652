import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "./custom.chat.message"

export type FileChatMessageProps = {
  fileContents: string
  filePath: string
  fileType: FileChatMessageType
}

export enum FileChatMessageType {
  File = "File",
  NotFound = "NotFound",
}

export class FileChatMessage extends CustomChatMessage<FileChatMessageProps> {
  public override readonly type = CustomChatMessageType.File
  public override readonly role: ChatRole = ChatRole.Assistant

  constructor(public override data: FileChatMessageProps) {
    super()
  }

  public override get content(): string {
    return `[FILE] ${this.data.filePath}\n\`\`\`${this.data.fileContents}\`\`\``
  }
}
