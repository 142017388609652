import { CancelledException } from "./cancellation.error"
import { v4 } from "uuid"

export class CancellationToken {
  private isCancellationRequested = false
  private onCancelledCallbacks: (() => void)[] = []

  public readonly uuid: string

  constructor(uuid?: string) {
    this.uuid = uuid ?? v4()
  }

  public cancel() {
    this.onCancelledCallbacks.forEach((cb) => cb())
    this.onCancelledCallbacks = []
    this.isCancellationRequested = true
  }

  public addOnCancelledCallback(callback: () => void) {
    this.onCancelledCallbacks.push(callback)
  }

  public removeOnCancelledCallback(callback: () => void) {
    this.onCancelledCallbacks = this.onCancelledCallbacks.filter((cb) => cb !== callback)
  }

  public isCancelled(): boolean {
    return this.isCancellationRequested
  }

  throwIfCancelled() {
    if (this.isCancelled()) {
      throw new CancelledException(this.uuid)
    }
  }
}
