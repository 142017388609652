export class SearchContext {
  // The primary data of the context
  protected title: string
  protected content: string

  constructor(title: string, content: string) {
    this.title = title
    this.content = content
  }

  public getContext(): string {
    return `
${this.title}
${this.content}    
`
  }
}
