import { ChatResult } from "../../types"
import { CompletionResult } from "../../types/completion/completion.result"
import { EmbeddingResult } from "../../types"
import { AI, CreateChatProps, CreateCompletionProps, CreateEmbeddingProps } from "../ai"
import { AIModel } from "../../models"

export class AnthropicAI implements AI {
  public async createChat(_props: CreateChatProps): Promise<ChatResult | undefined> {
    throw new Error("Method not implemented.")
  }

  public async createCompletion(_props: CreateCompletionProps): Promise<CompletionResult | undefined> {
    throw new Error("Method not implemented.")
  }

  public async createEmbedding(_props: CreateEmbeddingProps): Promise<EmbeddingResult | undefined> {
    throw new Error("Method not implemented.")
  }

  public preprocessEmbeddings(_inputs: string[], _model: AIModel, _targetRequestByteSize: number): Promise<{ processedInput: string; originalIndex: number }[][]> {
    throw new Error("Method not implemented.")
  }
}
