"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMessageSubscription = exports.useSendMessage = exports.useMessage = void 0;
var react_1 = require("react");
var message_context_1 = require("./message.context");
var useMessage = function (type, callback) {
    var _a = (0, react_1.useContext)(message_context_1.MessageContext), addMessageListener = _a.addMessageListener, removeMessageListener = _a.removeMessageListener;
    var memoizedCallback = (0, react_1.useCallback)(callback, [type, callback]);
    return (0, react_1.useEffect)(function () {
        var listener = addMessageListener(type, memoizedCallback);
        return function () { return removeMessageListener(listener); };
    }, [type, memoizedCallback, addMessageListener, removeMessageListener]);
};
exports.useMessage = useMessage;
function useSendMessage(event) {
    var sendMessage = (0, react_1.useContext)(message_context_1.MessageContext).sendMessage;
    return {
        send: function (data) { return sendMessage(event.name, data); }
    };
}
exports.useSendMessage = useSendMessage;
function useMessageSubscription(event, req) {
    var _a = (0, react_1.useContext)(message_context_1.MessageContext), addMessageListener = _a.addMessageListener, removeMessageListener = _a.removeMessageListener, sendMessage = _a.sendMessage;
    var _b = (0, react_1.useState)(undefined), data = _b[0], setData = _b[1];
    (0, react_1.useEffect)(function () {
        var listener = addMessageListener(event.name, function (response) {
            setData(response);
        });
        //Onmount request data
        sendMessage(event.name, req);
        return function () { return removeMessageListener(listener); };
    }, [event, addMessageListener, removeMessageListener]);
    return {
        data: data,
        refetch: function (req) { return sendMessage(event.name, req); },
        isSuccess: !!data,
    };
}
exports.useMessageSubscription = useMessageSubscription;
