import { ChatRole } from "@cosine/ai/types"
import { CustomChatMessage, CustomChatMessageType } from "./custom.chat.message"

export type ProcessChatMessageProps = {
  title: string
  description?: string
}

export class ProcessChatMessage extends CustomChatMessage<ProcessChatMessageProps> {
  public override readonly type = CustomChatMessageType.Process
  public override readonly role = ChatRole.Assistant
  public override readonly content = this.data?.title ?? ""

  constructor(public override data: ProcessChatMessageProps) {
    super()
  }
}
