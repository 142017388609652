"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralPanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_feather_1 = require("react-feather");
var components_1 = require("../components");
var GeneralPanel = function (props) {
    var _a = props.selection.properties, selectionTitle = _a.title, selectionDescription = _a.description;
    var _b = props.selection, selected = _b.value, setSelection = _b.onChange;
    var _c = props.highlight.properties, highlightTitle = _c.title, highlightDescription = _c.description;
    var _d = props.highlight, highlighted = _d.value, setHighlighted = _d.onChange;
    var _e = props.tokens.properties, tokensTitle = _e.title, tokensDescription = _e.description;
    var _f = props.tokens, tokens = _f.value, setTokens = _f.onChange;
    var _g = props.examples.properties, examplesTitle = _g.title, examplesDescription = _g.description;
    var _h = props.examples, examples = _h.value, setExamples = _h.onChange;
    var _j = props.theme.properties, themeTitle = _j.title, themeDescription = _j.description;
    var _k = props.theme, theme = _k.value, setTheme = _k.onChange;
    var _l = props.experimental.properties, experimentalTitle = _l.title, experimentalDescription = _l.description;
    var _m = props.experimental, experimental = _m.value, setExperimental = _m.onChange;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex flex-col divide-y divide-surface-bright last:pb-1 last:sm:pb-2", children: [(0, jsx_runtime_1.jsxs)(components_1.SettingGroup, { children: [(0, jsx_runtime_1.jsx)(components_1.ToggleSetting, { title: selectionTitle, description: selectionDescription, icon: (0, jsx_runtime_1.jsx)(react_feather_1.Type, { className: "h-4 w-4 stroke-white sm:h-5 sm:w-5" }), checked: selected, setChecked: setSelection }), (0, jsx_runtime_1.jsx)(components_1.ToggleSetting, { title: highlightTitle, description: highlightDescription, icon: (0, jsx_runtime_1.jsx)(react_feather_1.Eye, { className: "h-4 w-4 stroke-white sm:h-5 sm:w-5" }), checked: highlighted, setChecked: setHighlighted }), (0, jsx_runtime_1.jsx)(components_1.ToggleSetting, { title: tokensTitle, description: tokensDescription, icon: (0, jsx_runtime_1.jsx)(react_feather_1.BarChart2, { className: "h-4 w-4 stroke-white sm:h-5 sm:w-5" }), checked: tokens, setChecked: setTokens }), (0, jsx_runtime_1.jsx)(components_1.ToggleSetting, { title: examplesTitle, description: examplesDescription, icon: (0, jsx_runtime_1.jsx)(react_feather_1.BookOpen, { className: "h-4 w-4 stroke-white sm:h-5 sm:w-5" }), checked: examples, setChecked: setExamples })] }), (0, jsx_runtime_1.jsxs)(components_1.SettingGroup, { title: "Coming Soon", children: [(0, jsx_runtime_1.jsx)(components_1.ToggleSetting, { title: themeTitle, description: themeDescription, icon: (0, jsx_runtime_1.jsx)(react_feather_1.Sun, { className: "h-4 w-4 stroke-white sm:h-5 sm:w-5" }), checked: theme, setChecked: setTheme }), (0, jsx_runtime_1.jsx)(components_1.ToggleSetting, { title: experimentalTitle, description: experimentalDescription, icon: (0, jsx_runtime_1.jsx)(react_feather_1.Coffee, { className: "h-4 w-4 stroke-white sm:h-5 sm:w-5" }), checked: experimental, setChecked: setExperimental })] })] }));
};
exports.GeneralPanel = GeneralPanel;
