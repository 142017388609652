/* eslint-disable no-console */
import { Network } from "@cosine/network"
import { DataHandler } from "./data.handler"
import { Lineage } from "@cosine/common"

export class CoreDataHandler implements DataHandler {
  protected network: Network
  protected userId: string
  public enabled: boolean = true

  constructor(network: Network, userId: string) {
    this.network = network
    this.userId = userId
  }


  public async setEnabled(enabled: boolean): Promise<void> {
    this.enabled = enabled
  }

  public async collect(lineage: Lineage, data: any, timestamp: number): Promise<void> {
    const uid = this.userId
    await this.network
      .fetch("/data/collect", {
        method: "POST",
        body: JSON.stringify({ lineage, data, timestamp, uid }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => console.error(err))
  }
}
