export enum InteractionType {
  REWORDED_QUERY = "REWORDED_QUERY",
  REGENERATE_RESULTS = "REGENERATE_RESULTS",
  CONVERSATION_CONTINUED = "CONVERSATION_CONTINUED",
  CONTINUE_INCOMPLETE_CONVERSATION = "CONTINUE_INCOMPLETE_CONVERSATION",
  CLEARED_CONTEXT_WINDOW = "CLEARED_CONTEXT_WINDOW",
  CLOSED_FILE = "CLOSED_FILE",
  SCROLLED_IN_FILE = "SCROLLED_IN_FILE",
  EXPLORED_ADJACENT_FILES = "EXPLORED_ADJACENT_FILES",
  EXPLORED_DISTANT_FILES = "EXPLORED_DISTANT_FILES",
  // Search specific events
  RESULT_CLICKED = "RESULT_CLICKED",
  RESULTS_EXPANDED = "RESULTS_EXPANDED",
  SCROLLED_IN_RESULTS = "SCROLLED_IN_RESULTS",
  // Behaviour specific events
  EDITED_FILE = "EDITED_FILE",
}
