"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageContent = exports.getReaction = void 0;
var types_1 = require("@cosine/chat/types");
var getReaction = function (messages, id, index, classification) {
    var _a;
    var group = messages.find(function (group) { return group.id === id; });
    var chatMessage = group.messages[index];
    var previousMessageTypes = index > 0 ? group.messages.slice(0, index).map(function (message) { var _a; return (_a = message.type) !== null && _a !== void 0 ? _a : types_1.CustomChatMessageType.Text; }) : [];
    var prompt = (0, exports.getMessageContent)(group.prompt);
    var message = (0, exports.getMessageContent)(chatMessage);
    if (!message) {
        return null;
    }
    return {
        message: message,
        prompt: prompt,
        type: (_a = chatMessage.type) !== null && _a !== void 0 ? _a : types_1.CustomChatMessageType.Text,
        classification: classification,
        previousMessageTypes: previousMessageTypes,
        timestamp: new Date().getTime(),
    };
};
exports.getReaction = getReaction;
var getMessageContent = function (message) {
    var _a, _b, _c;
    switch (message.type) {
        case types_1.CustomChatMessageType.Text:
            return (_a = message.content) !== null && _a !== void 0 ? _a : "";
        case types_1.CustomChatMessageType.Search:
            return JSON.stringify(message.data);
        case types_1.CustomChatMessageType.Understand:
            return message.content;
        case types_1.CustomChatMessageType.File:
            return message.data.fileContents;
        case types_1.CustomChatMessageType.ExplainCode:
            return message.data.question;
        case types_1.CustomChatMessageType.Question:
            return (_b = message.content) !== null && _b !== void 0 ? _b : "";
        default:
            return (_c = message.content) !== null && _c !== void 0 ? _c : "";
    }
};
exports.getMessageContent = getMessageContent;
