"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("@cosine/chat/types");
var react_1 = require("react");
var react_2 = require("@headlessui/react");
var search_message_type_1 = require("../../types/search.message.type");
var process_message_1 = require("./process.message");
var markdown_message_1 = require("./markdown.message");
var file_message_1 = require("./file.message");
var error_message_1 = require("./error.message");
var cancel_message_1 = require("./cancel.message");
var explain_code_message_1 = require("./explain.code.message");
var code_message_1 = require("./code.message");
var no_results_message_1 = require("./no.results.message");
var get_corners_1 = require("../../utils/get.corners");
var ideate_message_1 = require("./ideate.message");
var lodash_1 = require("lodash");
function Message(props) {
    var delay = props.delay, disableTransition = props.disableTransition;
    var _a = (0, react_1.useState)(false), show = _a[0], setShow = _a[1];
    (0, react_1.useEffect)(function () { return setShow(true); }, []);
    var className = delay ? "delay-".concat(delay) : "delay-200";
    return ((0, jsx_runtime_1.jsx)("div", { children: disableTransition ? ((0, jsx_runtime_1.jsx)(SelectMessage, __assign({}, props))) : ((0, jsx_runtime_1.jsx)(react_2.Transition, { show: show, appear: true, className: className, enter: "transform transition ease-in-out duration-300", enterFrom: "translate-y-4 opacity-0", enterTo: "translate-y-0 opacity-100", leave: "transform transition ease-in-out duration-300", leaveFrom: "translate-y-0 opacity-100", leaveTo: "-translate-y-4 opacity-0", children: (0, jsx_runtime_1.jsx)(SelectMessage, __assign({}, props)) })) }));
}
exports.Message = Message;
var SelectMessage = /** @class */ (function (_super) {
    __extends(SelectMessage, _super);
    function SelectMessage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Because of the complex nature of the props, we need to use lodash to do a deep comparison
     * to determine whether we should re-render a message
     *
     * Without this we get a huge performance hit
     */
    SelectMessage.prototype.shouldComponentUpdate = function (nextProps) {
        return !(0, lodash_1.isEqual)(nextProps, this.props);
    };
    SelectMessage.prototype.render = function () {
        var _a = this.props, message = _a.message, top = _a.top, bottom = _a.bottom, side = _a.side, isUser = _a.isUser, onOpenFile = _a.onOpenFile;
        var corners = (0, get_corners_1.getCorners)(side, top, bottom);
        if (!message) {
            return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
        }
        switch (message.type) {
            case types_1.CustomChatMessageType.Search:
                return (0, jsx_runtime_1.jsx)(SearchMessage, { message: message, corners: corners, onOpenFile: onOpenFile });
            case types_1.CustomChatMessageType.Process:
                return (0, jsx_runtime_1.jsx)(process_message_1.ProcessMessage, { message: message });
            case types_1.CustomChatMessageType.Question:
                return (0, jsx_runtime_1.jsx)(markdown_message_1.MarkdownMessage, { text: message.content, corners: corners, isUser: isUser, inactive: message.inactive });
            case types_1.CustomChatMessageType.ExplainCode:
                return (0, jsx_runtime_1.jsx)(explain_code_message_1.ExplainCodeMessage, { message: message, corners: corners });
            case types_1.CustomChatMessageType.Text:
                return (0, jsx_runtime_1.jsx)(markdown_message_1.MarkdownMessage, { text: message.content, corners: corners, isUser: isUser });
            case types_1.CustomChatMessageType.File:
                return (0, jsx_runtime_1.jsx)(file_message_1.FileMessage, { message: message, corners: corners, onClick: onOpenFile });
            case types_1.CustomChatMessageType.Understand:
                return (0, jsx_runtime_1.jsx)(markdown_message_1.MarkdownMessage, { text: message.content, corners: corners, isUser: false });
            case types_1.CustomChatMessageType.Ideate:
                return (0, jsx_runtime_1.jsx)(ideate_message_1.IdeateMessage, { message: message, corners: corners });
            case types_1.CustomChatMessageType.Cancelled:
                return (0, jsx_runtime_1.jsx)(cancel_message_1.CancelledMessage, { message: message });
            case types_1.CustomChatMessageType.Error:
                return (0, jsx_runtime_1.jsx)(error_message_1.ErrorMessage, { message: message });
            default:
                return (0, jsx_runtime_1.jsx)(markdown_message_1.MarkdownMessage, { text: message.content, corners: corners, isUser: isUser });
        }
    };
    return SelectMessage;
}(react_1.Component));
function SearchMessage(props) {
    var message = props.message, onOpenFile = props.onOpenFile;
    switch (message.searchType) {
        case search_message_type_1.SearchMessageType.Code:
            return (0, jsx_runtime_1.jsx)(code_message_1.CodeMessage, __assign({}, props, { message: message, onClick: onOpenFile }));
        case search_message_type_1.SearchMessageType.None:
            return (0, jsx_runtime_1.jsx)(no_results_message_1.NoResultsMessage, __assign({}, props, { message: message }));
        default:
            return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
}
