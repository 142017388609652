"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodeBlock = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_feather_1 = require("react-feather");
var react_1 = require("react");
var code_overlay_1 = require("./code.overlay");
var message_card_1 = require("./message.card");
var CodeBlock = function (props) {
    var language = props.language, path = props.path, onClick = props.onClick, corners = props.corners;
    var _a = (0, react_1.useState)(false), expanded = _a[0], setExpanded = _a[1];
    var _b = reduceText(props.text), reducedText = _b[0], reduced = _b[1];
    var text = expanded ? props.text : reducedText;
    var header = {
        title: path,
        icon: (0, jsx_runtime_1.jsx)(react_feather_1.File, { className: "h-2 w-2 stroke-white sm:h-3 sm:w-3" }),
        copy: {
            text: text,
            type: "copy",
        },
        onExpand: reduced ? function (expanded) { return setExpanded(expanded); } : undefined,
        expanded: expanded,
        className: "bg-surface-dim",
    };
    return ((0, jsx_runtime_1.jsx)(message_card_1.MessageCard, { onClick: onClick, header: header, corners: corners, className: "bg-surface", children: (0, jsx_runtime_1.jsx)(code_overlay_1.CodeHighlightOverlay, { corners: corners, language: language, text: text, expanded: expanded, reduced: reduced, color: "to-surface", className: "text-xs" }) }));
};
exports.CodeBlock = CodeBlock;
var reduceText = function (text) {
    var lines = text.split("\n");
    var lineCount = lines.length;
    if (lineCount <= 10) {
        return [text, false];
    }
    return [lines.slice(0, 10).join("\n"), true];
};
