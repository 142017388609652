import { ChatRole } from "@cosine/ai/types"
import { TextChatMessage } from "./text.chat.message"
import { CustomChatMessageType } from "."
import { v4 as uuidv4 } from "uuid"

export class PromptChatMessage extends TextChatMessage {
  public override type = CustomChatMessageType.Question
  public override readonly role: ChatRole = ChatRole.User
  public override readonly content: string = this.data
  public inactive = false

  constructor(data: string) {
    super(uuidv4(), data)
  }
}
