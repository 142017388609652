import { Language } from "../language"
import { Position } from "./position"

export interface ComponentProps {
  text: string
  start: Position
  end: Position
  language: Language
  lineage?: string[]
  treeSitterType: string
  generalizedType?: string
}

export class Component {
  public text: string
  public start: Position
  public end: Position
  public readonly language: Language
  public readonly lineage?: string[]
  public readonly treeSitterType: string
  public readonly generalizedType?: string

  constructor(props: ComponentProps) {
    const { text, start, end, language, lineage, treeSitterType, generalizedType } = props
    this.text = text
    this.start = start
    this.end = end
    this.language = language
    this.lineage = lineage
    this.treeSitterType = treeSitterType
    this.generalizedType = generalizedType
  }
}
