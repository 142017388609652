export function cosineSimilarity(a: number[], b: number[]): number {
  if (a.length !== b.length) {
    throw new Error("Vectors must have the same length. Check that you haven't got mismatched embeddings versions.")
  }
  let dot_product = 0
  let norm_a = 0
  let norm_b = 0
  for (let i = 0; i < a.length; i++) {
    dot_product += a[i] * b[i]
    norm_a += a[i] * a[i]
    norm_b += b[i] * b[i]
  }
  return dot_product / (Math.sqrt(norm_a) * Math.sqrt(norm_b))
}

export function embeddingMultipliedByMatrix(embedding: number[], matrix: number[][]): number[] {
  // The previous check is removed to allow for the correct dimensionality operation.
  // The function now assumes that the embedding's length matches the matrix's row count,
  // which aligns with the matrix multiplication rule that inner dimensions must match.

  // Initialize the result vector with the length of the matrix's column count.
  const result: number[] = new Array(matrix[0].length).fill(0)
  // Iterate over each column of the matrix
  for (let col = 0; col < matrix[0].length; col++) {
    // For each column, iterate over each row
    for (let row = 0; row < matrix.length; row++) {
      if (isNaN(embedding[row] * matrix[row][col])) {
        throw new Error(`${embedding[row]} * ${matrix[row][col]} is NaN - ${row} ${col}`)
      }
      result[col] += embedding[row] * matrix[row][col]
    }
  }

  return result
}
