export class Lineage {
  public id: string
  public type: string
  public parent?: Lineage

  constructor(id: string, type: string, parent?: Lineage) {
    this.id = id
    this.type = type
    this.parent = parent
  }

  lineage(): Lineage[] {
    const lineageList: Lineage[] = []
    let current: Lineage | undefined = this.parent
    while (current) {
      lineageList.push(current)
      current = current.parent
    }
    return lineageList.reverse()
  }
}
