import { VectorDatabase } from "@cosine/database"
import { ChatMessage, MemoryType, collateContiguousContext, collateDiscontiguousContext, collateForgetfulContext } from ".."
import { AI, AIModel } from "@cosine/ai"
import EventEmitter from "events"
import TypedEmitter from "typed-emitter"
import { v4 as uuidv4 } from "uuid"

export type ChatHistoryEvents = {
  onNewConversationStarted: (conversationId: string, title: string, prompt: ChatMessage[]) => void
  /**
   * Listen for new messages
   */
  onMessageReceived: (conversationId: string, newMessage: ChatMessage[]) => void
  /**
   * Listen for the full history state update
   */
  onHistoryUpdated: (conversationId: string, newHistory: ChatMessage[]) => void
  onHistoryCleared: (previousConversationId: string, previousHistory: ChatMessage[]) => void
  onHistoryLoaded: (conversationId: string, history: ChatMessage[]) => void
}

export class ChatHistory {
  protected history: ChatMessage[] = []
  protected events = new EventEmitter() as TypedEmitter<ChatHistoryEvents>
  protected _conversationId = uuidv4()

  constructor(listeners?: Partial<ChatHistoryEvents>) {
    if (listeners) {
      for (const [event, listener] of Object.entries(listeners)) {
        this.events.on(event as keyof ChatHistoryEvents, listener)
      }
    }
  }

  push(...messages: ChatMessage[]) {
    let hasHistory = false
    if (this.history.length === 0) {
      this.events.emit("onNewConversationStarted", this._conversationId, messages[0].content ?? "New Conversation", messages)
    } else {
      hasHistory = true
    }
    this.history.push(...messages)

    this.events.emit("onMessageReceived", this._conversationId, messages)

    //Only send the full history update if there was already a conversation
    if (hasHistory) {
      this.events.emit("onHistoryUpdated", this._conversationId, this.history)
    }
  }

  clear() {
    this.events.emit("onHistoryCleared", this._conversationId, this.history)
    this.history = []
    this._conversationId = uuidv4()
  }

  load(id: string, messages: ChatMessage[]) {
    this.history = messages
    this._conversationId = id
    this.events.emit("onHistoryLoaded", this._conversationId, this.history)
  }

  get messages() {
    return this.history
  }

  get conversationId() {
    return this._conversationId
  }

  /*
   * This property is an array of ChatMessage objects and is used to hold a specific number of recent chat messages that are relevant to the current context. The purpose of the contextWindow is to provide the chat bot with the necessary context to generate appropriate responses.
   * For example, if the chat bot needs to understand the context of a user's message, it can refer to the messages in the contextWindow to gather information about previous interactions. By limiting the number of messages in the contextWindow, the chat bot can focus on the most recent messages and avoid unnecessary memory usage.
   */
  async contextWindow(memoryType: MemoryType, systemPrompt: ChatMessage, model: AIModel, ai: AI, db?: VectorDatabase<ChatMessage>) {
    switch (memoryType) {
      case MemoryType.Contiguous:
        return collateContiguousContext({
          newMessages: this.history,
          messages: [],
          systemPrompt: systemPrompt,
          model: model,
          ai: ai,
          summarizationModel: model,
          summarizationFormat: "prose",
          maxAttempts: 3,
        })
      case MemoryType.Discontiguous:
        return collateDiscontiguousContext({
          newMessages: this.history,
          messages: [],
          systemPrompt: systemPrompt,
          model: model,
          ai: ai,
          db: db!,
        })
      case MemoryType.Forgetful:
        return collateForgetfulContext({
          newMessages: this.history,
          messages: [],
          model: model,
          systemPrompt: systemPrompt,
        })
    }
  }
}
