import { Dependencies } from "../types"
import toml from "toml"

export function parsePyProjectToml(fileContents: string): Dependencies {
  let pyProjectContents: any
  try {
    pyProjectContents = toml.parse(fileContents)
  } catch (err) {
    return {}
  }
  const dependencies: Dependencies = {}

  // The dependencies are located under the [tool.poetry.dependencies] section in pyproject.toml
  if (pyProjectContents["tool"] && pyProjectContents["tool"]["poetry"] && pyProjectContents["tool"]["poetry"]["dependencies"]) {
    for (const [packageName, version] of Object.entries(pyProjectContents["tool"]["poetry"]["dependencies"])) {
      dependencies[packageName] = String(version || "unknown")
    }
  }

  // You can also include dev-dependencies if needed
  // They are located under the [tool.poetry.dev-dependencies] section
  if (pyProjectContents["tool"] && pyProjectContents["tool"]["poetry"] && pyProjectContents["tool"]["poetry"]["dev-dependencies"]) {
    for (const [packageName, version] of Object.entries(pyProjectContents["tool"]["poetry"]["dev-dependencies"])) {
      if (version && typeof version === "string") {
        dependencies[packageName] = String(version || "unknown")
      }
    }
  }

  return dependencies
}
