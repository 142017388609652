"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMessageContext = exports.MessageContext = void 0;
/* eslint-disable @typescript-eslint/no-unused-vars */
var react_1 = require("react");
exports.MessageContext = (0, react_1.createContext)({
    sendMessage: function (type, data) { },
    addMessageListener: function (type, listener) { },
    removeMessageListener: function (listener) { },
});
var useMessageContext = function () {
    return (0, react_1.useContext)(exports.MessageContext);
};
exports.useMessageContext = useMessageContext;
