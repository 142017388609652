"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@headlessui/react");
var react_feather_1 = require("react-feather");
var react_2 = require("react");
function DropDown(props) {
    var label = props.label, options = props.options, setOption = props.setOption;
    return ((0, jsx_runtime_1.jsx)(react_1.Popover, { className: "relative", children: function (_a) {
            var open = _a.open, close = _a.close;
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(react_1.Popover.Button, { className: "\n            ".concat(open ? "" : "text-opacity-90", "\n            group inline-flex items-center rounded-md bg-surface-container-lowest px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"), children: [(0, jsx_runtime_1.jsx)("p", { className: "text-sm", children: label }), (0, jsx_runtime_1.jsx)(react_feather_1.ChevronDown, { className: "".concat(open ? "" : "text-opacity-70", "\n              ml-2 h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-opacity-80"), "aria-hidden": "true" })] }), (0, jsx_runtime_1.jsx)(react_1.Transition, { as: react_2.Fragment, enter: "transition ease-out duration-200", enterFrom: "opacity-0 translate-y-1", enterTo: "opacity-100 translate-y-0", leave: "transition ease-in duration-150", leaveFrom: "opacity-100 translate-y-0", leaveTo: "opacity-0 translate-y-1", children: (0, jsx_runtime_1.jsx)(react_1.Popover.Panel, { className: "absolute right-0 z-10 mt-3 w-48 px-0 sm:w-64 sm:px-4 md:w-80 lg:w-screen lg:max-w-md xl:max-w-lg", children: (0, jsx_runtime_1.jsx)("div", { className: "w-full overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5", children: (0, jsx_runtime_1.jsx)("div", { className: "relative grid gap-8 bg-white p-3 sm:p-5", children: options.map(function (item) { return ((0, jsx_runtime_1.jsxs)("a", { onClick: function () {
                                            setOption(item.value);
                                            close();
                                        }, className: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-neutral-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-50", children: [item.img && ((0, jsx_runtime_1.jsx)("div", { className: "flex h-5 w-5 shrink-0 items-center justify-center rounded-md text-white sm:h-6 sm:w-6 md:h-7 md:w-7 lg:h-8 lg:w-8", children: typeof item.img === "string" ? (0, jsx_runtime_1.jsx)("img", { src: item.img, "aria-hidden": "true", alt: "Dropdown Icon" }) : (0, jsx_runtime_1.jsx)(item.img, {}) })), (0, jsx_runtime_1.jsxs)("div", { className: "ml-4", children: [(0, jsx_runtime_1.jsx)("p", { className: "text-xs font-medium text-neutral-900 sm:text-sm", children: item.title }), (0, jsx_runtime_1.jsx)("p", { className: "text-xs text-neutral-500", children: item.description })] })] }, item.title)); }) }) }) }) })] }));
        } }));
}
exports.DropDown = DropDown;
