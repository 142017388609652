"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageEventType = void 0;
var MessageEventType = /** @class */ (function () {
    function MessageEventType(eventName) {
        this.requestPayload = {};
        this.responsePayload = {};
        this._name = eventName;
    }
    Object.defineProperty(MessageEventType.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MessageEventType.prototype, "response", {
        get: function () {
            if (this.responsePayload) {
            }
            throw new Error("Do not call response directy. use typeof event.response to get the return type");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MessageEventType.prototype, "request", {
        get: function () {
            if (this.requestPayload) {
            }
            throw new Error("Do not call request directy. use typeof event.response to get the return type");
        },
        enumerable: false,
        configurable: true
    });
    return MessageEventType;
}());
exports.MessageEventType = MessageEventType;
