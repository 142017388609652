/**
 * Attempts to parse a flag value into true or false. Returns undefined if it cannot parse the flag
 * @param flag The flag to evaluate
 */
export function evaluateFlag(flag: string | undefined): boolean {
  if (flag === "on" || flag === "enabled") {
    return true
  } else if (flag === "off" || flag === "disabled") {
    return false
  }
  return false
}

/**
 * Attempts to parse a flag value into true or false. Returns undefined if it cannot parse the flag
 * @param flag The flag to evaluate
 */
export function evaluateFlags(flags: { [key: string]: string }): { [key: string]: boolean | undefined } | undefined {
  return Object.entries(flags).reduce(
    (newObject, [key, value]) => {
      newObject[key] = evaluateFlag(value)
      return newObject
    },
    {} as { [key: string]: boolean | undefined },
  )
}
