import { extname } from "path"
import { Language, languageExtensions } from "./types/language"
import { detectFileSync } from "chardet"

export const getLanguageFromPath = (path: string): Language | undefined => {
  const ext = extname(path.toLowerCase())
  const result = Object.entries(languageExtensions).find(([_, extensions]) => extensions.includes(ext))
  if (!result) {
    try {
      const encoding = detectFileSync(path)
      const validEncodings = ["UTF-8", "UTF-16BE", "UTF-16LE", "UTF-32", "UTF-32BE", "ISO-8859-1", "ISO-8859-2"]
      if (!encoding) return undefined
      if (validEncodings.includes(encoding)) return Language.PlainText
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Failed to detect encoding", e)
    }
    return undefined
  } else if (!result) {
    return undefined
  }
  return result[0] as Language
}
