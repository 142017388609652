"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodeSelection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@headlessui/react");
var common_1 = require("../../../../common");
var code_overlay_1 = require("./code.overlay");
var react_2 = require("react");
var lodash_1 = require("lodash");
var CodeSelection = /** @class */ (function (_super) {
    __extends(CodeSelection, _super);
    function CodeSelection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Because of the complex nature of the props, we need to use lodash to do a deep comparison
     * to determine whether we should re-render a message
     *
     * Without this we get a huge performance hit
     */
    CodeSelection.prototype.shouldComponentUpdate = function (nextProps) {
        return !(0, lodash_1.isEqual)(nextProps, this.props);
    };
    CodeSelection.prototype.render = function () {
        var _a = this.props, text = _a.text, language = _a.language, show = _a.show, useSnippet = _a.useSelection, setUseSelection = _a.setUseSelection, className = _a.className;
        var _b = reduceText(text !== null && text !== void 0 ? text : "", 7), reduced = _b[0], isReduced = _b[1];
        var lines = text.split("\n").length;
        var lineText = lines == 1 ? "line" : "lines";
        return ((0, jsx_runtime_1.jsx)("div", { className: "flex w-full flex-row justify-center", children: (0, jsx_runtime_1.jsx)(react_1.Transition, { show: show, appear: true, className: className, enter: "transform transition ease-in-out duration-250", enterFrom: "translate-y-10 opacity-0", enterTo: "translate-y-0 opacity-100", leave: "transform transition ease-in-out duration-250", leaveFrom: "translate-y-0 opacity-100", leaveTo: "translate-y-10 opacity-0", children: (0, jsx_runtime_1.jsxs)("div", { className: "bg-surface-container-low pointer-events-auto w-full rounded-t-lg px-0.5 pt-1 shadow-md", children: [(0, jsx_runtime_1.jsx)("div", { className: "relative max-h-[calc(6*1.5rem)] overflow-hidden", children: (0, jsx_runtime_1.jsx)(code_overlay_1.CodeHighlightOverlay, { text: reduced, language: language, expanded: false, reduced: isReduced, color: "to-surface-container-low", className: "text-xs" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "flex w-full flex-row items-center px-2", children: [(0, jsx_runtime_1.jsxs)("p", { className: "bg-surface-container-lowest text-on-surface-variant m-1.5 flex-none rounded-full px-2 py-1 text-xs backdrop-blur-sm", children: [lines, " ", lineText, " selected"] }), (0, jsx_runtime_1.jsx)("p", { className: "text-on-surface-variant mx-2 w-full text-end text-xs italic", children: "Include in prompt" }), (0, jsx_runtime_1.jsx)(common_1.Toggle, { checked: useSnippet, setChecked: setUseSelection, gradient: true, classNameUnchecked: "bg-surface-container-lowest", size: "large" })] })] }) }) }));
    };
    return CodeSelection;
}(react_2.Component));
exports.CodeSelection = CodeSelection;
var reduceText = function (text, maxLines) {
    if (maxLines === void 0) { maxLines = 10; }
    // This replace removes preceeding empty lines.
    // But it retains preceeding whitespace on the first line that has actual text.
    var lines = text.replace(/^(\s*\n)*/, "").split("\n");
    var lineCount = lines.length;
    if (lineCount <= maxLines) {
        return [text, false];
    }
    return [lines.slice(0, maxLines).join("\n"), true];
};
