function truncateLine(line: string, n: number): string {
  if (line.length <= n) {
    return line
  }

  const lastWhitespaceIndex = line.substring(0, n).lastIndexOf(" ")
  const start = line.substring(0, lastWhitespaceIndex)
  const end = line.substring(line.length - (n - lastWhitespaceIndex), line.length)
  return `${start}...${end}`
}

export function truncateMultiline(text: string, n = 25): string {
  const lines = text.split("\n")
  let truncatedText = ""

  for (let i = 0; i < lines.length; i++) {
    if (i > 0) {
      truncatedText += truncateLine(lines[i], n) + "\n"
    } else {
      truncatedText += lines[i]
    }
  }

  return truncatedText
}

export function truncateByNewLine(message: string, factor: number): string {
  if (factor < 0 || factor > 1) {
    throw new Error("Factor must be between 0 and 1")
  }
  const lines = message.split("\n")
  const desiredLength = Math.floor(lines.length * factor)
  const rest = lines.slice(0, desiredLength)
  rest.push("\n\n...")
  return rest.join("\n")
}
